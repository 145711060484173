import React from 'react';
import breakPoints from 'helpers/Styles';
import CircleRing from './CircleRing';

const ProductPerformance = ({ productTags }) => {
  const performanceTags = productTags?.filter((item) =>
    item.startsWith('performance:'),
  );

  return (
    <>
      {performanceTags.length > 0 && (
        <div className="product_performance">
          <div>
            <h5 className="title">PERFORMANCE</h5>
            <div className="performance_section">
              {performanceTags.map((item, index) => {
                const title = item
                  ?.split(':')[1]
                  .replace('-', ' ')
                  .toUpperCase();
                const percentage = item.split(':')[2];
                return (
                  <div className="performance_item" key={index}>
                    <div className="circle_ring">
                      <CircleRing percentage={percentage} />
                    </div>
                    <div className="attribute_value">{title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <style jsx>
        {`
          .product_performance {
            margin: 0 auto;
            width: 70%;
            border-top: 1px solid #949494;
            border-bottom: 1px solid #949494;
            margin-top: 50px;
            padding-bottom: 30px;
          }
          .title {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 10px;
          }
          .performance_section {
            display: flex;
          }
          .performance_item {
            display: flex;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
            width: 50%;
            margin-bottom: 20px;
          }
          .circle_ring {
            width: 50px;
            height: 50px;
            margin-right: 25px;
          }
          .attribute_value {
            margin-top: 25px;
          }
          @media (max-width: ${breakPoints.medium}) {
            .product_performance {
              width: 100%;
            }
          }
          @media (max-width: ${breakPoints.medium}) {
            .attribute_value {
              margin-top: 15px;
              margin-left: 5px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductPerformance;
