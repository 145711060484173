import React from 'react';
import breakPoints from 'helpers/Styles';
import PropTypes from 'prop-types';
import Video from 'components/video/index-rts';

const VideoViewer = ({
  videoPreviewLink,
  handleModalClose,
  imageIndex,
  handleImageIndex,
}) => {

  const handleCloseImage = () => {
    handleModalClose(false);
    handleImageIndex(0);
  };

  return (
    <>
      <div className="image_viewer_mask" />
      <div key={0}>
        <div className="image_viewer_container" id="imageViewer">
          <div className="image_viewer">

            <Video
              videoUrl={videoPreviewLink}
              videoHeight="700px"
              videoSource={videoPreviewLink}
              videoTitle={null}
              videoDescription={null}
            />
          </div>
          {/* <div className="close_view" onClick={handleCloseImage} /> */}
          <div 
              onClick={handleCloseImage} 
              className="close_view"
              style={{ backgroundImage: `url('https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756')` }}
              onMouseEnter={(event) => { 
                event.target.style.backgroundImage = "url('https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close-hover_1.png?v=1714063075')";
              }}
              onMouseLeave={(event) => { 
                event.target.style.backgroundImage = `url('https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756')`;
              }}
            />
        </div>
      </div>


      <style jsx>
        {`
          .image_viewer_container {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background: #141414;
            z-index: 10;
          }
          .image_viewer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;
            height: 100%;
            margin: 0 auto;
            z-index: 10;
          }
          .image_viewer img {
            min-width: 60%;
            height: 90%;
          }
          .close_view {
            height: 25px;
            width: 25px;
            position: absolute;
            top: 12%;
            right: 10%;
            cursor: pointer;
          }
          .last_image {
            position: absolute;
            top: 50%;
            left: 10%;
            cursor: pointer;
          }
          .next_image {
            position: absolute;
            top: 50%;
            right: 10%;
            cursor: pointer;
          }
          @media (max-width: ${breakPoints.medium}) {
            .image_viewer img {
              width: 100% !important;
              min-height: 400px !important;
            }
            
          @media (max-width: ${breakPoints.small}) {
            .last_image {
              left: 3%;
            }
            .next_image, .close_view {
              right: 3%;
            }
            .image_viewer img {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

VideoViewer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // imagesGallery: PropTypes.array.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  // handleImageIndex: PropTypes.func.isRequired,
  imageIndex: PropTypes.number.isRequired,
};

export default VideoViewer;
