import React from 'react';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';

const ProductFeatures = ({ productTags }) => {
  const featuresTags = productTags.filter((item) => item.includes('features:'));

  const renderIcon = (featureType) => {
    if (!featureType) return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-default.svg?v=1714049852';
    if (featureType) {
      if (
        featureType === 'screen-size' ||
        featureType === 'resolution' ||
        featureType === 'hz' ||
        featureType === 'curved/flat'
      ) {
        return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-desktop.svg?v=1714049863';
      }
      if (featureType === 'weight/laptop') {
        return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-laptop.svg?v=1714049872';
      }
      if (
        featureType === 'cord/wireless-mouse' ||
        featureType === 'weight/mouse' ||
        featureType === 'max-dpi' ||
        featureType === 'sensor'
      ) {
        return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-mouse.svg?v=1714049880';
      }
      if (
        featureType === 'switches-brown/blue/red' ||
        featureType === 'cord/wireless-keyboard' ||
        featureType === 'rgb'
      ) {
        return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-keyboard.svg?v=1714049889';
      }
      return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-default.svg?v=1714049852';
    }
  };

  return (
    <>
      {featuresTags.length > 0 && (
        <div className="product_feature">
          <div>
            <h5 className="title">FEATURES</h5>
            <div className="features_section">
              {featuresTags.map((item, index) => {
                const title =
                  typeof item.split(':')[2] !== 'undefined' &&
                  item.split(':')[2].replace(/-/g, ' ');
                return (
                  <div className="feature_item" key={index}>
                    {title && (
                      <div className="feature_icon">
                        <Image draggable={false}
                          src={renderIcon(item.split(':')[3])}
                          alt="product_features"
                          width={20}
                          height={20}
                        />
                      </div>
                    )}
                    {title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <style jsx>
        {`
          .product_feature {
            margin: 0 auto;
            width: 70%;
            border-bottom: 1px solid #949494;
            margin-top: 30px;
            padding-bottom: 30px;
          }
          .title {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }
          .features_section {
            width: 100%;
          }
          .feature_item {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 20px;
            display: flex;
          }
          .feature_icon {
            margin-right: 15px;
            margin-bottom: -3px;
          }
          @media (max-width: ${breakPoints.medium}) {
            .product_feature {
              width: 100%;
            }
            .feature_icon {
              margin-top: 3px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductFeatures;
