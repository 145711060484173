import React, { useState } from 'react';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';
import Config from '../../../next.config';
import Link from 'next/link';
import { useQuery } from '@apollo/client';
import {
  PRODUCT_BY_HANDLE,
  productByHandleVars,
} from 'helpers/graphql/product';
import MoreInfoModal from './MoreInfoModal';
import { fixProductDataMetafields } from 'helpers/fixPriceObjectModel';
import { fixDecimalPlaces } from 'helpers/smallFixes';
const ProductSpecification = ({
  productMetaFields,
  handleUpsellChange,
  image_url,
  name,
  selected,
  handleMetaFiled,
}) => {
  let productAvaiablityCheck = { [name]: [] };
  let domain = image_url && image_url.split('//')[1];
  domain = image_url && domain && domain.split('/')[0];
  let ImageUrl = '';
  if (Config.images.domains.includes(domain)) {
    ImageUrl = image_url;
  }
  const metaProducts = productMetaFields.map((handle) => {
    const productHandle = handle.product_handle.replace(/\ /g, '-');

    const { loading, data, error } = useQuery(PRODUCT_BY_HANDLE, {
      variables: productByHandleVars(productHandle),
    });
    if (loading || (!data && !error)) {
      return <>loading</>;
    }
    if (error || !data) {
      return <>{JSON.stringify(error)}</>;
    }
    if (!error) {
      return data;
    }
  });
  const [showModal, setShowModal] = useState(null);
  const [infoModalProduct, setInfoModalProduct] = useState(null);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const noNullMetaProducts = metaProducts?.filter(
    (item) => item.product != null,
  );

  const fixedMetaProducts = noNullMetaProducts.map((item) =>
    fixProductDataMetafields(item),
  );
  fixedMetaProducts.length > 0 &&
    fixedMetaProducts &&
    fixedMetaProducts.map((itm, idx) => {
      const { product } = itm;
      const productAvailable =
        product?.variants?.edges[0]?.node?.quantityAvailable > 0
          ? true
          : product?.variants?.edges[0]?.node?.quantityAvailable <= 0 &&
            product?.variants?.edges[0]?.node?.currentlyNotInStock
          ? true
          : false;
      if (!productAvailable) productAvaiablityCheck[name].push(true);
    });
  if (fixedMetaProducts.length === productAvaiablityCheck[name].length) {
    handleMetaFiled(name);
    return <></>;
  } else
    return (
      <>
        {infoModalProduct && (
          <MoreInfoModal
            product={infoModalProduct}
            handleClose={() => setInfoModalProduct(null)}
          />
        )}
        <div className='product_specifications'>
          <div>
            <div className='our_boxes'>
              <div className='title_container select_product'>
                {!showModal &&
                selected &&
                typeof selected[name] !== 'undefined' &&
                selected?.[name]?.id !== '' ? (
                  fixedMetaProducts &&
                  fixedMetaProducts.map((itm) => {
                    const { product } = itm;
                    const productId = product?.id;
                    const productImage =
                      product?.images?.edges[0]?.node?.originalSrc ||
                      'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274';
                    const productTitle = product ? product.title : null;
                    const productPrice = product
                      ? product.variants.edges[0].node.price
                      : null;
                    if (
                      selected &&
                      typeof selected[name] !== 'undefined' &&
                      selected?.[name]?.id === productId
                    )
                      return (
                        <>
                          <div className='select_image'>
                            <Image
                              draggable={false}
                              src={productImage}
                              alt='warranty'
                              width={64}
                              height={64}
                            />
                          </div>
                          <div className='select_label'>
                            <h5 className='title'>{name}</h5>
                            <span className='selected_product'>
                              {productTitle}
                            </span>
                            <span className='new_price'>+${productPrice}</span>
                          </div>
                        </>
                      );
                  })
                ) : (
                  <>
                    <div className='select_image'>
                      <Image
                        draggable={false}
                        src={ImageUrl ? ImageUrl : '/images/black.jfif'}
                        alt='warranty'
                        width={64}
                        height={64}
                      />
                    </div>
                    <div className='select_label'>
                      <h5 className='title'>{name}</h5>
                      <span className='selected_product'></span>
                      <span className='new_price'></span>
                    </div>
                  </>
                )}
                <div onClick={handleModal} className='modal_toggle'>
                  {showModal ? (
                    <div className='minus-icon'>
                      <Image
                        draggable={false}
                        src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_minus.png?v=1714048250'
                        alt='warranty'
                        width={15}
                        height={3}
                      />
                    </div>
                  ) : selected &&
                    typeof selected[name] !== 'undefined' &&
                    selected?.[name]?.id !== '' ? (
                    <div>
                      <Image
                        draggable={false}
                        src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/edit.svg?v=1714044271'
                        alt='warranty'
                        width={15}
                        height={15}
                      />
                    </div>
                  ) : (
                    <div>
                      <Image
                        draggable={false}
                        src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_plus.png?v=1714048195'
                        alt='warranty'
                        width={15}
                        height={15}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showModal ? (
              fixedMetaProducts.length > 0 ? (
                fixedMetaProducts &&
                fixedMetaProducts.map((itm, idx) => {
                  const { product } = itm;
                  const productId = product?.id;
                  const productImage =
                    product?.images?.edges[0]?.node?.originalSrc ||
                    'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274';

                  const productTitle = product ? product.title : null;
                  const productPrice = product
                    ? product.variants.edges[0].node.price
                    : null;
                  const compareAtPrice = product
                    ? product.variants.edges[0].node.compareAtPrice
                    : null;
                  const productHandle = product ? product.handle : null;
                  const productAvailable =
                    product?.variants?.edges[0]?.node?.quantityAvailable > 0
                      ? true
                      : product?.variants?.edges[0]?.node?.quantityAvailable <=
                          0 &&
                        product?.variants?.edges[0]?.node?.currentlyNotInStock
                      ? true
                      : false;

                  const featureTags = product?.tags?.filter((tag) =>
                    tag?.startsWith('features', 0),
                  );
                  const features = featureTags?.map(
                    (tag) => tag?.split(':')[2],
                  );
                  if (productAvailable) {
                    return (
                      <div key={idx} className={'specifications_section_bg'}>
                        <div
                          className={`specifications_section ${
                            selected &&
                            typeof selected[name] !== 'undefined' &&
                            selected?.[name]?.id === productId &&
                            'uninstalled'
                          }`}
                          onClick={(e) => {
                            if (
                              !infoModalProduct &&
                              e.target.className !== 'more_info'
                            ) {
                              handleUpsellChange(name, product);
                            }
                          }}
                        >
                          <div className='upsell_product_img'>
                            <Image
                              draggable={false}
                              src={productImage}
                              alt='warranty'
                              width={40}
                              height={40}
                            />
                          </div>
                          <div className='upsell_product_content'>
                            <p>{productTitle}</p>
                            {/* <Link href={`/products/${productHandle}`}> */}
                            <a
                              className='more_info'
                              onClick={() => {
                                setInfoModalProduct({
                                  productImage,
                                  productTitle,
                                  description: product?.description,
                                  features,
                                });
                              }}
                            >
                              More info
                              <img
                                draggable='false'
                                src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/moreinfo.png?v=1714032871'
                                alt='warranty'
                                style={{ marginLeft: 4 }}
                              />
                            </a>
                          </div>
                          <div className='our_price'>
                            <span className='atprice'>
                              {compareAtPrice ? '+$' + compareAtPrice : ''}
                            </span>
                            <span className='new_price'>
                              +${fixDecimalPlaces(productPrice)}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <p>No combinition has been found.</p>
              )
            ) : null}
          </div>
        </div>
        <style>
          {`
          .minus-icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
          }
        .text-center{
          text-align: center
        }
        .select_label {
          width: calc(100% - 95px);
          display: inline-block;
          text-align: left;
          padding-left: 20px;
        }
        .select_label .title {
          text-transform: capitalize;
        }
        .select_image {
            width: 65px;
            display: inline-block;
        }
        .modal_toggle {
            cursor: pointer;
            margin-top: 0px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            display: inline-block;
        }
        .title_container.select_product {
            align-items: center;
        }
        .our_boxes h5.title {
          margin: 0px;
          padding: 0;
          text-transform: uppercase;
        }
        .product_specifications .our_boxes span.new_price {
            padding-left: 10px;
            color: #7ED321;
            opacity: 0.75;
        }
        
        .title_container span.new_price {
            opacity: 0;
        }

        .selected_price {
          color: #7ED321;
        }
        .product_specifications .our_boxes span {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #E4E4E4;
          opacity: 0.75;
          overflow-wrap: break-word;
        }
        .uninstalled {
          border: 1px solid rgba(253,3,0,1);
        }
        .specifications_section_bg:hover .upsell_product_content p {
          opacity: 1;
        }
        .specifications_section:hover a.more_info {
          opacity: 1;
          color: #E4E4E4;
        }
        .specifications_section:hover a.more_info img {
          filter: contrast(0);
          opacity: 1;
        }
        .specifications_section:hover span.atprice {
          opacity: 1;
          color: #E4E4E4;
        }
        .specifications_section:hover span.new_price {
          opacity: 1;
          color: #E4E4E4;
        }
        a.more_info {
          display: inline-block;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #FD0300;
          cursor:pointer;
        }
        .our_price span {
          width: 100%;
          display: inline-block;
        }
        .product_specifications {  
          width: 100%;
          // border-top: 1px solid #242424;
          padding-top: 16px;

          
        }
        .upsell_product_content {
          width: calc(100% - 50px);
          display: inline-block;
          padding-left: 20px;
        }
        .upsell_product_content p {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #E4E4E4;
          margin: 0;
          opacity: 0.75;
        }
        .upsell_product_img {
          width: 70px;
          // height: 64px;
          display: inline-block;
      }
      .upsell_product_img img {
        width: 70px !important;
      }
        .specifications_section_bg .specifications_section{
          background: #242424;
          border-radius: 4px;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .our-text h2 {
            font-weight: 600;
            font-size: 13px;
            line-height: 24px;
            color: #949494;
            margin: 0px;
        }
        .our-text p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #949494;
            margin: 10px 0px 25px;
        }
        .our_boxes {
          margin: 0 auto;
          width: 100%;
          border: inherit;
          margin-top: 0;
          background: #141414;
          border-radius: 4px;
          padding: 8px 8px;
        }
        .title_container {
          display: flex;
          justify-content: start;
          position:relative;
        }
        .modal_toggle {
          cursor: pointer;
          margin-top: 0px;
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
        }
        .select_label .title {
          color: #E4E4E4;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          padding-left: 25px;
          opacity: 0.75;
        }
        .performance_section {
          display: flex;
          justify-content: space-between;
        }
        .specification_item {
          width: 100%;
          display: flex;
          margin-bottom: 16px;
        }
        .attribute {
          min-width: 40%;
          color: #949494;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
        }
        .attribute_value {
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: left;
        }
        .long_value {
          min-height: 50px;
        }
        span.atprice {
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            text-align: right;
            text-decoration-line: line-through;
            color: #949494;
            opacity: 0.75;
        }
        span.new_price {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          text-align: right;
          color: #7ED321;
          opacity: 0.75;
        }
        a.more_info>div {
            overflow: visible !important;
        }
        a.more_info img {
            top: 8px !important;
            left: 7px !important;
        }
        .specifications_section_bg {
            width: 100%;
            background: background: #141414;
            background: #141414;
            padding: 8px 8px;
            display: inline-block;
        }
        @media (max-width: ${breakPoints.medium}) {
          .product_specifications {
            width: 100%;
            padding: 0 20px 16px;
          }
          .attribute_value {
            font-size: 16px;
            line-height: 24px;
          }
        }
        @media (max-width: ${breakPoints.small}) {
          .specification_item {
            min-height: 48px;
          }
        }
        `}
        </style>
      </>
    );
};

export default ProductSpecification;
