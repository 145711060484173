import React from 'react';
import Image from 'next/image';

function MoreInfoModal({ product, handleClose }) {
  return (
    <>
      <div
        className="info-modal-bg"
        onClick={(e) => {
          const classText = e.target.className;
          if (classText.includes('info-modal-bg')) {
            handleClose(null);
          }
        }}
      >
        <div className="info-modal-container">
          <button className="close-button" onClick={() => handleClose(null)}>
            <img draggable="false"  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936" alt="close-x" />
          </button>
          <div className="info-modal-image-container">
            <Image draggable={false}
              src={product.productImage}
              alt="warranty"
              width={570}
              height={570}
              blurDataURL={product.productImage}
              placeholder="blur"
              objectFit="contain"
            />
          </div>
          <div className="info-modal-content-container">
            <p className="title">{product.productTitle}</p>
            <p className="description">{product.description}</p>
            <ul className="features">
              {product.features?.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <style jsx>{`
        .description {
          font-family: 'Roboto-Medium';
          margin-top: 30px;
          font-size: 18px;
        }
        .close-button {
          position: absolute;
          right: 0px;
          top: 5px;
          background: transparent;
          outline: none;
          border: none;
          z-index: 1;
        }
        .close-button img {
          height: 30px;
          width: 30px;
        }
        .features {
          margin-top: 30px;
        }
        .features li {
          list-style: disc;
          font-family: 'Roboto-Medium';
          font-size: 18px;
        }
        .title {
          font-family: 'big_noodle_titling';
          font-size: 28px;
          color: white;
          line-height: 30px;
          padding: 0;
          margin: 0;
          max-width: 90%;
        }
        .info-modal-container {
          display: grid;
          position: relative;
          overflow: auto;
          max-width: 1200px;
        }
        .info-modal-image-container {
          background: linear-gradient(#15181c, #2d3237, #15181c);
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 60px;
        }
        .info-modal-content-container {
          background: black;
          padding: 20px;
        }

        .info-modal-bg {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
          position: fixed;
          inset: 0;
          z-index: 1000;
          padding: 20px;
        }

        @media (min-width: 640px) {
          .info-modal-content-container,
          .info-modal-bg,
          .info-modal-image-container {
            padding: 40px;
          }
          .info-modal-bg {
            justify-content: start;
          }
        }

        @media (min-width: 991px) {
          .info-modal-container {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .features li {
            font-size: 20px;
          }
          .description {
            font-size: 20px;
          }
          .title {
            font-size: 30px;
          }
          .info-modal-bg {
            justify-content: center;
          }
          .info-modal-image-container {
            padding: 40px;
            padding-top: 40px;
          }

          .close-button img {
            height: 40px;
            width: 40px;
          }
        }
      `}</style>
    </>
  );
}

export default MoreInfoModal;
