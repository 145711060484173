export const kSocials = [
  {
    href: 'https://www.facebook.com/aftershockpcau',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/facebook-16px.png?v=1714064126',
    alt: 'facebook icon',
  },
  {
    href: 'https://www.instagram.com/aftershockpcau/',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/instagram-16px.png?v=1714064112',
    alt: 'instagram icon',
  },
  {
    href: 'https://twitter.com/aftershockau',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/twitter-16px.png?v=1714064103',
    alt: 'twiter icon',
  },
  {
    href: 'https://www.youtube.com/user/aftershocknotebooks',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/youtube-16px.png?v=1714064086',
    alt: 'youtube icon',
  },
];

export const kRegions = [
  {
    code: 'SG',
    country: 'Singapore',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/flag-singapore.png?v=1714052735',
    link: 'https://www.aftershockpc.com/',
  },
  {
    code: 'AU',
    country: 'Australia',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/flag-australia.png?v=1714052710',
    link: 'https://www.aftershockpc.com.au/',
  },
];
export const kFeatureList = [
  {
    groupType: 'graphics_card',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-gpu.png?v=1714038721',
  },
  {
    groupType: 'cpu',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-cpu_1.png?v=1714038645',
  },
  {
    groupType: 'ram',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-ram.png?v=1714038794',
  },
  {
    groupType: 'primary_ssd',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-ssd.png?v=1714038814',
  },
  {
    groupType: 'cpu_cooling_system',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-cooling_1.png?v=1714038855',
  },
  {
    groupType: 'cpu_cooling system',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-cooling_1.png?v=1714038855',
  },
  {
    groupType: 'motherboard',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-motherboard.png?v=1714038909',
  },
  {
    groupType: 'display',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-display.webp?v=1714038946',
  },

  {
    groupType: 'airflow',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/airflow.png?v=1714038998',
  },
  {
    groupType: 'chassis',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chassis.png?v=1714038998',
  },
  {
    groupType: 'color',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/color.png?v=1714038998',
  },
  {
    groupType: 'dimension',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/dimension.png?v=1714038998',
  },
  {
    groupType: 'glass_design',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/glass_design.png?v=1714038998',
  },
  {
    groupType: 'power',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/power.png?v=1714038998',
  },
  {
    groupType: 'screen',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/screen.png?v=1714038998',
  },
  {
    groupType: 'weight',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/weight.png?v=1714038998',
  },
  {
    groupType: 'special_usp',
    image: 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/special_usp.png?v=1714038998',
  },
];
