/* eslint-disable react/prop-types */
import Link from 'next/link';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import slugify from 'slugify';
import styles from './styles';

const ItemList = ({ item, onClick, checkMegaMenu, index }) => {
  const defaultImage = item?.items[0]?.cta_image?.url || null;
  const [ctaImageUrl, setCtaImageUrl] = useState(null);
  const [pageurl, setPageurl] = useState(null);
  const handleOut = () => {
    setCtaImageUrl(null);
  };

  useEffect(() => {
    checkMegaMenu(index);
  }, []);

  const handleContext = (e) => {
    e.preventDefault();
    window.open(e.target.dataset.pageurl, '_blank');
  };

  return (
    <div className='item-list-container'>
      <div className='item-list-title-group'>
        <div className='item-list-title'>{item?.primary?.title}</div>
        <div className='item-list-subtitle'>{item?.primary?.subtitle}</div>
      </div>

      <div
        className={`item-list-group ${
          item?.slice_type === '3rd_level' && 'mega-menu-center'
        }`}
      >
        <Link
          href={item.items[0].link || '#'}
          target={
            item.items[0].link === 'https://thetechyard.com/'
              ? '_blank'
              : '_self'
          }
        >
          {item?.items && (
            <div
              className={item.slice_type !== '3rd_level' && 'item-list-body'}
              onMouseLeave={handleOut}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='item-list-image'>
                {defaultImage && !ctaImageUrl && (
                  <div className='cta-img'>
                    {item?.slice_type === '3rd_level' ? (
                      <>
                        <Link
                          href={item.items[0].link || '#'}
                          target={
                            item.items[0].link === 'https://thetechyard.com/'
                              ? '_blank'
                              : '_self'
                          }
                        >
                          <div
                            className='rts-image-container'
                            onClick={onClick}
                          >
                            <Image
                              draggable={false}
                              layout='fixed'
                              src={defaultImage}
                              width={200}
                              height={180}
                              alt='rts_image'
                              data-pageurl={item.items[0].link}
                              onContextMenu={(e) =>
                                (item.items[0].link !==
                                  'https://thetechyard.com/' ||
                                  item.items[0].link !==
                                    'https://theomnidesk.com/') &&
                                handleContext(e)
                              }
                              onClick={(e) => {
                                (item.items[0].link ===
                                  'https://thetechyard.com/' ||
                                  item.items[0].link ===
                                    'https://theomnidesk.com/') &&
                                  handleContext(e);
                              }}
                              loading='lazy'
                            />
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Image
                          draggable={false}
                          src={defaultImage}
                          width={120}
                          height={120}
                          alt='default_image'
                          data-pageurl={item.items[0].link}
                          onContextMenu={(e) => handleContext(e)}
                          loading='lazy'
                        />
                      </>
                    )}
                  </div>
                )}
                {ctaImageUrl && (
                  <div className='cta-img'>
                    {item?.slice_type === '3rd_level' ? (
                      <Link
                        href={item.items[0].link || '#'}
                        target={
                          item.items[0].link === 'https://thetechyard.com/'
                            ? '_blank'
                            : '_self'
                        }
                      >
                        <div className='rts-image-container' onClick={onClick}>
                          <Image
                            draggable={false}
                            layout='fixed'
                            src={defaultImage}
                            width={200}
                            height={180}
                            alt='rts_image'
                            data-pageurl={item.items[0].link}
                            onContextMenu={(e) => handleContext(e)}
                            loading='lazy'
                          />
                        </div>
                      </Link>
                    ) : (
                      <Image
                        draggable={false}
                        src={ctaImageUrl}
                        width={120}
                        height={120}
                        alt='cta_image'
                        data-pageurl={item.items[0].link}
                        onContextMenu={(e) => handleContext(e)}
                        loading='lazy'
                      />
                    )}
                  </div>
                )}
              </div>
              <div
                className='items-list'
                style={
                  item?.slice_type === '3rd_level' ? { paddingLeft: '0px' } : {}
                }
              >
                {item.items.map((productItem, index) => {
                  const handleSelect = () => {
                    setCtaImageUrl(productItem?.cta_image?.url);
                    setPageurl(productItem?.link);
                  };
                  const key = slugify(`item-list-${index}`);
                  return (
                    <div className='items-list-title-group' key={key}>
                      {productItem?.link && (
                        <a
                          className='items-list-title'
                          href={productItem.link || '#'}
                          onMouseOver={handleSelect}
                          onClick={onClick}
                          onFocus={() => undefined}
                          aria-hidden='false'
                          style={
                            item?.slice_type === '3rd_level'
                              ? { textAlign: 'center' }
                              : {}
                          }
                          target={
                            item.items[0].link === 'https://thetechyard.com/'
                              ? '_blank'
                              : '_self'
                          }
                        >
                          {productItem?.title}
                        </a>
                      )}

                      <div className='navbar-subtitle'>
                        {productItem?.navbar_subtitle}
                      </div>

                      <div className='items-list-subtitle'>
                        {productItem?.subtitle}
                      </div>
                    </div>
                  );
                })}
                {item?.primary?.link && (
                  <div aria-hidden='false' className='link-group'>
                    <Link href={item.primary.link || '#'} passHref>
                      <div
                        onClick={onClick}
                        aria-hidden='false'
                        onFocus={() => undefined}
                        className='link-button'
                      >
                        Shop all
                      </div>
                    </Link>
                    <Image
                      draggable={false}
                      height={16}
                      width={16}
                      src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/arrow-right-red.svg?v=1714040060'
                      alt='link-button'
                      loading="lazy"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Link>
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};
export default ItemList;
