import Image from 'next/image';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Error = ({ code, message }) => {
	const router = useRouter();
	useEffect(() => {
		const handlePageReload = () => {
			if (performance.navigation.type === 1) {
				router.replace('/');
			}
		};
		window.addEventListener('load', handlePageReload);

		return () => {
			window.removeEventListener('load', handlePageReload);
		};
	}, [router]);

	return (
		<>
			<div className="error_container">
				<div>
					<Image src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/error_1.png?v=1714049088" width="645" height="923" />
				</div>
				<div className="error_message">
					<h1>OOPS!</h1>
					<h2>LOOKS LIKE YOU OPENED A {code}.</h2>
					<p>{message}</p>
					<div className="subscribe_container">
						<a href="/" className="subscribe_button">
							TAKE ME HOME
						</a>
					</div>
				</div>
			</div>
			<style jsx>
				{`
					.error_container {
						background-color: black;
						color: #e4e4e4;
						font-family: 'big_noodle_titling';
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						min-height: 500px;
					}
					.error_message {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}
					.error_message > h1 {
						font-family: 'Eurostile-Bold', sans-serif;
						font-weight: 900;
						letter-spacing: 4px;
						font-size: 65px;
						margin: 0 auto 1rem;
					}
					.error_message > h2 {
						font-family: 'Eurostile-Bold', sans-serif;
						font-weight: 900;
						font-size: 35px;
						margin: 0 auto 1rem;
					}
					.error_message > p {
						font-family: 'Roboto-Medium', sans-serif;
						font-weight: 900;
						font-size: 16px;
						margin: 0;
						width: 50%;
						text-align: center;
						margin: 0 auto 1rem;
					}
					.subscribe_container {
						width: 200px;
					}
					.subscribe_button {
						display: block;
						height: 40px;
						background-color: #950810;
						font-family: 'Roboto-Medium';
						font-size: 16px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 40px;
						text-align: center;
					}
					.subscribe_button:hover {
						background-color: #800006;
					}
					@media (max-width: ${breakPoints.small}) {
						.error_container {
							flex-direction: column-reverse;
						}
						.error_message {
							margin-top: 2rem;
						}
						.error_message > h1 {
							font-size: 32px;
						}
						.error_message > h2 {
							font-size: 18px;
						}
						.error_message > p {
							font-size: 12px;
							width: 75%;
						}
						.subscribe_container {
							width: 120px;
						}
						.subscribe_button {
							font-size: 13px;
						}
					}
				`}
			</style>
		</>
	);
};

Error.propTypes = {
	code: PropTypes.number,
	message: PropTypes.string,
};

Error.defaultProps = {
	code: 404,
	message:
		'We are currently experiencing technical difficulties. We hope to be back with you shortly. Please try refreshing the page or heading to our main page.',
};

export default Error;

