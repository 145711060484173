import React, { useState, useEffect } from "react";
import breakPoints from "helpers/Styles";
import NotifyMe from "components/NotifyMe";
import AddToBagRTS from "../AddToBag/rts";
import {
  fixProductsPriceObjectModel,
  fixVariantPriceObjectModel,
} from 'helpers/fixPriceObjectModel';
import { fixDecimalPlaces } from 'helpers/smallFixes';

const ProductAddToCart = ({
  sameDayShipping,
  productTitle,
  productPrice,
  productVariant,
  productCompareAtPrice,
  productId,
  productAvailable,
  products,
  customDesignUrl,
  freight,
  deliveryDays,
	collectionDays,
	gcidx
}) => {
  const discount = productCompareAtPrice - productPrice;
  const title = productTitle.split('/')[0];
  const subtitle = productTitle.split('/')[1];
  const sameDayShipAvab =
    sameDayShipping.length > 0 && sameDayShipping[0].split(':')[1];

  const [showShipping, setShowShipping] = useState(true);

  useEffect(() => {
    if (productTitle === "Aftershock Gift Card") {
      setShowShipping(false)
    }
  }, [productTitle])

  return (
    <>
      <div
        className={
          'main_desktop_footer ' + (!productAvailable ? 'sold_pro' : '')
        }
      >
		{ showShipping &&
        <div className="footer_right">
          <div className="delivery">
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5455 3.5H13.0909V0H1.63636C0.736364 0 0 0.7875 0 1.75V11.375H1.63636C1.63636 12.8275 2.73273 14 4.09091 14C5.44909 14 6.54545 12.8275 6.54545 11.375H11.4545C11.4545 12.8275 12.5509 14 13.9091 14C15.2673 14 16.3636 12.8275 16.3636 11.375H18V7L15.5455 3.5ZM15.1364 4.8125L16.74 7H13.0909V4.8125H15.1364ZM4.09091 12.25C3.64091 12.25 3.27273 11.8562 3.27273 11.375C3.27273 10.8938 3.64091 10.5 4.09091 10.5C4.54091 10.5 4.90909 10.8938 4.90909 11.375C4.90909 11.8562 4.54091 12.25 4.09091 12.25ZM5.90727 9.625C5.45727 9.09125 4.81909 8.75 4.09091 8.75C3.36273 8.75 2.72455 9.09125 2.27455 9.625H1.63636V1.75H11.4545V9.625H5.90727ZM13.9091 12.25C13.4591 12.25 13.0909 11.8562 13.0909 11.375C13.0909 10.8938 13.4591 10.5 13.9091 10.5C14.3591 10.5 14.7273 10.8938 14.7273 11.375C14.7273 11.8562 14.3591 12.25 13.9091 12.25Z"
                fill="#E4E4E4"
              />
            </svg>

            <span className="shipping">Delivery</span>
            {sameDayShipAvab === 'same-day-shipping' ? (
              <span className="next-content">
                Regular and same day delivery options on checkout
              </span>
            ) : sameDayShipAvab === 'next-day-shipping' ? (
              <span className="next-content">Dispatched next business day</span>
            ) : sameDayShipAvab === 'pre-order' ? (
              <span className="next-content">
                Estimated time frame for product can be obtained from our live chat or at checkout
              </span>
            ) : sameDayShipAvab === 'delayed' ? (
              <span className="next-content">
                Dispatched between 5-15 business days
              </span>
            ) : (
              <span className="next-content">
                Dispatched between {deliveryDays ? deliveryDays : '3-7'} business days
              </span>
            )}
          </div>
          {freight !== 'dropship' && (
            <div className="collection">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.00039 1.5V0H18.0504V1.5H2.00039ZM2.12539 16V9.55H0.900391V8.05L2.00039 3H18.0254L19.1254 8.05V9.55H17.9004V16H16.4004V9.55H11.6754V16H2.12539ZM3.62539 14.5H10.1754V9.55H3.62539V14.5ZM2.37539 8.05H17.6504H2.37539ZM2.37539 8.05H17.6504L16.8754 4.5H3.15039L2.37539 8.05Z"
                  fill="#E4E4E4"
                />
              </svg>

              <span className="shipping">Collection</span>
              <span className="next-content">
                {sameDayShipAvab === 'same-day-shipping' ||
                sameDayShipAvab === 'next-day-shipping'
                  ? 'Same day collection subjected to availability & request.'
                  : sameDayShipAvab === 'pre-order'
                  ? `Estimated time frame for product can be obtained from our live chat or at checkout`
                  : sameDayShipAvab === 'delayed'
                  ? 'Pickup available between 5-15 business days'
                  : `Pickup available ${collectionDays ? collectionDays : '1-5'} business days`}
              </span>
            </div>
          )}
        </div>
		}
        <div className="product_description add2CartDesc">
          <div>
            <div className="price_section">
              <div className="save_price">
                {productCompareAtPrice && discount > 0 ? (
                  <div className="compareAtPrice">
                    <div className="original_price">
                      <del>
                        {fixDecimalPlaces(productCompareAtPrice)}
                      </del>
                    </div>
                  </div>
                ) : null}
                {discount > 0 && (
                  <div className="save-am">
                    You save ${fixDecimalPlaces(discount)}
                  </div>
                )}
              </div>
              {productPrice && (
                <div className="product_price">
                  $
                  {fixDecimalPlaces(productPrice)}
                </div>
              )}
            </div>
          </div>
          <div className="car_button cart_btn">
            <AddToBagRTS
              products={fixProductsPriceObjectModel(products)}
              soldOut={!productAvailable}
              disabled={!productAvailable}
              productTitle={productTitle}
              selectedVariant={fixVariantPriceObjectModel(productVariant)}
              productVariantRTS={fixVariantPriceObjectModel(productVariant)}
              productId={productId}
              customizedClass="add_to_cart"
              customDesignUrl={customDesignUrl}
						  gcidx={gcidx}
            />
          </div>
          {!productAvailable && (
            <div className="notifyRes">
              <NotifyMe
                title={title}
                product={productId}
                variant={productVariant?.id}
              />
            </div>
          )}
        </div>
      </div>
      <style>
        {`
          .notifyRes {
            padding-left: 20px;
          } 
          .main_desktop_footer {
            display: flex;
            width: 100%;
            align-items: center;
          }
          .footer-title {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            text-decoration-line: underline;
          } 
          .main_desktop_footer .delivery {
            margin-bottom: 12px;
          }
          .main_desktop_footer .footer_right {
            padding: 0;
            width: 80%;
          }
          /main_desktop_footer .footer_right span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #E4E4E4;
          }
          .main_mobile_footer .footer_right img {
            width: 12px;
            height: auto;
            margin-right: 10px;
            top: 2px;
          }
          .main_desktop_footer .shipping {
            margin-left: 10px;
          }
          .main_desktop_footer .next-content {
              color: #949494 !important;
              padding-left: 12px;
              font-weight: 400 !important;
          }
          .main_desktop_footer .next-content a {
              text-decoration: underline;
              padding-left: 6px;
          }
          .car_button.cart_btn button {
            font-style: normal;
            font-weight: 600 !important;
            font-size: 13px !important;
            line-height: 24px !important;
            text-align: center;
            color: #E4E4E4 !important;
            padding: 12px 58px;
            margin-left: 0px;
          }
          
          .short_description {
            padding: 10px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
          .add2CartDesc {
            margin: 0 auto;
            width: 50%;
            box-sizing: border-box;
            margin-top: 0px;
            display: flex;
            align-items: center;
            justify-content: right;
          }
          .product_title {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            margin: 0;
          }
          .price_section {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .currency {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            margin-top: 10px;
            margin-right: 10px;
          }

          .product_subtitle {
            color: #949494;
            font-family: 'Eurostile-Bold';
            font-size: 20px;
            letter-spacing: 0;
            line-height: 40px;
            margin: 0;
          }
          .product_price {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
          }
         
          .compareAtPrice {
              width: 100%;
              display: inherit;
              padding: 0
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              text-decoration-line: line-through;
              color: #949494;
          }
        .price_section {
            flex-wrap: wrap;
            justify-content: right;
         }

          .original_price {
            width: 100%;
            text-align: right;
            margin: 0 !important;
        }
        .save_price {
            display: flex;
            flex-wrap: wrap;
            padding-right: 10px;
        }
        .save-am {
            width: 100%;
            text-align: right;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #FD0300;
        }

      
        .main_mobile_footer .footer_right {
            padding: 0 !important;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
        .main_mobile_footer {
              width: 100%;
          }
        .cart__mobile{
            /* display: none; */
            padding-bottom: 30px;
        }
        .car_button.cart_btn {
            margin-left: 0px;
        }


          @media (max-width: ${breakPoints.medium}) {
            .add2CartDesc {
              width: 100%;
            }
            .product_title,
            .product_price {
              font-size: 32px;
            }
            .product_subtitle {
              font-size: 23px;
            }
            .notifyRes {
              padding-left: 0px !important;
              margin-top:10px;
            }
            .product_footer.jsx-2741476128 {
                position: relative;
            }
            .add2CartDesc {
                flex-wrap: wrap;
            }
              .show_content_footer {
                height: auto;
                position: absolute;
                bottom: 138px;
                padding: 0px 15px;
                
            }
            .show_content_footer .car_button.cart_btn button {
                margin-left: 0;
            }
            .main_mobile_footer .delivery {
                padding-top: 20px;
            }
            .car_button.cart_btn {
                padding-top: 10px;
            }
            .show_content_footer .contact_container li img {
              margin-right: 10px;
          }
          }
        `}
      </style>
    </>
  );
};

export default ProductAddToCart;
