import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { db } from '../../../utils/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
} from 'firebase/firestore';
import image from 'next/image';

const DropdownOption = ({
  options,
  setActiveOptionImage,
  setProgress1080P,
  setProgress1440P,
  setProgress4KP,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (
      options.filter((e) => e.name === 'Warzone 2').length > 0 ||
      options.filter((e) => e.Name === 'Warzone 2').length > 0
    ) {
      const index = options.findIndex(
        (x) => x.Name === 'Warzone 2' || x.name === 'Warzone 2',
      );
      setActiveOption(options[index].name || options[index].Name);
    } else {
      setActiveOption(options[0].name || options[0].Name);
    }
    fetchImages();
  }, [options]);

  const [activeOption, setActiveOption] = useState(
    options[0].name || options[0].Name,
  );

  const fetchImages = async () => {
    const q = query(collectionGroup(db, 'rts-games-db'));
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    setImages(data);
  };

  const changeOption = async (option) => {
    const i = images.findIndex(
      (item) => item.name === option || item.Name === option,
    );
    setActiveOptionImage(images[i].img);
    setActiveOption(option);
  };

  return (
    <>
      <label className="dropdown">
        <div className="dd-button">{activeOption}</div>

        <input type="checkbox" className="dd-input" />

        <ul className="dd-menu" id="scroll">
          {options.map((option, i) => (
            <li
              key={i}
              onClick={() => {
                setProgress1080P(option.FPS1080),
                setProgress1440P(option.FPS1440),
                setProgress4KP(option.FPS4K || option.FPS4k),
                changeOption(option.name || option.Name);
              }}
            >
              {option.name || option.Name}
            </li>
          ))}
        </ul>
      </label>
      <style jsx>{styles}</style>
    </>
  );
};

DropdownOption.propTypes = {
  options: PropTypes.object.isRequired,
  setActiveOptionImage: PropTypes.func.isRequired,
  setProgress1080P: PropTypes.func,
  setProgress1440P: PropTypes.func,
};

export default DropdownOption;
