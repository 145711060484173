
 import css from 'styled-jsx/css';

 export default css`
  .dropdown {
    display: inline-block;
    position: relative;
    color: black;
    width: 90%;
  }
  .dd-button {
    width: 100%;
    display: inline-block;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;
    color: #b1b1b1
  }
  .dd-button:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #b1b1b1;
  }
  .dd-button:hover {
    background-color: transparent;
  }
  .dd-input {
    display: none;
  }
  .dd-menu {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    list-style-type: none;
    z-index: 999;
    width: 100%;
    background-color: #181818;
    color: #b1b1b1;
    max-height: 155px;
    overflow-y: auto;
  }
  .dd-input + .dd-menu {
    display: none;
  } 
  .dd-input:checked + .dd-menu {
    display: block;
  } 
  .dd-menu li {
    padding: 5px 10px 5px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  .dd-menu li:hover {
    background-color: #323232;
  }
  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  .dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
  #scroll::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  #scroll::-webkit-scrollbar
  {
    width: 4px;
    background-color: #F5F5F5;
  }

  #scroll::-webkit-scrollbar-thumb
  {
    background-color: #950810;
  }
 `;