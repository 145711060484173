import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import breakPoints from 'helpers/Styles';
import PropTypes from 'prop-types';

const ImageViewer = ({
  imagesGallery,
  handleModalClose,
  imageIndex,
  handleImageIndex,
}) => {
  const [imageViewer, setImageViewer] = useState(null);

  const handleCloseImage = () => {
    handleModalClose(false);
    handleImageIndex(0);
  };

  useEffect(() => {
    setImageViewer(imagesGallery);
  }, [imagesGallery]);

  return (
    <>
      <div className="image_viewer_mask" />
      {imagesGallery.map((item, id) => {
        const handelLastImage = () => {
          handleImageIndex(imageIndex - 1);
        };
        const handleNextImage = () => {
          handleImageIndex(imageIndex + 1);
        };
        return (
          <div key={id}>
            {id === imageIndex ? (
              <div className="image_viewer_container" id="imageViewer">
                <div className="image_viewer">
                  {imageViewer && (
                    <Image draggable={false}
                      src={imageViewer[imageIndex].node.originalSrc}
                      alt="view_image"
                      width={700}
                      height={700}
                    />
                  )}
                </div>

                <>
                  <div
                    className="last_image"
                    onClick={handelLastImage}
                    id="viewImage"
                  >
                    <Image draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_left_light.png?v=1714040352"
                      alt="last_image"
                      width={25}
                      height={25}
                    />
                  </div>
                  {imageViewer && imageViewer.length > imageIndex + 1 ? (
                    <div
                      className="next_image"
                      onClick={handleNextImage}
                      id="viewImage"
                    >
                      <Image draggable={false}
                        src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_right_light.png?v=1714040402"
                        alt="next_image"
                        width={25}
                        height={25}
                      />
                    </div>
                  ) : null}
                </>

                {/* <div className="close_view" onClick={handleCloseImage} /> */}
                <div 
                  onClick={handleCloseImage} 
                  className="close_view"
                  style={{ backgroundImage: `url('https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756')` }}
                  onMouseEnter={(event) => { 
                    event.target.style.backgroundImage = "url('https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close-hover_1.png?v=1714063075')";
                  }}
                  onMouseLeave={(event) => { 
                    event.target.style.backgroundImage = `url('https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756')`;
                  }}
                />
              </div>
            ) : null}
          </div>
        );
      })}

      <style jsx>
        {`
          .image_viewer_container {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background: #141414;
            z-index: 10;
          }
          .image_viewer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;
            height: 100%;
            margin: 0 auto;
            z-index: 10;
          }
          .image_viewer img {
            min-width: 60%;
            height: 90%;
          }
          .close_view {
            height: 25px;
            width: 25px;
            position: absolute;
            top: 12%;
            right: 10%;
            cursor: pointer;
          }
          .last_image {
            position: absolute;
            top: 50%;
            left: 10%;
            cursor: pointer;
          }
          .next_image {
            position: absolute;
            top: 50%;
            right: 10%;
            cursor: pointer;
          }
          @media (max-width: ${breakPoints.medium}) {
            .image_viewer img {
              width: 100% !important;
              min-height: 400px !important;
            }
            
          @media (max-width: ${breakPoints.small}) {
            .last_image {
              left: 3%;
            }
            .next_image, .close_view {
              right: 3%;
            }
            .image_viewer img {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

ImageViewer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  imagesGallery: PropTypes.array.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleImageIndex: PropTypes.func.isRequired,
  imageIndex: PropTypes.number.isRequired,
};

export default ImageViewer;
