import React, { useState } from 'react';
import { fGetPlural } from 'utils/functions';

function BannerCountdownTimer({ date, handleCloseBanner, hideBannerTimerRunsOut }) {
  const [daysText, setDaysText] = useState(0);
  const [hoursText, setHoursText] = useState(0);
  const [minutesText, setMinutesText] = useState(0);
  const [secondsText, setSecondsText] = useState(0);
  const [timerRunsOut, setTimerRunsOut] = useState(true);
  const timerDateTime = new Date(date).getTime();
  var timeInterval = setInterval(function () {
    var now = new Date().getTime();
    var timeleft = timerDateTime - now;
    if (timeleft < 0) {
      clearInterval(timeInterval);
      if (hideBannerTimerRunsOut) {
        handleCloseBanner();
      }
      setTimerRunsOut(false);
    }
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    setDaysText(days);
    setHoursText(hours);
    setMinutesText(minutes);
    setSecondsText(seconds);
    if (timerDateTime - now < 0) {
      clearInterval(timeInterval);
    }
  }, 1000);

  return (
    <div className="countdown-text">
      <span className="countdown-time">
        { timerRunsOut &&
          " | Ends in"
        }
        {daysText > 0 && (
          <span>
            {fGetPlural({
              number: daysText,
              plural: 'd',
              singular: 'd',
            })}
          </span>
        )}
        {hoursText > 0 && (
          <span>
            {fGetPlural({
              number: hoursText,
              plural: 'h',
              singular: 'h',
            })}
          </span>
        )}
        {minutesText > 0 && (
          <span>
            {fGetPlural({
              number: minutesText,
              plural: 'm',
              singular: 'm',
            })}
          </span>
        )}
        {secondsText > 0 && (
          <span>
            {fGetPlural({
              number: secondsText,
              plural: 's',
              singular: 's',
            })}
          </span>
        )}
      </span>
      <style>
        {`
        .countdown-time{
            display:flex;
            gap:3px;
            margin-left: 5px;
        }
        `}
      </style>
    </div>
  );
}

export default BannerCountdownTimer;
