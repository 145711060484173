/* global window */
/* global CustomEvent  */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  createCheckout,
  addToCartVars,
  checkoutLineItemsAdd,
  checkoutAddLineItemVars,
} from "helpers/graphql/checkout";
import { StorefrontContext } from "providers/storefront";
import { useMutation } from "@apollo/client";
import { getCheckoutId } from "helpers/cookies";
import { addToCartKlaviyo } from "helpers/klaviyo";
import { addToCartEvent } from "lib/events/fb-pixel/add-to-cart";
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';
import TiktokPixel from 'tiktok-pixel';

import {
  sendShopifyAnalytics,
  AnalyticsEventName,
  getClientBrowserParameters
} from '@shopify/hydrogen-react';

const AddToBag = ({
  productTitle,
  productPrice,
  productHandle,
  productImage,
  productId,
  disabled,
  soldOut,
  qty,
  selectedVariant,
  customizedClass,
  productTags,
  productSKU,
}) => {
  const instance2Id =
    productTags &&
    productTags.find((tag) => tag.startsWith("instance2"))?.split(":")?.[1];

  const customAttributes = [];
  if (instance2Id) {
    customAttributes.push({
      key: "_lineitem1",
      value: JSON.stringify({
        variantId: instance2Id,
        title: productTitle,
      }),
    });
  }
  const { setIsCartOpen, setCheckout, checkout } =
    useContext(StorefrontContext);
  const text = soldOut ? "SOLD OUT" : "ADD TO CART";
  const fullClassName =
    customizedClass + (disabled ? " button--disabled sold-out-button" : "");

  const [values, setValues] = useState({
    variantId: selectedVariant ? selectedVariant.id : null,
    quantity: Number(qty),
    customAttributes,
  });

  console.log({ values });

  useEffect(() => {
    setValues({
      variantId: selectedVariant ? selectedVariant.id : null,
      quantity: Number(qty),
      customAttributes,
    });
  }, [selectedVariant, qty]);

  const [
    checkoutCreateMutation,
    {
      loading: checkoutCreateMutationLoading,
      error: checkoutCreateMutationError,
      // data: checkoutCreateMutationData,
    },
  ] = useMutation(createCheckout, {
    variables: addToCartVars(values),
    update(proxy, { data: { checkoutCreate } }) {
      setCheckout(checkoutCreate.checkout);
      setIsCartOpen(true);
    },
  });

  const [
    checkoutLineItemsAddMutation,
    {
      loading: checkoutLineItemsAddMutationLoading,
      error: checkoutLineItemsAddMutationError,
      // data: checkoutLineItemsAddMutationData,
    },
  ] = useMutation(checkoutLineItemsAdd, {
    variables: checkoutAddLineItemVars({
      ...values,
      checkoutId: getCheckoutId(),
    }),
    update(proxy, { data: { checkoutLineItemsAdd } }) {
      setCheckout(checkoutLineItemsAdd.checkout);
      setIsCartOpen(true);
    },
  });

  const  sendAddToCart = (analyticsPageData, product) => {
    const payload = {
      products: [
        {
          productGid: product[0].id,
          name: product[0].title,
          brand: product[0].vendor,
          price: Number(product[0].variants.edges[0].node.price),
          variantGid: product[0].variants.edges[0].node.id,
          variantName: product[0].variants.edges[0].node.title,
          category: product[0].handle,
          sku: product[0].variants.edges[0].node.sku,
          quantity: Number(qty),
        }
      ],
      ...analyticsPageData,
      ...getClientBrowserParameters(),
    };

    sendShopifyAnalytics({
      eventName: AnalyticsEventName.ADD_TO_CART,
      payload
    });
  }

  const analyticsShopData = {
    shopId: 'gid://shopify/Shop/63704072436',
    currency: 'SGD',
    acceptedLanguage: 'en',
    hasUserConsent: true,
  };

  const analytics = {
    ...analyticsShopData,
  };

  const handleClick = () => {
    // get checkout id again in case it has been changed by any other process
    const checkoutId = getCheckoutId();
    if (!values.variantId) return;
    if (checkoutId) {
      checkoutLineItemsAddMutation();
    } else {
      checkoutCreateMutation();
    }
    addToCartKlaviyo(
      productTitle,
      productId,
      productSKU,
      productImage,
      productHandle,
      productPrice,
      productTags,
      checkout
    );
    if (typeof TriplePixel !== 'undefined')
      TriplePixel('AddToCart', {
        item: selectedVariant.id,
        q: qty,
      });

    fbEvent({
      eventName: 'AddToCart',
      products: [{
        sku: productSKU,
        quantity: qty,
      }],
      value: productPrice,
      currency: 'SGD',
      enableStandardPixel: false
    });

    const options = {
      debug: false,
    };

    TiktokPixel.init(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID, options);
    TiktokPixel.track('AddToCart', {
      content_type: 'product',
      quantity: qty,
      content_name: productSKU,
      content_id: '1',
      currency: 'SGD',
      value: productPrice,
    });

    sendAddToCart(analytics, products);

    window.dispatchEvent(
      new CustomEvent("AddToCart", {
        detail: {
          productTitle: selectedVariant.title,
          productCategory: "Gaming",
          productId: [selectedVariant.id],
          productType: "product",
          productPrice: selectedVariant.priceV2.amount,
          productCurrency: "SGD",
        },
      })
    );
    addToCartEvent({
      title: productTitle,
      variantId: Buffer.from(selectedVariant.id)
        .toString('base64')
        ?.replace('gid://shopify/ProductVariant/', ''),
      qty: Number(qty),
      price: selectedVariant.priceV2.amount,
    });
  };

  if (checkoutCreateMutationError || checkoutLineItemsAddMutationError) {
    if (checkoutCreateMutationError) console.log(checkoutCreateMutationError);
    if (checkoutLineItemsAddMutationError)
      console.log(checkoutLineItemsAddMutationError);
  }

  return (
    <div
      className={`${
        customizedClass === 'essential_products_cart'
          ? 'add_cart_container'
          : ''
      }`}
    >
      <button
        className={fullClassName}
        onClick={handleClick}
        type="button"
        disabled={
          !!(
            disabled ||
            checkoutCreateMutationLoading ||
            checkoutLineItemsAddMutationLoading
          )
        }
      >
        {checkoutCreateMutationLoading || checkoutLineItemsAddMutationLoading
          ? 'Adding to cart ...'
          : text}
      </button>
      {customizedClass === 'essential_products_cart' ? (
        <div className="arrow_right">
          <img draggable="false"  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_arrow_5.png?v=1714047897" alt="arrow_icon" />
        </div>
      ) : null}
      <style jsx>
        {`
          .add_to_cart {
            background: #950810;
            width: 100%;
            height: 48px;
            outline: none;
            border: 0;
            border-radius: 3px;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 48px;
            padding: 0;
          }
          .add_cart_container:hover > button {
            background: #800006;
          }
          .add_cart_container:hover > .arrow_right {
            background: #141414;
          }
          .add_to_cart button:hover {
            background: #800006;
          }
          .add_cart_container {
            display: flex;
          }
          .essential_products_cart {
            background: #950810;
            width: 80%;
            height: 48px;
            outline: none;
            border: 0;
            border-radius: 3px 0 0 3px;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 48px;
            padding: 0;
          }
          .arrow_right {
            width: 48px;
            height: 48px;
            background: #242424;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 0px 3px 3px 0px;
          }
          .arrow_right img {
            width: 25px;
          }
          .sold-out-button {
            background-color: #646464;
            color: #e4e4e4;
            font-family: 'Roboto-Bold';
            font-size: 16px;
            line-height: 24px;
          }
        `}
      </style>
    </div>
  );
};

AddToBag.propTypes = {
  disabled: PropTypes.bool,
  soldOut: PropTypes.bool,
  className: PropTypes.string,
  qty: PropTypes.number,
  selectedVariant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    priceV2: PropTypes.shape({
      amount: PropTypes.string.isRequired,
    }),
  }),
};

AddToBag.defaultProps = {
  disabled: false,
  soldOut: false,
  className: "add_to_bag",
  qty: 1,
  selectedVariant: {},
};

export default AddToBag;
