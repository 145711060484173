import { infoLog, eventLog, EVENT_DEBUG } from './general';

export const addToCartEvent = (product) => {
  if (EVENT_DEBUG && !window?.fbq) {
    infoLog('Add to Cart: No FBQ found');
  }

  if (window?.fbq) {
    const detail = {
      content_type: 'product',
      content_name: product?.title,
      contents: [{ id: product?.variantId, quantity: product.qty }],
      currency: 'SGD',
      value: parseFloat(product?.price),
    };
    window?.fbq('track', 'AddToCart', detail);
    if (EVENT_DEBUG) eventLog('AddToCart', { detail });
  }
};
