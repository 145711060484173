/* eslint-disable react/prop-types */
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styles from './styles';

const Components = ({ item }) => {

  const { primary } = item[0];
  return (
    <>
      <div className="main-container">
        <div className="component-section">
          <div className="component-container">
            <p className="component-title">{primary.title}</p>

            <div style={{ width: '100%' }}>
              {item[0].items.map((item, i) => {
                return (
                  <div
                    className="component-box"
                    onClick={() => (window.location.href = item.link)}
                    key={i}
                  >
                    <Image draggable={false}
                      layout="fixed"
                      src={item.component_image.url}
                      width={100}
                      height={102}
                      alt="rts_image_navcom"
                      loading="lazy"
                    />
                    <p className="component-box-title">
                      {item.component_title}
                    </p>
                  </div>
                );
              })}

              <div className="component-box-see-more">
                <p
                  onClick={() =>
                    (window.location.href = primary.component_see_more_link)
                  }
                  className="see-more"
                >
                  {'See more >'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tech-yard-section">
          <div>
            <Image draggable={false}
              layout="fixed"
              src={primary.tech_yard_image.url}
              width={280}
              height={130}
              alt="rts_image_navcom"
              loading="lazy"
            />
            <p className="tech-yard-title">{primary.tech_yard_title}</p>
            <p className="tech-yard-description">
              {primary.tech_yard_description}
            </p>

            <a
              href={primary.tech_yard_link}
              target="_blank"
              className="tech-yard-visit"
            >
              {'Learn More >'}
            </a>
          </div>
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};
export default Components;
