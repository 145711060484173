import Product from 'containers/Product';
import ProductAccessories from 'containers/Product/product-accessories';
import ProductRTS from 'containers/Product/product-rts';
import PropTypes from 'prop-types';
import globalProps from 'utils/globalProps';
import {
  PRODUCT_BY_HANDLE,
  productByHandleVars,
} from 'helpers/graphql/product';
import client from 'lib/shopify-storefront/client';
import Error from 'components/Error';
import Loader from 'components/Loader';
import { useRouter } from 'next/router';
import { productView } from 'lib/events/fb-pixel/product-view';
import { useEffect, createContext, useState } from 'react';
import Head from 'next/head';

import ContainedHead from 'components/ContainedHead';
import { fixProductDataMetafields } from 'helpers/fixPriceObjectModel';
import Prismic from 'helpers/Prismic';
import Script from 'next/script';
import {
  fetchMetaObjects,
  transformMetaObjects,
} from 'helpers/metaObjectFetchers';

const P = ({
  metaObjects,
  productData,
  global,
  accessory_list,
  disclaimer,
}) => {
  const { isFallback, asPath } = useRouter();
  if (isFallback) {
    return <Loader />;
  }
  if (
    productData === null ||
    productData.product === null ||
    productData?.product.tags.includes('custom')
  ) {
    return <Error code={404} />;
  }
  useEffect(() => {
    productView(productData?.product);
  }, []);

  useEffect(() => {
    localStorage.setItem('giftcard', 0);
  }, []);

  // console.log("metaObjects", metaObjects);

  return (
    <>
      <Head>
        <link
          rel='preconnect dns-prefetch'
          href='https://triplewhale-pixel.web.app/'
          crossOrigin='true'
        />
        <link
          rel='preconnect dns-prefetch'
          href='https://api.config-security.com/'
          crossOrigin='true'
        />
        <meta
          property='og:image'
          content={productData.product.images.edges[0].node.transformedSrc}
        />
        <meta property='og:image:type' content='image/png' />
        <meta property='og:image:width' content='1024' />
        <meta property='og:image:height' content='1024' />
      </Head>
      <Script
        src='tripleWhale.js'
        dangerouslySetInnerHTML={{
          __html: 'TripleHeadless = "aftershockpcsg.myshopify.com"',
        }}
        strategy='lazyOnload'
      />

      <ContainedHead
        title={
          productData?.product?.seo?.title != null
            ? productData?.product?.seo?.title
            : productData?.product?.title
        }
        description={
          productData?.product?.seo?.description ||
          productData?.product?.description
        }
        path={asPath}
      />

      {productData?.product?.productType &&
      productData?.product?.productType
        .replace(/ /g, '')
        .replace(/-/g, '')
        .toLowerCase() === 'readytoship' ? (
        <ProductRTS
          data={fixProductDataMetafields(productData)}
          metaObjects={metaObjects}
        />
      ) : productData?.product?.productType &&
        productData?.product?.productType.toLowerCase() === 'accessories' ? (
        <GiftCardContextProvider>
          <ProductAccessories
            data={fixProductDataMetafields(productData)}
            accessory_list={accessory_list}
          />
        </GiftCardContextProvider>
      ) : (
        <GiftCardContextProvider>
          <ProductAccessories
            data={fixProductDataMetafields(productData)}
            accessory_list={accessory_list}
          />
        </GiftCardContextProvider>
      )}
    </>
  );
};

P.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productData: PropTypes.object,
};

export default P;

export async function getStaticProps({ params, preview = false }) {
  const {
    props: { global },
  } = await globalProps();
  const { handle } = params;

  const productHandle = handle?.join('-');
  const { data } = await client.query({
    query: PRODUCT_BY_HANDLE,
    variables: productByHandleVars(productHandle),
  });

  let metafields = [];
  try {
    if (data.product?.metafields[0]?.value) {
      metafields = JSON.parse(data.product?.metafields[0]?.value);
      if (!Array.isArray(metafields)) {
        metafields = [];
      }
    }
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  const tempMetaObjects = await fetchMetaObjects(metafields);
  const metaObjectsWithProducts = await transformMetaObjects(tempMetaObjects);

  const preparedMetaObjects = metaObjectsWithProducts.filter(
    (item) => item.title !== 'Custom Design',
  );

  const content = await Prismic.getByUID(
    'collections_accessories',
    'cls-accessories',
  );
  const product_list = content.data.body.filter(
    (el) => el.slice_type === 'aftershock_accessories_product_list',
  );

  const disclaimer = await Prismic.getByUID(
    'aux_page',
    'aux-panel-printing-disclaimer',
  );

  return {
    props: {
      metaObjects: preparedMetaObjects || null,
      productData: data,
      global,
      accessory_list: product_list[0]?.items || null,
      disclaimer: disclaimer?.data.body || null,
    },
    revalidate: 300,
  };
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}

export const GiftCardContext = createContext();

export const GiftCardContextProvider = ({ children }) => {
  const [giftCard, setGiftCard] = useState();
  const [gcidx, setIdx] = useState();

  return (
    <GiftCardContext.Provider
      value={{
        giftCard,
        setGiftCard,
        gcidx,
        setIdx,
      }}
    >
      {children}
    </GiftCardContext.Provider>
  );
};
