/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useState, memo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles';
import ItemList from '../ItemList';
import Components from '../Components';
import { useRouter } from 'next/router';

const Secondary = ({
  items,
  kspsItems,
  quizItems,
  mainMenuHoverIndex,
  parent,
  columns,
  mainMenuCurrentIndex,
  openNav,
  onClick,
  componentItems,
  showCtaBanner,
  showQuotationBanner,
}) => {
  const parentTitle = `parent-${parent.title}`;
  const [ksps, setKsps] = useState();
  let classNames = `category_navigation_container ${slugify(
    parentTitle,
  ).toLocaleLowerCase()}`;
  const [openMegaNav, setOpenMegaNav] = useState(true);
  const isGaming = mainMenuHoverIndex === 1;
  const isWorkstations = parent.title === "Workstations & Servers";
  if (mainMenuCurrentIndex === mainMenuHoverIndex && openNav && openMegaNav) {
    classNames = `${classNames} active`;
  }
  const [activeLinkIndex, setActiveLinkIndex] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState(
    columns[0]?.mega_nav_column_handle || '',
  );
  const [menuItems, setMenuItems] = useState(items);
  const handleMoveOut = () => {
    setActiveLinkIndex(0);
    let menuItem = [];
    if (columns[0]) {
      menuItem = items.filter(
        (n) => n.primary.column_handle === columns[0]?.mega_nav_column_handle,
      );
      setMenuItems(menuItem);
    }
    setOpenMegaNav(true);
    const kspItems = kspsItems.filter(
      (n) => n.primary.column_handle === columns[0]?.mega_nav_column_handle,
    );
    setKsps(kspItems);
  };
  const handleMenuClick = (columnHandle, columnIndex) => {
    setSelectedMenu(columnHandle);
    if (selectedMenu.length > 0) {
      const selectedMenuItems = items.filter(
        (n) => n.primary.column_handle === columnHandle,
      );
      const kspItems = kspsItems.filter(
        (n) => n.primary.column_handle === columnHandle,
      );
      setKsps(kspItems);
      setMenuItems(selectedMenuItems);
    }
    setActiveLinkIndex(columnIndex);
  };
  const handleClickItem = () => {
    setOpenMegaNav(false);
    onClick();
  };

  const checkMegaMenu = (index) => {
    const child = document.getElementById(
      'megaMenuItem-' + index + '-3rd_level',
    );
    if (child?.classList?.contains('content-center')) {
      child?.parentElement?.classList?.add('justify-content-center');
    }
  };

  useEffect(() => {
    let menuItem = [];
    if (columns[0]) {
      menuItem = items.filter(
        (n) => n.primary.column_handle === columns[0]?.mega_nav_column_handle,
      );
      const kspItems = kspsItems.filter(
        (n) => n.primary.column_handle === columns[0]?.mega_nav_column_handle,
      );
      setKsps(kspItems);
      setMenuItems(menuItem);
    }
  }, []);

  const router = useRouter();

  const handleRedirectReadyToShip = () => {
    router.push('/ready-to-ship/desktops?category=same-day-shipping');
    setOpenMegaNav(false);
  };
  const handleRedirectQuotation = () => {
    router.push('/pages/corporate');
    setOpenMegaNav(false);
  };
  return (
    <>
      <div onMouseLeave={handleMoveOut} className={classNames}>
        <div className='category_navigation__secondary'>
          {columns.length > 0 && (
            <div className='category_navigation_secondary_column'>
              {columns.map((column, index) => {
                const key = slugify(
                  `${parentTitle}-${column.mega_nav_column_handle}-${index}}`,
                );

                return (
                  <div className='second-level' key={key}>
                    {column.mega_nav_column_title && (
                      <div className='menu_group'>
                        <div
                          onFocus={() => undefined}
                          aria-hidden='false'
                          type='button'
                          onClick={() =>
                            handleMenuClick(
                              column.mega_nav_column_handle,
                              index,
                            )
                          }
                          className={`second-level-title ${
                            activeLinkIndex === index
                              ? 'second-level-title-2'
                              : null
                          }`}
                        >
                          {column.mega_nav_column_title}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {/* {ksps && (
            <div className="ksps">
              {ksps[0]?.items?.map((item, index) => {
                const key = slugify(`ksp-${index}`);
                return (
                  <div key={key}>
                    {item?.ksp_icon?.url && (
                      <div className="item_group" >
                        <Image draggable={false}
                          src={item.ksp_icon.url}
                          width={22}
                          height={22}
                          alt={item?.ksp_icon?.alt || 'ksp icon'}
                        />
                        {item.ksp_title}
                      </div>
                    )}
                  </div >
                );
              })}
            </div>
          )} */}

          <div className='wrapper item-list'>
            {menuItems.map((item, index) => {
              const key = slugify(`menuItem-${index}`);
              if (item?.slice_type === '3rd_level') {
                return (
                  <div
                    id={`megaMenuItem-${index}-${
                      item?.slice_type === '3rd_level' && '3rd_level'
                    }`}
                    className={`item-list-item ${
                      item?.slice_type === '3rd_level' && 'content-center'
                    }`}
                    key={key}
                  >
                    <ItemList
                      onClick={handleClickItem}
                      index={index}
                      checkMegaMenu={checkMegaMenu}
                      item={item}
                    />
                  </div>
                );
              }
            })}

            {componentItems.length > 0 && <Components item={componentItems} />}
          </div>

          {/* {(quizItems && !(isGaming || isWorkstations)) && (
            <div className="quiz-wrapper">
              <div className="quiz">
                {quizItems?.[0]?.primary?.quiz_image?.url && (
                  <Image draggable={false}
                    src={quizItems[0].primary.quiz_image.url}
                    width={
                      quizItems?.[0]?.primary?.quiz_image?.dimensions?.width
                    }
                    height={
                      quizItems?.[0]?.primary?.quiz_image?.dimensions?.height
                    }
                    alt={
                      quizItems?.[0]?.primary?.quiz_image?.alt || 'RIG FINDER'
                    }
                  />
                )}
                {quizItems?.[0]?.primary?.title && (
                  <div className="quiz_title">{quizItems[0].primary.title}</div>
                )}

                {quizItems?.[0]?.primary?.link && (
                  <Link type="button" href={quizItems[0].primary.link || '#'} passHref>
                    <div className="quiz_button">TAKE THE QUIZ</div>
                  </Link>
                )}
              </div>
            </div>
          )} */}

          {showCtaBanner && isGaming && (
            <div className='cta-wrapper'>
              <div className='cta'>
                <div className='cta_image_container'>
                  <Image
                    className='cta_image'
                    src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/truck-white.png?v=1714057645'
                    alt='truck'
                    width={64}
                    height={40}
                    loading="lazy"
                  />
                  <div className='cta_image_title'>
                    SAME DAY
                    <br />
                    DELIVERY
                  </div>
                </div>
                <div className='cta_title'>
                  Level up your gaming experience instantly! AFTERSHOCK PC now
                  offers
                  <br />
                  Same Day Delivery for gaming rigs. Order now and dive into the
                  action today!
                </div>
                <div onClick={handleRedirectReadyToShip}>
                  <div className='cta_button'>SHOP SAME-DAY DELIVERY PCS</div>
                </div>
              </div>
            </div>
          )}

          {showQuotationBanner && isWorkstations && (
            <div className='cta-wrapper'>
              <div className='cta'>
                <div className='cta_image_container'>
                  <Image
                    className='cta_image'
                    src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/quotation.png?v=1714057676'
                    alt='qutation'
                    width={85}
                    height={78}
                    loading="lazy"
                  />
                  <div className='cta_image_title'>
                    GET A<br />
                    QUOTATION
                  </div>
                </div>
                <div className='cta_title'>
                  Ready to elevate your workstation experience? Click now to
                  request a personalized
                  <br />
                  quote for your custom workstation build!
                </div>
                <div onClick={handleRedirectQuotation}>
                  <div className='cta_button'>GET A QUOTATION</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

Secondary.propTypes = {
  items: PropTypes.array,
  mainMenuHoverIndex: PropTypes.number,
  mainMenuCurrentIndex: PropTypes.number,
  kspsItems: PropTypes.array,
  quizItems: PropTypes.array,
  openNav: PropTypes.bool,
  columns: PropTypes.any.isRequired,
  parent: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

Secondary.defaultProps = {
  items: [],
  mainMenuHoverIndex: null,
  mainMenuCurrentIndex: null,
  kspsItems: [],
  quizItems: [],
  openNav: false,
  onClick: undefined,
};

export default memo(Secondary);
