import Script from "next/script";

export default (
    <>
        <Script async src="https://www.googletagmanager.com/gtag/js?id=G-3GLNTXENHQ"></Script>
        <Script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-3GLNTXENHQ');
            `,
            }}
            strategy='lazyOnload'
        />
    </>
)