import { memo } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VideoEmbedCode = ({ block }) => {
  const { embed_code } = block.primary;

  const adjustEmbedCode = (embedCode) => {
    return embedCode
      .replace(/width="\d+"/, 'width="100%"')
      .replace(/height="\d+"/, 'height="100%"');
  };

  return (
    <>
      <div className='embed_video_slice'>
        <div
          className='embed_code'
          dangerouslySetInnerHTML={{ __html: adjustEmbedCode(embed_code) }}
        />
        <style jsx>
          {` 
            .embed_video_slice {
              display: flex;
              align-items: center;
              justify-content: center;
              padding-top: 56.25%;
              position: relative;
              margin: 7%;
            }
            .embed_code, .embed_code_mobile {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            .embed_code iframe, .embed_code_mobile iframe {
              width: 100%;
              height: 100%;
              border: 0;
            }
            @media (max-width: 767px) {
              .embed_video_slice {
                margin: 0 0 30px 0;
              }
            }
          `}
        </style>
      </div>
    </>
  );
};

export default memo(VideoEmbedCode);
