import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import breakPoints from 'helpers/Styles';
import Link from 'next/link';
import Image from 'next/image';
import AddToBag from '../AddToBag';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const EssentialProductsMobile = ({ essentialProducts }) => (
  <>
    <div className="essential_products_mobile">
      <Carousel
        showStatus={false}
        showThumbs={false}
        useKeyboardArrows
        infiniteLoop
        autoPlay
        interval={5000}
        showIndicators={false}
      >
        {essentialProducts.map((item, index) => {
          const productImage = item.product
            ? item.product.images.edges[0].node.originalSrc
            : null;
          const productTitle = item.product ? item.product.title : null;
          const productPrice = item.product
            ? item.product.variants.edges[0].node.price
            : null;
          const productVariant = item.product
            ? item.product.variants.edges[0].node
            : null;
          const productHandle = item.product ? item.product.handle : null;
          return (
            <div className="essential_product_single" key={index}>
              <Link href={`/products/${productHandle}`}>
                <a href={`/products/${productHandle}`}>
                  <div className="image_container">
                    {/* <Image draggable={false}
                        src={productImage}
                        alt="cart icon"
                        className="product_image"
                        // layout="fixed"
                        width="300px"
                        height="200px"
                      /> */}
                    <img draggable="false"  src={productImage} alt="product_image" className="product_image" />
                  </div>
                </a>
              </Link>

              <h2 className="product_title">{productTitle}</h2>
              <h1 className="product_price">${productPrice}</h1>
              <div>
                <AddToBag
                  selectedVariant={productVariant}
                  customizedClass="essential_products_cart"
                />
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>

    <style jsx>
      {`
        .essential_products_mobile {
          width: 100%;
          overflow: hidden;
          display: none;
        }
        .essential_product_single {
          width: 90%;
          margin: 0 auto;
        }
        .image_container {
          background: #141414;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .product_image {
          max-height: 300px;
          width: 90%;
        }
        .product_title {
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          max-height: 60px;
          margin: 0;
          margin-top: 20px;
        }
        .product_price {
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
          margin: 0;
          margin-bottom: 20px;
        }
        @media (max-width: ${breakPoints.medium}) {
          .essential_products_mobile {
            display: block;
          }
          .image_container {
            height: 400px !important;
          }
          .product_image {
            width: 60%;
            max-height: 400px;
          }
        }
        @media (max-width: ${breakPoints.small}) {
          .image_container {
            height: 300px !important;
          }
          .product_image {
            width: 80% !important;
          }
        }
      `}
    </style>
  </>
);

export default EssentialProductsMobile;
