import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';

const BarCounter = ({ id, value, percentage, game }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 500);
  }, [game]);

  const getTextValue = (value) => {
    if (value >= 100) return 'Ultra';
    if (value >= 90 && value < 100) return 'High-Ultra';
    if (value >= 80 && value < 90) return 'High';
    if (value >= 75 && value < 80) return 'Medium-high';
    if (value >= 70 && value < 75) return 'Medium';
    if (value >= 50 && value < 70) return 'Low-Medium';
    if (value < 50) return 'Low';
    return value;
  };

  const getBarCounter = () => {
    const textValue = getTextValue(percentage);
    if (id === '1080P') {
      return (
        <div
          id={id}
          className={`bar-counter-progress-1080p ${
            isAnimating ? 'animate-width' : ''
          }`}
          style={{
            width: `${percentage >= 100 ? '100' : percentage}%`,
          }}
        >
          <p className='bar-counter-value'>{textValue}</p>
          <style jsx>
            {`
              .bar-counter-progress-1080p {
                background-image: linear-gradient(to right, #243e3a, #4eb02c);
                height: 40px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
              }
              .bar-counter-value {
                margin: 0;
                font-family: 'big_noodle_titling';
                font-size: 21px;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translate(0, -50%);
                width: ${percentage >= 100 ? '100' : percentage}%;
                text-align: center;
              }

              @-webkit-keyframes show-bar-one-vertical-1080p {
                0% {
                  width: 1%;
                }
                100% {
                  width: ${percentage >= 100 ? '100' : percentage}%;
                }
              }
              .animate-width {
                animation: show-bar-one-vertical-1080p 0.5s ease;
              }
              @media (max-width: ${breakPoints.small}) {
                .bar-counter-value {
                  font-size: 12px;
                }
                .bar-counter-container,
                .bar-counter-progress-1080p {
                  height: 25px;
                }
              }
            `}
          </style>
        </div>
      );
    } else if (id === '1440P') {
      return (
        <div
          id={id}
          className={`bar-counter-progress-1440p ${
            isAnimating ? 'animate-width' : ''
          }`}
          style={{
            width: `${percentage >= 100 ? '100' : percentage}%`,
          }}
        >
          <p className='bar-counter-value'>{textValue}</p>

          <style jsx>
            {`
              .bar-counter-progress-1440p {
                background-image: linear-gradient(to right, #402d49, #b334ef);
                height: 40px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
              }
              .bar-counter-value {
                margin: 0;
                font-family: 'big_noodle_titling';
                font-size: 21px;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translate(0, -50%);
                width: ${percentage >= 100 ? '100' : percentage}%;
                text-align: center;
              }

              @-webkit-keyframes show-bar-one-vertical-1440p {
                0% {
                  width: 1%;
                }
                100% {
                  width: ${percentage >= 100 ? '100' : percentage}%;
                }
              }
              .animate-width {
                animation: show-bar-one-vertical-1440p 0.5s ease;
              }
              @media (max-width: ${breakPoints.small}) {
                .bar-counter-value {
                  font-size: 12px;
                }
                .bar-counter-progress-1440p {
                  height: 25px;
                }
              }
            `}
          </style>
        </div>
      );
    } else if (id === '4K') {
      return (
        <div
          id={id}
          className={`bar-counter-progress-4k ${
            isAnimating ? 'animate-width' : ''
          }`}
          style={{
            width: `${percentage >= 100 ? '100' : percentage}%`,
          }}
        >
          <p className='bar-counter-value'>{textValue}</p>

          <style jsx>
            {`
              .bar-counter-progress-4k {
                background-image: linear-gradient(to right, #47311e, #f57500);
                height: 40px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
              }
              .bar-counter-value {
                margin: 0;
                font-family: 'big_noodle_titling';
                font-size: 21px;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translate(0, -50%);
                width: ${percentage >= 100 ? '100' : percentage}%;
                text-align: center;
              }

              @-webkit-keyframes show-bar-one-vertical-4k {
                0% {
                  width: 1%;
                }
                100% {
                  width: ${percentage >= 100 ? '100' : percentage}%;
                }
              }
              .animate-width {
                animation: show-bar-one-vertical-4k 0.5s ease;
              }

              @media (max-width: ${breakPoints.small}) {
                .bar-counter-value {
                  font-size: 12px;
                }
                .bar-counter-progress-4k {
                  height: 25px;
                }
              }
            `}
          </style>
        </div>
      );
    }
  };

  return (
    <>
      <div className='bar-counter-container'>{getBarCounter()}</div>

      <style jsx>
        {`
          .bar-counter-container {
            width: 100%;
            height: 40px;
            position: relative;
          }
          @media (max-width: ${breakPoints.small}) {
            .bar-counter-container {
              height: 25px;
            }
          }
        `}
      </style>
    </>
  );
};

BarCounter.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  game: PropTypes.string.isRequired,
};

export default BarCounter;
