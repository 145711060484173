import Prismic from '../helpers/Prismic';

let propsRequest = [];

if (Prismic && Prismic !== undefined) {
  propsRequest = [
    Prismic.getByUID(
      'main_navigation_new_version2',
      'main_navigation_new_version2'
    )
      .then((res) => ['header', res])
      .catch(() => null),
    Prismic.getByUID('footer', 'footer')
      .then((res) => ['footer', res])
      .catch(() => null),
    Prismic.getByUID('ready_to_ship_page', 'rts-desktops')
    .then((res) => ['rtsProducts', res])
    .catch(() => null),
    Prismic.getByUID('gaming_landing_page', 'glp-desktops')
    .then((res) => ['customProducts', res])
    .catch(() => null),
    Prismic.getByUID('workstations_pag', 'ws-workstations')
    .then((res) => ['workstationProducts', res])
    .catch(() => null),
    Prismic.getByUID('limited_edition_page', 'limited-edition')
    .then((res) => ['limitedEditionProducts', res])
    .catch(() => null),
    Prismic.getByUID('gaming_landing_page', 'glp-laptops')
    .then((res) => ['customLaptops', res])
    .catch(() => null),
  ];
}

export const globalPropsRequests = propsRequest;

const globalProps = async () => {
  const global = {};

  await Promise.all(globalPropsRequests)
    .then((responses) => {
      responses.forEach((res) => {
        if (res) {
          const [id, body] = res;
          global[id] = body.data;
        }
      });
    })
    .catch(() => {});

  return {
    props: {
      global,
    },
  };
};

export default globalProps;
