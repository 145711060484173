import React, { useState, useEffect } from "react";
import Image from "next/image";
import PropTypes from "prop-types";
import Video from "components/video/index-rts";
import { ConsoleView } from "react-device-detect";
import PerformanceChart from "../PerformanceChart";

const ImageGallery = ({
  productImage,
  imagesGallery,
  productTitle,
  handleModalOpen,
  handleImageIndex,
  videoPreviewLink,
  productAltText,
  handleVideoPreview,
  videoUrl,
  rankingMetaField
}) => {
  const [imageViewer, setImageViewer] = useState(null);
  const [imageActive, setImageActive] = useState(0);
  const [altTextValue, setAltTextValue] = useState(null);
  const lastImageIndex = imagesGallery.length - 1;
  const imageHandleChange = (id) => {
    const imageList = imagesGallery;
    imageList.map((imageNode) => {
      if (imageList.indexOf(imageNode) === id) {
        setImageViewer(imageNode.node.originalSrc);
        setAltTextValue(imageNode.node.altText);
      }
    });
  };

  const handleArrowClick = (clickType) => {
    const correctId =
      clickType === "prev"
        ? imageActive !== 0
          ? imageActive - 1
          : 0
        : imageActive < lastImageIndex
        ? imageActive + 1
        : lastImageIndex;

    imageHandleChange(correctId);
    setImageActive(correctId);
    if (imagesGallery[correctId].node.altText === "video") {
      handleVideoPreview(videoUrl);
    } else {
      handleVideoPreview(null);
      handleImageIndex(correctId);
    }
  };

  const handleModal = () => {
    handleModalOpen(true);
  };
  useEffect(() => {
    setImageViewer(productImage || "https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274");
    setAltTextValue(productAltText || null);
  }, [productImage, productAltText]);
  return (
    <>
      <div className="product_image_container">
        {/* onClick={handleModal} */}
        <div className="image_view">
          {imageActive > 0 && (
            <img draggable="false" 
              onClick={() => handleArrowClick("prev")}
              className="arrow_icon arrow_icon__left"
              src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/arrow-left-prev.png?v=1714031752"
              alt="arrow-left"
            />
          )}
          {imageActive < lastImageIndex && (
            <img draggable="false" 
              onClick={() => handleArrowClick("next")}
              className="arrow_icon arrow_icon__right"
              src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/arrow-right-next.png?v=1714031836"
              alt="arrow-right"
            />
          )}
          <div className="product_image">
            {videoPreviewLink == null ? (
              imageViewer && (
                <Image draggable={false}
                  onClick={handleModal}
                  src={imageViewer}
                  alt={productTitle}
                  width={500}
                  height={500}
                />
              )
            ) : (
              <Video
                videoUrl={videoPreviewLink}
                videoHeight="500px"
                videoSource={videoPreviewLink}
                videoTitle={null}
                videoDescription={null}
              />
            )}
            
            {rankingMetaField[0] != undefined && 
              <PerformanceChart rankingMetaField={rankingMetaField} />
            }
          </div>

          <div className="image_zoom_in">
            <Image draggable={false}
              src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-magnifyingglass-light_1.png?v=1714048366"
              alt="product_features"
              width={25}
              height={25}
              onClick={handleModal}
            />
          </div>
        </div>
        <div className="image_gallery">
          {imagesGallery.map((item, id) => {
            const handleClick = (url) => {
              imageHandleChange(id);
              handleImageIndex(id);
              setImageActive(id);
              if (url && typeof url !== "undefined" && url !== undefined)
                handleVideoPreview(url);
              else handleVideoPreview(null);
            };
            if (item.node.altText != 'rts_variant') {
              return (
                <div
                  className={
                    id == imageActive ? "active_slide image_tab" : "image_tab"
                  }
                  key={id}
                >
                  {item.node.altText && videoUrl ? (
                    <>
                      <div className="svg-icon">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25px"
                          height="25px"
                          viewBox="0 0 512.000000 512.000000"
                          preserveAspectRatio="xMidYMid meet"
                          onClick={() => handleClick(videoUrl)}
                        >
                          <g
                            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#ffffff"
                            stroke="none"
                          >
                            <path d="M2321 5110 c-497 -48 -990 -251 -1376 -565 -114 -92 -294 -274 -384 -387 -229 -287 -417 -675 -495 -1023 -49 -218 -60 -325 -60 -575 0 -250 11 -357 60 -575 79 -355 272 -749 509 -1040 92 -114 274 -294 387 -384 287 -229 675 -417 1023 -495 218 -49 325 -60 575 -60 250 0 357 11 575 60 261 58 603 204 828 353 389 259 688 599 893 1016 125 255 196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283 480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19 -468 27 -599 15z m507 -1995 c524 -302 952 -552 952 -555 0 -4 -1887 -1098 -1912 -1108 -4 -2 -8 497 -8 1108 0 611 4 1110 8 1108 5 -1 437 -250 960 -553z" />
                          </g>
                        </svg>
                      </div>
                      <Image draggable={false}
                        src={item.node.originalSrc}
                        alt="product_view_image"
                        width={200}
                        height={200}
                        onClick={() => handleClick(videoUrl)}
                      />
                    </>
                  ) : (
                    <Image draggable={false}
                      src={item.node.originalSrc}
                      alt="product_view_image"
                      width={200}
                      height={200}
                      onClick={() => handleClick()}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>

      <style>
        {`
          
          .svg-icon{
            position: absolute;
            z-index: 9;
          }
            .product_image_container {
              display: flex;
              justify-content: space-between;
              align-items: start;
            }
            body .image_gallery {
                width: 13%;
                margin-top: 0px;
                height: auto;
            }
           .image_tab {
              width: 100% !important;
              margin-bottom: 20px !important;
            }
            .product_image > div {
                position: inherit !important;
            }
            .image_tab.active_slide {
                border: 1px solid red;
            }




          .image_tab img {
              width:100% !important;
            }
          .image_view {
            order: 2;
            width: 696px !important;
            display: flex;
            justify-content: center;
            align-items: start;
            cursor: pointer;
            position: relative;
            // height: 696px;
            margin-left: 24px;
          }

          .arrow_icon {
            position: absolute;
            top: 50%;
            z-index: 20;
          }
          .arrow_icon__left {
            left: 16px;
          }
          .arrow_icon__right {
            right: 16px;
          }

          .image_zoom_in {
            position: absolute;
            display: none;
            right: 10px;
            bottom: 10px;
          }

          .image_gallery {
            height: 200px;
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 5%;
          }
          .image_tab {
            width: 30%;
            background: #141414;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: 30px;
          }
          .image_tab img {
            width: 70%;
          }
                  
          @media (max-width: 1440px) {
            .image_view {
              // height: 640px;
            }
          }
        `}
      </style>
    </>
  );
};

ImageGallery.propTypes = {
  productImage: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  imagesGallery: PropTypes.array.isRequired,
  productTitle: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleImageIndex: PropTypes.func.isRequired,
};

export default ImageGallery;
