import { GiftCardContext } from 'pages/products/[...handle]';
import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';


const VarianceGift = ({ title, data, productImage }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [imageViewer, setImageViewer] = useState(null);

  const { setGiftCard, setIdx } = useContext(GiftCardContext);
  const cleanedPrices = data.map(price => {
    return parseFloat(price.replace(/[^\d.]/g, ''));
  });

  const handleModal = () => {
    handleModalOpen(true);
  };
  useEffect(() => {
    setImageViewer(productImage || 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274');
  }, [productImage]);

  const handleClick = (index, item) => {
    setActiveTab(index);
    setGiftCard(cleanedPrices[index])
    setIdx(index);
    localStorage.setItem("giftcard", index);
  };

  return (
    <div className="container">
      <h3 className='title'>{title}</h3>
      <div className="price-gifts">
        {cleanedPrices.map((item, index) => (
          <a
            className={
              activeTab === index ? 'active' : index === 0 ? 'default' : ''
            }
            key={index}
            onClick={() => handleClick(index, item)}
          >
            {`$ ${item}`}
          </a>
        ))}
      </div>

      <style jsx>
        {`
          .container {
            margin: 0 auto;
            width: 70%
          }
          .title {
            margin-top: 40px;
          }
          .price-gifts {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            padding: 10px 0px;
            border: 1px solid;
            border-radius: 50px;
            gap: 20px;
            width: 100%;
          }
          .price-gifts a {
            display: flex;
            padding: 15px 10px;
            border-radius: 70px;
            cursor: pointer;
            font-size: 18px;
          }
          .active {
            background: #950810 !important;
          }
          .image_view {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 600px;
            cursor: pointer;
            position: relative;
          }

					@media (max-width: 768px) {
					 .container {
						 width: 100%;
					 }
					 .price-gifts a {
						font-size: 1rem;
					 }
					}
        `}
      </style>
    </div>
  );
};

VarianceGift.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
};

VarianceGift.defaultProps = {
  title: 'Select amount',
};

export default VarianceGift;

