import React, { useEffect, useState } from 'react';
import breakPoints from 'helpers/Styles';
import { RichText } from 'prismic-reactjs';

const LocationBanner = ({ block }) => {
  if (!block) return '';
  
  const isAutoscroll = block.primary.autoscroll;
  const locations = block.items;
  const title = block.primary.title1;
  const subtitle = block.primary.subtitle;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const [adress, setAdress] = useState(locations[0].adress);
  const [activeLocation, setActiveLocation] = useState(locations[0].location);
  const [description, setDescription] = useState(locations[0].description);
  const [locationImage, setLocationImage] = useState(
    locations[0].location_image.url,
  );
  const [locationIcon, setLocationIcon] = useState(
    locations[0].location_icon.url,
  );

  const hendleLocationClick = (item) => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    setAdress(item.adress);
    setActiveLocation(item.location);
    setDescription(item.description);
    setIsFading(true);
    setTimeout(() => {
      setLocationImage(item.location_image.url);
      setIsFading(false);
    }, 300);
    setLocationIcon(item.location_icon.url);
  };

  useEffect(() => {
    const id = setInterval(() => {
      hendleLocationClick(locations[currentIndex]);

      setCurrentIndex((prevIndex) =>
        prevIndex === locations.length - 1 ? 0 : prevIndex + 1,
      );
    }, 3000);

    setIntervalId(id);

    if (!isAutoscroll) {
      clearInterval(id);
    }

    return () => clearInterval(id);
  }, [currentIndex, locations]);

  return (
    <>
      <div className='location_wrapper'>
        <div className='location_banner'>
          <img
            src={locationImage}
            alt='Location'
            className={`location_image ${isFading ? 'fade-out' : ''}`}
          />
          <div className='location_content'>
            <div className='main_title'>{title}</div>
            <div className='main_subtitle'>{subtitle}</div>
            <div className='lication_with_icon'>
              <img className='location_icon' src={locationIcon} />
              <div className='location'>{activeLocation}</div>
            </div>
            <div className='location_description'>{description}</div>
            <div className='location_adress'>{RichText.render(adress)}</div>
            <div className='location_picker'>
              {locations.map((location, index) => {
                return (
                  <button
                    key={index}
                    className={`location_button ${
                      location.location === activeLocation ? 'active' : ''
                    }`}
                    onClick={() => hendleLocationClick(location)}
                  >
                    {location.location}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className='location_banner_mobile'>
          <div className='main_title'>{title}</div>
          <div className='main_subtitle'>{subtitle}</div>
          <div className='location_picker'>
            {locations.map((location, index) => {
              return (
                <button
                  key={index}
                  className={`location_button ${
                    location.location === activeLocation ? 'active' : ''
                  }`}
                  onClick={() => hendleLocationClick(location)}
                >
                  {location.location}
                </button>
              );
            })}
          </div>
          <img
            src={locationImage}
            alt='Location'
            className={`location_image ${isFading ? 'fade-out' : ''}`}
          />
          <div className='lication_with_icon'>
            <img className='location_icon' src={locationIcon} />
            <div className='location'>{activeLocation}</div>
          </div>
          <div className='location_description'>{description}</div>
          <div className='location_adress'>{RichText.render(adress)}</div>
        </div>
      </div>
      <style jsx>
        {`
          .location_wrapper {
            background-color: #111111;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .location_banner {
            max-width: 1440px;
            height: 560px;
            width: 100%;
            background-color: #171717;
            display: flex;
            gap: 45px;
            margin: 100px 0;
          }
          .location_image {
            width: 50%;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
          }
          .location_image.fade-out {
            opacity: 0;
          }
          .main_title {
            font-family: 'Eurostile-Bold';
            font-size: 32px;
            line-height: 38px;
            color: #d41616;
            margin-top: 37px;
          }
          .main_subtitle {
            font-family: 'Eurostile-Bold';
            font-size: 24px;
            line-height: 29px;
          }
          .lication_with_icon {
            display: flex;
            align-items: center;
            margin-top: 40px;
          }
          .location_icon {
            width: 28px;
            height: 28px;
          }
          .location {
            font-family: 'Roboto-Medium';
            font-size: 24px;
            line-height: 28px;
            margin-left: 15px;
          }
          .location_description {
            font-family: 'Roboto-Normal';
            font-size: 15px;
            line-height: 25px;
            max-width: 500px;
            margin-top: 20px;
          }
          .location_adress {
            max-width: 500px;
          }
          .location_picker {
            display: flex;
            align-items: center;
            gap: 16px;
          }
          .location_button {
            font-family: 'Roboto-Medium';
            font-size: 13px;
            line-height: 13px;
            color: #ffffff;
            margin: 40px 0;
            padding: 12px 15px;
            border-radius: 5px;
            background-color: #171717;
            border: solid 1px #ffffff;
            transition:
              border-color 0.5s ease,
              background-color 0.5s ease;
          }
          .location_button:hover {
            border-color: #d41616;
            background-color: #d41616;
          }
          .location_button.active {
            border-color: #d41616;
            background-color: #d41616;
          }
          .location_banner_mobile {
            display: none;
          }
          @media (max-width: 1300px) {
            .location_image {
              width: 40%;
            }
          }
          @media (max-width: ${breakPoints.medium}) {
            .location_banner {
              display: none;
            }
            .location_banner_mobile {
              display: flex;
              flex-direction: column;
              width: 100%;
              margin: 70px 20px 50px;
            }
            .main_title {
              margin-top: 0;
              text-align: center;
            }
            .main_subtitle {
              margin-top: 20px;
              text-align: center;
            }
            .location_picker {
              justify-content: center;
              margin: 20px 0 50px;
              gap: 10px;
            }
            .location_button {
              margin: 0;
              width: 100%;
            }
            .location_image {
              width: 100%;
              height: 254px;
              object-fit: cover;
              opacity: 1;
              transition: opacity 0.3s ease-in-out;
            }
            .lication_with_icon {
              margin-top: 30px;
            }
            .location_description {
              max-width: 100%;
            }
          }
          @media (max-width: ${breakPoints.small}) {
          }
        `}
      </style>
    </>
  );
};

export default LocationBanner;
