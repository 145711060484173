import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './styles';

const FPSCounter = ({ id, view, title, progress }) => {
  useEffect(() => {
    countFPS(progress);
  });

  const countFPS = (fps) => {
    let elt = document.getElementById(id);
    FPSCounter(0, fps, elt);
  };

  const FPSCounter = (i, endNbr, elt) => {
    if (i <= endNbr) {
      if (i > 400) {
        return i;
      }
      elt.innerHTML = i;
      setTimeout(function () {
        FPSCounter(i + 5, endNbr, elt);
      }, 10);
    }
  };

  return (
    <>
      <div className="circle">
        <div className="indicator-top">
          <div>{title}</div>
        </div>
        <div className="indicator-center">
          <p id={id}>{progress}</p>
          {progress >= 400 &&
            <span className='plus-icon'>+</span>
          }
        </div>
        <svg style={{ height: 0, display: 'block' }}>
          <defs>
            {view == 'mobile' ? (
              <linearGradient
                id="gradientMobile"
                gradientTransform="rotate(90)"
              >
                <stop offset="5%" stopColor="#FE1416" />
                <stop offset="70%" stopColor="#8666FF" />
              </linearGradient>
            ) : (
              <linearGradient
                id="gradientDesktop"
                gradientTransform="rotate(90)"
              >
                <stop offset="5%" stopColor="#FE1416" />
                <stop offset="70%" stopColor="#8666FF" />
              </linearGradient>
            )}
          </defs>
        </svg>
        <CircularProgressbar
          strokeWidth={3}
          value={progress}
          text={''}
          maxValue={240}
          styles={{
            path: {
              stroke:
                view == 'mobile'
                  ? `url(#${'gradientMobile'})`
                  : `url(#${'gradientDesktop'})`,
              height: '100%',
              strokeLinecap: 'butt',
            },
            trail: {
              stroke: '#0B0302',
            },
          }}
        />
        <div className="indicator-bottom">
          <div>FPS</div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

FPSCounter.propTypes = {
  id: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  progress1440P: PropTypes.string.isRequired,
};

export default FPSCounter;