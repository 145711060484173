import React, { useState, useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { getCheckoutId } from 'helpers/cookies';
import { StorefrontContext } from 'providers/storefront';
import Image from 'next/image';
import CountrySelector from 'components/CountrySelector';
import Logo from 'components/Logo';
import styles from './styles';
import CartCounter from './CartCounter';
import Primary from './Primary';
import Countdown from 'react-countdown';
import RegionSwitchButton from 'components/NavigationButtonIcons/RegionSwitchButton';
import ProfileButton from 'components/NavigationButtonIcons/ProfileButton';
import { useRouter } from 'next/router';
import AccountPrimary from './AccountPrimary';
import BannerCountdownTimer from '../BannerCountdownTimer';
import Prismic from '../../../../helpers/Prismic';
import { ThreeDots } from 'react-loader-spinner'

const Navigation = ({ header, opacityRef, rtsProducts, customProducts, workstationProducts, limitedEditionProducts, customLaptops }) => {
  const router = useRouter();
  const [kspItems, setKspsItems] = useState([]);
  const [quizItems, setQuizItems] = useState();
  const [checkoutId, setCheckoutId] = useState(getCheckoutId());
  const { setIsCartOpen, checkout, isLoggedIn, setIsLoggedIn } =
    useContext(StorefrontContext);
  const { setIsAnnouncementBarOpen, isAnnouncementBarOpen } =
    useContext(StorefrontContext);
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    if (checkout?.id) setCheckoutId(checkout.id);
  }, [checkout]);

  const [search, setSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const { setAllPcRtsProducts } = useContext(StorefrontContext)

  // CTA Banner showing state
  const [showCtaBanner, setShowCtaBanner] = useState(false);

  // Quotation Banner showing state
  const [showQuotationBanner, setShowQuotationBanner] = useState(false);

  // CTA and Quotation Banner showing state change effect handler
  useEffect(() => {
    if (header?.show_desktop_cta_banner !== undefined && header?.show_quotation_banner !== undefined) {
      setShowCtaBanner(header?.show_desktop_cta_banner)      
      setShowQuotationBanner(header?.show_quotation_banner)      
    }
  }, [header?.show_desktop_cta_banner, header?.show_quotation_banner]);

  const getMegaMenuColumns = (megaMenuItems) => {
    const columns = [];
    let currentSecondLevelIndex = 0;
    megaMenuItems?.forEach((childMenuItem, index) => {
      const sliceType = childMenuItem.slice_type;
      // eslint-disable-next-line no-plusplus
      if (sliceType === '1st_level' && index !== 0) currentSecondLevelIndex++;
      if (!columns[currentSecondLevelIndex]) {
        columns[currentSecondLevelIndex] = [];
      }

      columns[currentSecondLevelIndex].push(childMenuItem);
    });

    return columns;
  };
  useEffect(() => {
    if (header && header.body) {
      // console.log({ headerBody: header.body });
      setMenuItems(getMegaMenuColumns(header.body));
    }
  }, [header]);
  useEffect(() => {
    if (header && header.body) {
      const ksps = header.body.filter((n) => n.slice_type === 'ksps');
      const quiz = header.body.filter((n) => n.slice_type === 'quiz');
      const items = ksps;
      setKspsItems(items);
      setQuizItems(quiz);
    }
  }, [header]);
  const handleChatToggle = () => {
    window.FrontChat('show')
  }

  const handleCartOpen = () => {
    setIsCartOpen(true);
  };

  const handleCloseBanner = () => {
    setIsAnnouncementBarOpen(false);
  };
  const topBanner =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');

  const topBannerTimer =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');

  const topBannerContents = topBanner && topBanner[0]?.primary?.banner_contents;

  const hideBannerTimerRunsOut = topBanner && topBanner[0]?.primary?.hide_when_timer_runs_out;

  const topBannerTimerContents =
    topBannerTimer && topBannerTimer[0]?.primary?.countdown_end;

  const renderer = ({
    days = null,
    hours = null,
    minutes = null,
    seconds = null,
    completed = null,
  }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <span>
          {' '}
          | Ends in {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  async function getPcModelsByNames(names) {
		try {
			const response = await fetch(`/api/search/getAllPcModelsByNames?names=${names.join(',')}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
	
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
	
			const results = await response.json();
			return results;
		} catch (error) {
			console.error('Error fetching data:', error);
			return null;
		}
	}

  const arraysOfObjectsAreEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }


  function getAllElementsInSearch () {
    const sixHoursInMillis = 6 * 60 * 60 * 1000;
		const lastUpdated = localStorage.getItem('lastUpdated');
		const allProductsStore = localStorage.getItem('allProductsStore');

    // GET ALL CUSTOM PRODUCTS
    if (lastUpdated && allProductsStore) {
			const currentTime = new Date().getTime();
        	const timeDiff = currentTime - parseInt(lastUpdated);
			if (timeDiff < sixHoursInMillis) {
				setAllProducts(JSON.parse(localStorage.getItem('allProductsStore')));
				setAllPcRtsProducts(JSON.parse(localStorage.getItem('allProductsStore')));
				setLoadingSearch(false);
				return;
			}
    }

    let finalCustomProducts = [];
    let slpPcModels = [];

    customProducts?.body?.map((data, i) => {
      data.items.map((item, i) => {
        finalCustomProducts = [...finalCustomProducts, { product: item }]
      })
    })

    customLaptops?.body?.map((data, i) => {
      data.items.map((item, i) => {
        item.tags = 'laptops'
        finalCustomProducts = [...finalCustomProducts, { product: item }]
      })
    })

    if (limitedEditionProducts?.body !== undefined) {
      finalCustomProducts = [...finalCustomProducts, ...limitedEditionProducts?.body];
    }

    finalCustomProducts?.map((data, i) => {
      let slp = '';
      if (data.product != undefined) {
        slp = data.product.link.split('/');
        slpPcModels = [...slpPcModels, slp[2]];
      } else if (data.primary != undefined) {
        slp = data.primary.redirect_link.split('/');
        slpPcModels = [...slpPcModels, slp[2]];
      }
    });

    const newSlpModels = slpPcModels.map((model) => {
			if (model.includes('-')) {
				return model.split('-')[0];
			}
			return model;
		});

    function filterDuplicates(arr) {
			return arr.filter((item, index) => arr.indexOf(item) === index);
		}

		const updatedSlpModels = filterDuplicates(newSlpModels);
    updatedSlpModels.pop();

    const handleFlatArray = (arr) => {
      let filteredItems = arr.map((bodyItem) => {
        return bodyItem.items;
      });
      let filtered = filteredItems.map((el) => {
        return el.filter(
          (itm) => itm.product !== null && typeof itm.product !== "undefined"
        );
      });

      const flatProducts = filtered.flat();
      flatProducts.filter((el) => el.product !== null);

      return flatProducts;
    };

    const promises = [];
		// Calculate the number of chunks needed
		const numChunks = Math.ceil(updatedSlpModels.length / 4);

    		// Loop to create and push promises for each chunk
		for (let i = 0; i < numChunks; i++) {
			const start = i * 4;
			const end = (i + 1) * 4;
			if (start == 0) {
				const chunk = updatedSlpModels.slice(start, end);
				promises.push(getPcModelsByNames(chunk));
			} else {
				const chunk = updatedSlpModels.slice(start + 1, end);
        if (chunk.length > 0) {
          console.log('chunk', chunk);
          promises.push(getPcModelsByNames(chunk));
        }
			}
		}

    Promise.all(promises)
      .then((responses) => {
        const allPcModels = responses.flat();
				const filteredAllPcModels = allPcModels.filter(pcModel =>  pcModel.price < 90000);

        let filteredPcModels = [];

        filteredAllPcModels.map((option) => {
          const graphicsCardOptions = option?.modelOptionGroup?.find(
            (options) => options?.optionGroup.option_group_title === "Graphics Card"
          );

          const chassisOptions = option?.modelOptionGroup?.find(
            (options) =>
              options?.optionGroup.option_group_title === 'Chassis',
          );

          const cpuOptions = option?.modelOptionGroup?.find(
						(options) =>
							options?.optionGroup.option_group_title === 'CPU',
					);

          filteredPcModels = [...filteredPcModels, {
            slug: option.slug,
            model_title: option.model_title,
            seo_description: option.seo_description,
            seo_title: option.seo_title,
            thumb_image: option.thumb_image,
            modelOptionGroup: [graphicsCardOptions],
            chassisGroup: [chassisOptions],
            cpuGroup: [cpuOptions],
            buildType: option.buildType.build_type_name,
          }]
        }
        )

        // GET ALL RTS PRODUCTS
        let filteredItems = rtsProducts?.body?.map((bodyItem) => {
          return bodyItem.items;
        });
        let filtered = filteredItems?.map((el) => {
          return el.filter((itm) => itm.product !== null);
        });
        const flatProducts = filtered?.flat();
        const finalProducts = flatProducts?.filter(
          (el) => el.product !== undefined
        );

        const pageSearch = [
          {
            title1: 'FLAGSHIP SHOWROOM',
            subtitle: 'Suntec City Mall',
            seo_description: `AFTERSHOCK's first-ever retail storefront and Flagship Showroom in Suntec City condenses and replaces AFTERSHOCK PC's former experiential space with a transformative focus on embodying a clearer expression of the company's aim to present the enthusiast PC experience as a cohesive whole.`,
            thumb_image: 'https://images.prismic.io/aftershock-singapore/65b5dea6-2b61-4119-916e-e6345edce066_SHOWROOM.PNG?auto=compress,format',
            link: '/pages/showroom'
          },
          {
            title1: 'SERVICE CENTRE',
            subtitle: '10min from Boon Keng MRT',
            seo_description: `Visit Singapore's largest Custom PC service team at the AFTERSHOCK PC Service Centre, open six days a week! Our engineers stand ready to get your PC up and running at record speed. Enjoy peak performance and absolute peace of mind with industry-leading customer support.`,
            thumb_image: 'https://images.prismic.io/aftershock-singapore/509203bb-fcd9-4ed1-abdd-da8d9c228c9d_SERVICE+CENTRE.PNG?auto=compress,format',
            link: '/pages/service-centre'
          },
          {
            title1: 'Support',
            subtitle: 'Contact us',
            seo_description: `While we've made a name for ourselves in the gaming world, our expertise extends far beyond. Our professional solutions are deployed across diverse industries, from content creation to scientific computing and engineering.`,
            thumb_image: 'https://images.prismic.io/aftershock-singapore/784afab6-2e05-4d8b-9a7b-f89fd05a4f75_SUPPORT.PNG?auto=compress,format',
            link: '/pages/support'
          },
          {
            title1: 'Corporate',
            subtitle: 'Get a quote quotation',
            seo_description: `We're here to assist you in any way we can. Whether you have a question about our products, need technical support, or want to explore partnership opportunities, our dedicated team is ready to help. Reach out to us using the contact information provided below, and we'll get back to you as soon as possible.`,
            thumb_image: 'https://images.prismic.io/aftershock-singapore/d9cf1173-0614-4708-b162-40adbea45979_Service_Shot.png?auto=compress,format',
            link: '/pages/corporate'
          },
          {
            title1: 'Gaming PC',
            subtitle: '',
            seo_description: `Build your setup with Singapore's #1 Custom PC Brand. Pairing incredible craftsmanship with a comprehensive curation of premium components, our team of artisans perfect every detail to your exact needs! Enjoy our industry-leading 2-year warranty and lifetime customer support today.`,
            thumb_image: 'https://images.prismic.io/aftershock-singapore/80be4873-9b16-41ee-aaf2-8641d3c5da42_GAMING+CUSTOM.png?auto=compress,format',
            link: '/gaming/desktops'
          },
          {
            title1: 'Techyard',
            subtitle: 'One Stop PC Components & Gear Superstore',
            seo_description: `Looking for graphics cards, processors, storage drives, computer parts, or just looking for the best deals? Check out the brand new TECHYARD Superstore!`,
            thumb_image: 'https://images.prismic.io/aftershock-singapore/c6356031-a378-4fb5-8bc4-c0bca5a00cdc_TECHYARD_2.PNG?auto=compress,format',
            link: 'https://thetechyard.com/'
          }
        ]

        Prismic.getByUID('collections_accessories', `cls-accessories`)
          .then((res) => {
            const allWorkstationProducts = workstationProducts?.body?.flatMap(itemGroup => itemGroup.items);
            const allAccessoriesProducts = handleFlatArray(res?.data?.body);
            const allProducts = [...finalProducts, ...filteredPcModels];
            allProducts = [...allProducts, ...allWorkstationProducts];
            allProducts = [...allProducts, ...allAccessoriesProducts];
            allProducts = [...allProducts, ...pageSearch];
            const arraysAreEqual = arraysOfObjectsAreEqual(JSON.parse(localStorage.getItem('allProductsStore')), allProducts);

            if (!arraysAreEqual) {
              setAllProducts(allProducts);
              localStorage.setItem('allProductsStore', JSON.stringify(allProducts));
              localStorage.setItem('lastUpdated', new Date().getTime().toString());
              setAllPcRtsProducts(allProducts);
              setLoadingSearch(false);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const checkGraphicsCard = (data, search) => {
    let check = false;
    data.filter((item) => {
      const graphicsCardOptions = item?.modelOptionGroup?.find(
        (options) => options?.optionGroup.option_group_title === "Graphics Card"
      );

      if (graphicsCardOptions) {
        graphicsCardOptions.options.find((option) => {
          option.component.component_title.toLowerCase().includes(search.toLowerCase())
          if (check = option.component.component_title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        });
      }
    })
    return check;
  }

  const checkCPU = (data, search) => {
    let check = false;
    data.filter((item) => {
      const cpuOptions = item?.modelOptionGroup?.find(
        (options) => options?.optionGroup.option_group_title === "CPU"
      );

      if (cpuOptions) {
        cpuOptions.options.find((option) => {
          option.component.component_title.toLowerCase().includes(search.toLowerCase())
          if (check = option.component.component_title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        });
      }
    })
    return check;
  }

  const checkChasisAllProducts = (data, search) => {
    let check = false;

    data.filter((item) => {
      if (item.product != undefined) {
        const productTags = item.product?.tags?.split(',').map((item) => {
          return item.trim();
        });
        const featuresTags = productTags.filter((item) =>
          item.startsWith('specifications:'),
        );

        featuresTags.map((item) => {
          if (item.split(':')[3] != undefined) {
            const description = item.split(':')[3].replace(/[^a-zA-Z0-9]/g, '');

            if (description.toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())) {
              check = true;
            }
          }
        })
      } else {
        if (item.chassisGroup != undefined) {
          item.chassisGroup[0]?.options?.map((option) => {
            if (option.component.component_title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())) {
              check = true;
            }
          })
        }
      }
    })
    return check;
  }

  const checkChasisRTS = (item, search) => {
    let check = false;

    if (item.product != undefined) {
      const productTags = item.product?.tags?.split(',').map((item) => {
        return item.trim();
      });
      const featuresTags = productTags.filter((item) =>
        item.startsWith('specifications:'),
      );

      featuresTags.map((item) => {
        if (item.split(':')[3] != undefined) {
          const description = item.split(':')[3].replace(/[^a-zA-Z0-9]/g, '');
          if (description.toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())) {
            check = true;
          }
        }
      })
    }
    return check;
  }

  const checkTags = (data, search) => {
    const filteredData = data.filter(item => {
      return item.product?.tags?.toLowerCase().replace(/[^a-zA-Z0-9]/g, '').includes(search.replace(/[^a-zA-Z0-9]/g, ''));
    });
    if (filteredData.length != 0) {
      return true;
    }
    return false;
  }

  const handleSearch = (value) => {
    if (value.includes('notebook')) {
      setSearch('laptop');
    } else if (value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '').includes('samedaydelivery')) {
      setSearch('samedayshipping');
    } else if (value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '').includes('sff')) {
      setSearch('itx');
    } else {
      setSearch(value)
    }
  }

  return (
    <>
      <nav>
        {isAnnouncementBarOpen && topBannerContents && (
          <div className="shipping-banner">
            <div className="wrapper shipping-banner">
              {topBannerTimerContents ? (
                <>
                  {topBannerContents}
                  {/* <Countdown
                    renderer={renderer}
                    date={new Date(topBannerTimerContents)}
                  /> */}
                  <BannerCountdownTimer
                    date={topBannerTimerContents}
                    handleCloseBanner={handleCloseBanner}
                    hideBannerTimerRunsOut={hideBannerTimerRunsOut}
                  />
                </>
              ) : (
                topBannerContents
              )}
              <div
                className="close_banner"
                onClick={handleCloseBanner}
                aria-hidden="false"
              >
                <Image 
                  draggable={false} 
                  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936" 
                  width="16" 
                  height="16" 
                  alt="close_icon" 
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        )}
      </nav>
      <nav className="header-nav">
        <div className="top_nav">
          <div className="menu_container">
            <div className="wrapper">
              <div className="nav-logo">
                <Logo width={148} height={32} />
              </div>
              {/* {['/me', '/me/sign-up'].includes(router.pathname) ? (
                <AccountPrimary
                  ksps={kspItems}
                  quiz={quizItems}
                  data={menuItems}
                  opacityRef={opacityRef}
                />
              ) : (
                <Primary
                  ksps={kspItems}
                  quiz={quizItems}
                  data={menuItems}
                  opacityRef={opacityRef}
                />
              )} */}
              <Primary
                ksps={kspItems}
                quiz={quizItems}
                data={menuItems}
                opacityRef={opacityRef}
                showCtaBanner={showCtaBanner}
                showQuotationBanner={showQuotationBanner}
              />
              <div className="icon_section">
                {menuItems.length > 0 &&
                  <div 
                    className='wide-search-bar-container' 
                    style={search != '' ? { width: '200px' } : {}} 
                    onFocus={() => getAllElementsInSearch()}
                  >
                    <input className='wide-search-bar' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                    <button className="search-icon">
                      <img draggable="false"  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-search-gray_736e3137-c729-4aad-b020-7bb9ed902c1e.png?v=1714032128" alt="icon-search" width={15} height={15} />
                    </button>

                    {search != '' &&
                      <div className="search-result-con" id="scroll" >
                        {
                          loadingSearch ?
                            <div className="loading-search">
                              <p className="loading-text">Loading</p>
                              <ThreeDots
                                height="25"
                                width="25"
                                radius="9"
                                color="#950610"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={loadingSearch}
                              />
                            </div>
                            : search != '' &&
                            (
                              (allProducts.filter((item) => item.product?.title?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())).length == 0) &&
                                (allProducts.filter((item) => item.model_title?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())).length == 0) &&
                                (allProducts.filter((item) => item.title1?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())).length == 0) &&
                                (allProducts.filter((item) => item.seo_title?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())).length == 0) &&
                                (allProducts.filter((item) => item.subtitle?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())).length == 0) &&
                                !checkChasisAllProducts(allProducts, search) &&
                                !checkGraphicsCard(allProducts, search) &&
                                !checkCPU(allProducts, search) &&
                                !checkTags(allProducts, search) ?
                                <div>
                                  <p>No result found</p>
                                </div>
                                : (
                                  allProducts.map((item) => {
                                    const checkPcModel = item?.modelOptionGroup?.filter((options) => options?.optionGroup?.option_group_title == "Graphics Card");
                                    const checkPcModelChassis = item?.chassisGroup?.filter((options) => options?.optionGroup?.option_group_title == "Chassis");
                                    const checkPcModelCpu = item?.cpuGroup?.filter((options) => options?.optionGroup?.option_group_title == "CPU");

                                    if (
                                      item.product?.title?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) ||
                                      item.model_title?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) ||
                                      checkChasisRTS(item, search) ||
                                      item.title1?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) ||
                                      item.seo_title?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) ||
                                      item.subtitle?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) ||
                                      (item.product?.tags?.toLowerCase().replace(/[^a-zA-Z0-9]/g, '').includes(search.replace(/[^a-zA-Z0-9]/g, ''))) ||
                                      (item.buildType?.toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '')))
                                    ) {
                                      const productImage = item.product?.image?.src ? item.product?.image?.src : item.thumb_image ? item.thumb_image : item.thumbnail?.url;
                                      const productTitle = item.product?.title ? item.product?.title?.split("/") : item.model_title ? item.model_title : item.title1;
                                      const productDescription = item.product?.body_html ? (item.product?.body_html?.replace(/<[^>]*>/g, '')) : item.seo_description;
                                      return (
                                        <a
                                          href={item.product?.handle ? `/products/${item.product?.handle}` : item.slug ? `/pc-models/${item.slug}` : item.link}
                                          className='search-section'
                                          onClick={() => window.location.href = item.product?.handle ? `/products/${item.product?.handle}` : `/pc-models/${item.slug}`}
                                        >
                                          <div className='search-image'>
                                            <img draggable="false"  width={'100%'} src={productImage} />
                                          </div>
                                          <div className='search-description'>
                                            <h1 className='product-title'>{item.product?.title ? productTitle[0] : productTitle}</h1>
                                            <h1 className='product-subtitle'>{item.product?.title ? productTitle[1] : item.seo_title ? item.seo_title : item.subtitle.replace(/\bquotation\b/g, '')}</h1>
                                            <div className='product-description'>{productDescription}</div>
                                          </div>
                                        </a>
                                      )
                                    } else if (checkPcModelChassis != undefined || checkPcModel != undefined || checkPcModelCpu != undefined) {
                                      const check = checkPcModel[0]?.options?.map((option) => {
                                        if (option.component.component_title.toLowerCase().includes(search.toLowerCase())) {
                                          return true;
                                        }
                                      })
                                      const hasTrueValue = check?.includes(true);

                                      const checkChassis = checkPcModelChassis[0]?.options?.map((option) => {
                                        if (option.component.component_title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())) {
                                          return true;
                                        }
                                      })
                                      const hasTrueValueChassis = checkChassis?.includes(true);

                                      const checkCpu = checkPcModelCpu[0]?.options?.map((option) => {
                                        if (option.component.component_title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())) {
                                          return true;
                                        }
                                      })
                                      const hasTrueValueCpu = checkCpu?.includes(true);

                                      if (hasTrueValue || hasTrueValueChassis || hasTrueValueCpu) {
                                        const productImage = item.thumb_image;
                                        const productTitle = item.model_title;
                                        const productDescription = item.seo_description;
                                        return (
                                          <a href={`/pc-models/${item.slug}`} className='search-section' onClick={() => window.location.href = `/pc-models/${item.slug}`}>
                                            <div className='search-image'>
                                              <img draggable="false"  width={'100%'} src={productImage} />
                                            </div>
                                            <div className='search-description'>
                                              <h1 className='product-title'>{productTitle}</h1>
                                              <h1 className='product-subtitle'>{item.seo_title}</h1>
                                              <div className='product-description'>{productDescription}</div>
                                            </div>
                                          </a>
                                        )
                                      }
                                    }
                                  })
                                )
                            )
                        }
                      </div>
                    }
                  </div>
                }

                {/* <CountrySelector backgroundColor="#242424" isShowFullName /> */}
                {/* <button className="chat_icon" onClick={handleChatToggle}>
                  <Image draggable={false}
                    src={'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-chat.svg?v=1714033559'}
                    alt={'icon-chat'}
                    width={18}
                    height={18}
                  />
                  <span className="chat_badge" />
                  <span className="chat_badge ping" />
                </button> */}
                <ProfileButton
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
                />
                {/* <div>
                  <Link href="/me">
                    <a href="/me">
                      <div className="profile_icon">
                        <Image draggable={false}
                          src={
                            isLoggedIn
                              ? 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-profile-logged-in.svg?v=1714033595'
                              : 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-profile.svg?v=1714033625'
                          }
                          alt="cart icon"
                          className="cart_icon"
                          layout="fixed"
                          width={24}
                          height={24}
                        />
                      </div>
                    </a>
                  </Link>
                </div> */}

                <div
                  type="button"
                  className="cart cart_container"
                  onClick={handleCartOpen}
                  aria-hidden="false"
                >
                  <Image draggable={false}
                    src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-cart.svg?v=1714039956"
                    alt="cart icon"
                    className="cart_icon"
                    layout="fixed"
                    loading="lazy"
                    width={24}
                    height={24}
                  />
                  <span>
                    {checkoutId && <CartCounter checkoutId={checkoutId} />}
                  </span>
                </div>

                <RegionSwitchButton />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <style jsx>{styles}</style>

    </>
  );
};

Navigation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  header: PropTypes.any,
};

// Navigation.defaultProps = {
//   header: null,
// };

export default memo(Navigation);
