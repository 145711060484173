import React, { useState } from 'react';
import SidePanelButtonLink from './SidePanelButtonLink';
import SidePanelFormButton from './SidePanelFormButton';
import SidePanelTextField from './SidePanelTextField';
import { useMutation } from '@apollo/client';
import {
  CUSTOMER_LOGIN_MUTATION,
  customerLoginVars,
} from 'helpers/customer';
import { Form, Formik, Field } from 'formik';
import { setCustomerToken as setCustomerTokenCookie } from 'helpers/cookies';
import * as Yup from 'yup';
import FormSubmitErrors from 'components/Form/FormSubmitErrors';

function SidePanelLoginForm({
  onHandleLogin: onSuccessfulLogin,
  onHandleForgotPassword,
  onHandleCreateProfile,
  setIsLoggedIn
}) {
  const [customerLogin, { error, data }] = useMutation(CUSTOMER_LOGIN_MUTATION);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(8, 'Too Short!').max(50, 'Too Long!').required('Required'),
  });

  const onHandleLogin = (values) => {
    customerLogin({
      variables: customerLoginVars(values),
      update(proxy, { data: { customerAccessTokenCreate } }) {
        const { customerAccessToken } = customerAccessTokenCreate;
        if (customerAccessToken && customerAccessToken.accessToken) {
          const { accessToken, expiresAt } = customerAccessToken;
          // redirect to profile sidepanel
          setIsLoggedIn(true);
          setCustomerTokenCookie(customerAccessToken);
          onSuccessfulLogin()
        }

      }
    })
  }

  const handleKeyDown = (e, values) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (values) onHandleLogin(values);
    }
    return;
  }

  return (
    <>
      <div className="main">
        <p className="login_text">Please log in to your profile</p>
        {data && data.customerAccessTokenCreate.customerUserErrors ? (
          <FormSubmitErrors
            errors={data.customerAccessTokenCreate.customerUserErrors}
          />
        ) : null}
        {error ? (
          <FormSubmitErrors
            errors={[{ message: 'Something went wrong. Please try again.' }]}
          />
        ) : null}
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const { email, password } = values;
            onHandleLogin({ email, password });
          }}
        >
          {({ errors, touched, values }) => (
            <Form onKeyDown={(e) => handleKeyDown(e, values)}>
              <div className="inputs">
                <Field
                  name="email"
                  component={SidePanelTextField}
                  placeholder="Email"
                />
                <Field
                  name="password"
                  component={SidePanelTextField}
                  placeholder="Password"
                  type="password"
                />
              </div>

              <SidePanelButtonLink
                text={'Forgot password?'}
                onClick={onHandleForgotPassword}
              />
              <div className="button_container">
                <SidePanelFormButton text="LOG IN" type="submit" />
                <SidePanelFormButton
                  text="Create Profile"
                  onClick={onHandleCreateProfile}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <style jsx>{`
        .button_container {
          margin-top: 16px;
          display: grid;
          gap: 16px;
        }
        .main {
          color: #e4e4e4;
        }
        .login_text {
          padding: 0;
          margin: 0;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
        .inputs {
          margin-top: 15px;
          display: grid;
          gap: 16px;
        }
      `}</style>
    </>
  );
}

export default SidePanelLoginForm;
