import breakPoints from 'helpers/Styles';

const TextsOnly = ({ block, defaultBackgroundColor, primaryFontColor, secondaryFontColor }) => {
  if (!block) return '';
  const { primary } = block;
  const title = primary.title1 ? primary.title1 : null;
  const description = primary.description ? primary.description : null;
  const backgroundColour = primary?.background_colour
    ? primary.background_colour
    : defaultBackgroundColor;
  const titleColour = primary?.title1_colour
    ? primary.title1_colour
    : primaryFontColor;
  const descriptionColour = primary?.description_colour
    ? primary.description_colour
    : secondaryFontColor;

  return (
    <>
      <div className="texts_only_slice wrapper">
        <div className="slice_container">
          <h1>{title}</h1>
          <div className="description">
            <p>{description}</p>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .texts_only_slice {
            display: flex;
            justify-content: center;
            background-color: ${backgroundColour};
            padding: 40px 0;
          }
          .slice_container h1 {
            color: ${titleColour};
            font-family: 'Eurostile-Bold';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            text-align: center;
            text-transform: uppercase;
          }
          .slice_container p {
            width: 50%;
            color: ${descriptionColour};
            font-family: 'Roboto-Normal';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin: 0 auto;
          }

          @media (max-width: ${breakPoints.medium}) {
            .slice_container p {
              width: 90%;
            }
            .slice_container h1 {
              font-size: 26px;
              margin-top: 0;
         
          }
          @media (max-width: ${breakPoints.small}) {
            .texts_only_slice {
              padding: 20px 0;
            }
          }
        `}
      </style>
    </>
  );
};

export default TextsOnly;
