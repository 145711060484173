/* eslint-disable react/prop-types */
import React, { useState, memo, useContext } from 'react';
import pluck from 'utils/pluck';
import Router from 'next/router';
import Link from 'next/link';
import { StorefrontContext } from 'providers/storefront';
import Secondary from '../SecondaryMobile';
import SidePanel from '../../../../../components/SidePanel';
import SidePanelCreateProfileForm from '../../../../../components/SidePanel/SidePanelCreateProfileForm';
import SidePanelForgotPasswordForm from '../../../../../components/SidePanel/SidePanelForgotPasswordForm';
import SidePanelLoginForm from '../../../../../components/SidePanel/SidePanelLoginForm';
import SidePanelYourProfile from '../../../../../components/SidePanel/SidePanelYourProfile';
import SidePanelHelpAndSupport from '../../../../../components/SidePanel/SidePanelHelpAndSupport';
import {
  setCustomerToken as setCustomerTokenCookie,
  signOut,
} from 'helpers/cookies';
import { useRouter } from 'next/router';

const PrimaryMobile = ({
  ksps,
  data,
  closeMenu,
  componentItems,
  showRTSBannerMobile,
}) => {
  const { isAnnouncementBarOpen, isLoggedIn, setIsLoggedIn, setIsMenuOpen } =
    useContext(StorefrontContext);
  const [secondaryHeader, setSecondaryHeader] = useState(null);
  const [showSecondary, setShowSecondary] = useState(false);
  const [secondaryTabs, setSecondaryTabs] = useState([]);
  const [secondaryItems, setSecondaryItems] = useState([]);
  const [parentLink, setParenLink] = useState(null);
  const goBack = () => {
    setSecondaryItems([]);
    setSecondaryTabs([]);
    setShowSecondary(false);
    setParenLink(null);
  };

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { setProfileTabIndex, isChatOpen, setIsChatOpen } =
    useContext(StorefrontContext);
  const [currentIndex, setCurrentIndex] = useState(isLoggedIn ? 3 : 0);

  const router = useRouter();

  Router.onRouteChangeStart = () => {
    closeMenu();
  };

  const onHandleLogout = () => {
    signOut();
    setIsLoggedIn(false);
    setCurrentIndex(0);
    //bad and temporary solution
    window.location.reload();
  };

  const gotoOrders = () => {
    setProfileTabIndex(2);
    router.push('/me');
    setIsProfileModalOpen(false);
    setIsMenuOpen(false);
  };

  const gotoProfile = () => {
    setProfileTabIndex(0);
    router.push('/me');
    setIsProfileModalOpen(false);
    setIsMenuOpen(false);
  };

  const openChat = () => {
    window.FrontChat('show');
  };

  const states = [
    {
      title: 'log in',
      content: (
        <SidePanelLoginForm
          setIsLoggedIn={setIsLoggedIn}
          onHandleForgotPassword={() => setCurrentIndex(1)}
          onHandleCreateProfile={() => setCurrentIndex(2)}
          onHandleLogin={() => setCurrentIndex(3)}
        />
      ),
    },
    {
      title: 'Forgot password',
      content: (
        <SidePanelForgotPasswordForm
          onHandleGoBack={() => setCurrentIndex(0)}
        />
      ),
    },
    {
      title: 'Create Profile',
      content: (
        <SidePanelCreateProfileForm
          onCreateProfile={() => setCurrentIndex(3)}
          onHandleLogin={() => setCurrentIndex(0)}
        />
      ),
    },
    {
      title: 'Your Profile',
      content: (
        <SidePanelYourProfile
          onHandleLogout={() => onHandleLogout()}
          gotoOrders={gotoOrders}
          gotoProfile={gotoProfile}
          openChat={openChat}
        />
      ),
    },
    {
      title: null,
      content: (
        <SidePanelHelpAndSupport
          openChat={openChat}
          onCloseSidePanel={() => setIsProfileModalOpen(false)}
        />
      ),
    },
  ];

  return (
    <>
      <div className='primary_menu_container'>
        {/* {!showSecondary && (
          <div className="primary_menu_header">
            <div className="primary_menu_header_text">MENU</div>
          </div>
        )} */}
        {data && (
          <ul className='primary_menu'>
            {data.map((item, index) => {
              const primary = item.filter((n) => n.slice_type === '1st_level');
              if (!(primary.length > 0)) return null;
              const title = pluck(primary, '0.primary.title');
              const menuType = pluck(primary, '0.primary.menu_type');
              const primaryLink = pluck(primary, '0.primary.link');
              const items = item.filter((n) => n.slice_type === '3rd_level');
              const tabs = pluck(primary, '0.items');
              const key = `item-${index}`;
              const thirdLevelItems = item.filter(
                (n) => n.slice_type === '3rd_level',
              );
              const thirdLevel = [];
              if (thirdLevelItems.length > 0) {
                thirdLevel.push(thirdLevelItems[0]);
                thirdLevelItems.map((item, index) => {
                  if (thirdLevel[0].items.length < thirdLevelItems.length) {
                    if (index > 0) {
                      thirdLevel[0].items.push(item.items[0]);
                    }
                  }
                });
              }
              return (
                <React.Fragment key={key}>
                  {menuType === 'mega-menu' && (
                    <li
                      className='active-menu'
                      aria-hidden='false'
                      onClick={() => {
                        if (showSecondary === key) {
                          setShowSecondary(null);
                        } else {
                          setShowSecondary(key);
                        }
                      }}
                    >
                      {title}
                      <div
                        className={`img-container ${
                          showSecondary === key ? 'rotated' : ''
                        }`}
                      >
                        <img
                          draggable='false'
                          src='https://cdn.shopify.com/s/files/1/0522/3320/7988/files/chevron-right.png?v=1713865512'
                          alt='first_level menu'
                          layout='fixed'
                        />
                      </div>
                    </li>
                  )}
                  <div className='submenu-container'>
                    <div
                      className={`submenu ${
                        showSecondary === key ? 'open' : ''
                      }`}
                    >
                      <Secondary
                        items={items}
                        secondaryHeader={secondaryHeader}
                        tabs={secondaryTabs}
                        closeMenu={closeMenu}
                        goBack={goBack}
                        ksps={ksps}
                        parentLink={parentLink}
                        componentItems={componentItems}
                        showRTSBannerMobile={showRTSBannerMobile}
                      />
                    </div>
                  </div>
                  {/* {!showSecondary && (
                    <div>
                      {menuType === 'simple-link' && primaryLink && (
                        <Link type='button' href={primaryLink} passHref onClick={onClose}>
                          <li className="active-menu" aria-hidden="false">
                            {title}
                            <div>
                              <img draggable="false"
                                src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-right.png?v=1714032175"
                                alt="first_level menu"
                                layout="fixed"
                              />
                            </div>
                          </li>
                        </Link>
                      )}
                    </div>
                  )} */}
                </React.Fragment>
              );
            })}
            {/* {showSecondary && (
              <Secondary
                items={secondaryItems}
                secondaryHeader={secondaryHeader}
                tabs={secondaryTabs}
                onClose={onClose}
                goBack={goBack}
                ksps={ksps}
                parentLink={parentLink}
                componentItems={componentItems}
                showRTSBannerMobile={showRTSBannerMobile}
              />
            )} */}
            <li
              className='active-menu'
              aria-hidden='false'
              onClick={() => {
                setCurrentIndex(0);
                setIsProfileModalOpen((prev) => !prev);
              }}
            >
              {'ACCOUNT'}
              <div>
                <img
                  draggable='false'
                  src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-right.png?v=1714032175'
                  alt='first_level menu'
                  layout='fixed'
                />
              </div>
            </li>
          </ul>
        )}

        {isLoggedIn ? (
          <SidePanel
            title={states[3].title}
            isOpen={isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
            isHasFeaturedImage={false}
          >
            {states[3].content}
          </SidePanel>
        ) : (
          <SidePanel
            title={states[currentIndex].title}
            isOpen={isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
            isHasFeaturedImage={currentIndex === 4}
          >
            {states[currentIndex].content}
          </SidePanel>
        )}
      </div>

      <style jsx>
        {`
          .primary_menu_header_text {
            color: #949494;
            font-size: 13px;
            line-height: 20px;
            font-weight: 700;
            font-family: 'Roboto-Medium';
          }
          .primary_menu_header {
            display:flex;
            justify-content: center;
            align-items: center;
            height:56px;
            width: 100%;
            background: #141414;
            border-top: 1px solid rgba(228,228,228,0.2) ;
            border-bottom: 1px solid rgba(228,228,228,0.2) ;
          }
          .primary_menu_container {
            margin-top:${isAnnouncementBarOpen ? '80px' : '48px'};
            height: auto;
            background: #000000;
            transition: all 0.6s linear;
            animation: slide-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          }

          @keyframes slide-left {
            0% {
              opacity: 0;
              transform: translateX(100%);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .primary_menu {
            background: #000000;
            margin: 0;
            width: 100%;
            margin: 0 auto;
            padding: 0;
            border-top: 1px solid rgba(228,228,228,0.2) ;
          }
          .img-container {
            display: inline-block;
            transition: transform 0.3s ease;
          }
          .rotated {
            transform: rotate(90deg);
          }
          .submenu-container {
            overflow: hidden;
          }
          .submenu {
            transition: max-height 0.5s ease;
            max-height: 0;
          }
          .submenu.open {
            max-height: 770px;
          }
          .primary_menu li {
            height: 56px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 16px 0;
            border-bottom: 1px solid rgba(228,228,228,0.2) ;
            color: #E4E4E4;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            padding-left: 16px;
            padding-right: 10px;
            cursor:pointer;
          }
          .category_navigation__title{
            display: block !important:
            margin-left: 10px;
            color: red;
          }
          .primary_menu li a {
            color: #141414;
          }
        `}
      </style>
    </>
  );
};

export default memo(PrimaryMobile);
