import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Carousel } from 'react-responsive-carousel';
import breakPoints from 'helpers/Styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Image from 'next/image';

const HeroCarousel = ({ block }) => {
  const { items } = block;
  const [scrollTop, setScrollTop] = useState(0);
  const scrollButtonRef = useRef();
  useEffect(() => {
    function handleScroll() {
      // Update the state with the new scrollTop value
      setScrollTop(
        document.documentElement.scrollTop || document.body.scrollTop,
      );
    }

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);
    // Detach the event listener on cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const fadeSpeed = 'opacity 0.5s';
    if (scrollTop < 200) {
      const scrollTimeout = setTimeout(() => {
        if (scrollButtonRef.current && scrollButtonRef.current.style != null) {
          scrollButtonRef.current.style.display = 'block';
        }
        clearTimeout(scrollTimeout);
      }, 200);
      scrollButtonRef.current.style.opacity = 1;
      scrollButtonRef.current.style.transition = fadeSpeed;
    } else {
      scrollButtonRef.current.style.opacity = 0;
      scrollButtonRef.current.style.transition = fadeSpeed;
      const scrollTimeout = setTimeout(() => {
        if (scrollButtonRef.current && scrollButtonRef.current.style != null) {
          scrollButtonRef.current.style.display = 'none';
        }
        clearTimeout(scrollTimeout);
      }, 200);
    }
  }, [scrollTop]);

  if (!items || !items.length) return '';

  const onScrollDown = () => {
    const topToScroll = window.innerHeight - 150;

    window.scrollTo({ top: topToScroll, behavior: 'smooth' });
    // Get the current scroll position of the document
  };

  return (
    <>
      <div className='banner_large_screen'>
        <div className='hero-carousel'>
          <div className='scroll-down-indicator'>
            <button
              ref={scrollButtonRef}
              onClick={onScrollDown}
              className='scroll-down-button'
              aria-label='scroll-down-button'
            >
              {/* <img draggable="false" 
              src="/icons/icon_chevron-double-red.svg"
              alt="scroll-down-icon"
              className="scroll-down-icon"
            /> */}
            </button>
          </div>
          <Carousel
            showStatus={false}
            showThumbs={false}
            useKeyboardArrows
            infiniteLoop
            autoPlay
            interval={5000}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={100}
            showIndicators={items.length > 1 || false}
            axis='horizontal'
          >
            {items &&
              items.map((item, index) => (
                <div key={index}>
                  <Link href={item?.link || '#'}>
                    <a href={item?.link || '#'}>
                      {item?.image?.url ? (
                        <div className='banner_image'>
                          <Image
                            draggable={false}
                            src={item.image.url}
                            alt={
                              (item.image && item.image.alt) ||
                              'Aftershock PC Banner Image'
                            }
                            width={item.image.dimensions.width}
                            height={item.image.dimensions.height}
                            loading={index === 0 ? 'eager' : 'lazy'}
                            priority={index === 0}
                          />
                        </div>
                      ) : null}

                      {item?.mobile_image?.url ? (
                        <div className='banner_image_mobile'>
                          <Image
                            draggable={false}
                            src={item.mobile_image.url}
                            alt={
                              (item.image && item.image.alt) ||
                              'Aftershock PC Banner Image Mobile'
                            }
                            width={item.mobile_image.dimensions.width}
                            height={item.mobile_image.dimensions.height}
                            loading={index === 0 ? 'eager' : 'lazy'}
                            priority={index === 0}
                          />
                        </div>
                      ) : (
                        <div className='banner_image_mobile'>
                          <Image
                            draggable={false}
                            src={item?.image?.url}
                            alt={
                              (item.image && item.image.alt) ||
                              'Aftershock PC Banner Image Mobile 2'
                            }
                            width={item.mobile_image.dimensions.width}
                            height={item.mobile_image.dimensions.height}
                            loading={index === 0 ? 'eager' : 'lazy'}
                            priority={index === 0}
                          />
                        </div>
                      )}

                      <div className='contents'>
                        {item?.title1 ? (
                          <div className='title'>{item.title1}</div>
                        ) : null}
                        {item?.button_text !== null ? (
                          <Link href={item?.button_link || '#'}>
                            <div
                              href={item?.button_link || '#'}
                              className='button_container'
                            >
                              <button
                                type='button'
                                className='two_column_button'
                              >
                                {item?.button_text}
                              </button>
                              <div className='arrow_right'>
                                <img
                                  draggable='false'
                                  src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_arrow_5.png?v=1714047897'
                                  alt='icon_arrow'
                                />
                              </div>
                            </div>
                          </Link>
                        ) : null}
                      </div>
                    </a>
                  </Link>
                </div>
              ))}
          </Carousel>
          <style jsx global>
            {`
              .hero-carousel {
                position: relative;
              }
              .hero-carousel .carousel,
              .carousel-slider {
                padding-bottom: 35px;
              }
              .hero-carousel .carousel .control-dots .dot {
                width: 12px;
                height: 12px;
              }

              .scroll-down-indicator {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                bottom: 43px;
                z-index: 1;
                animation: bounce 1s infinite;
              }
              .scroll-down-button {
                background: none;
                outline: none;
                border: none;
                user-select: none;
              }
              .scroll-down-icon {
                height: 50px;
                color: red;
              }

              @media screen and (max-width: 991px) {
                .scroll-down-indicator {
                  display: none;
                }
              }

              @media screen and (min-width: 3000px) {
                .scroll-down-icon {
                  height: 100px;
                }
                .scroll-down-indicator {
                  bottom: 200px;
                }
              }

              @keyframes bounce {
                0%,
                100% {
                  transform: translateY(-25%);
                  animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
                }
                50% {
                  transform: translateY(0);
                  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
                }
              }

              .banner_large_screen {
                width: 1435px;
                margin: 0 auto;
              }

              @media (max-width: 1500px) {
                .banner_large_screen {
                  width: 100%;
                  margin: 0 auto;
                }
              }
            `}
          </style>
          <style jsx>
            {`
              .hero-carousel {
                overflow: hidden;
              }
              .banner_image {
                width: 100%;
                height: 100%;
                position: relative;
              }
              // .banner_image img {
              //   min-height: 587px;
              //   max-height: 587px;
              // }
              .banner_image_mobile {
                display: none;
              }
              .contents {
                width: 100%;
                margin: 0 auto;
                position: absolute;
                top: 45%;
              }
              .title {
                color: #e4e4e4;
                font-family: 'big_noodle_titling';
                font-size: 49px;
                letter-spacing: 0;
                line-height: 48px;
                text-align: center;
              }
              .button_container {
                display: flex;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 20px;
              }
              .button_container:hover > .two_column_button {
                background: #800006;
              }
              .button_container:hover > .arrow_right {
                background: #141414;
              }
              .two_column_button {
                height: 48px;
                min-width: 128px;
                color: white;
                background: #950810;
                font-family: 'Roboto-Medium';
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
                border-radius: 3px 0 0 3px;
                border: none;
                outline: 0;
                padding: 12px 24px;
                text-transform: uppercase;
              }
              .arrow_right {
                width: 48px;
                height: 48px;
                background: #242424;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 0px 3px 03px 0px;
              }
              .arrow_right img {
                width: 25px;
              }
              @media (min-width: ${breakPoints.large}) {
                .title {
                  left: 40%;
                }
              }
              @media (max-width: ${breakPoints.medium}) {
                // .banner_image {
                //   height: 200px;
                // }
                .title {
                  font-size: 32px;
                  top: 40%;
                  left: 23%;
                }
              }
              @media (max-width: 428px) {
                .banner_image_mobile {
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: relative;
                }
                .banner_image {
                  display: none;
                }
                .title {
                  font-size: 26px !important;
                }
                .contents {
                  top: 20%;
                }
              }
            `}
          </style>
        </div>
      </div>
    </>
  );
};

HeroCarousel.propTypes = {
  block: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        link: PropTypes.string,
        link_type: PropTypes.string,
      }),
    ),
  }),
};

HeroCarousel.defaultProps = {
  block: null,
};

export default memo(HeroCarousel);
