import React from 'react';
import styles from './styles';
import Proptypes from 'prop-types';

const HolidayOffers = ({ block }) => {
  if (!block) return '';

  const { primary, items } = block;
  const is4Columns = primary.is_4_columns;

  const redirectTo = (url) => {
    window.location = url;
  };

  return (
    <>
      <div className="main-container">
        <div className="header-container">
          <div>
            <h1 className="title">{primary?.title1}</h1>
          </div>
          <div>
            <h3 className="header">{primary?.header}</h3>
          </div>
          <div>
            <p className="description">{primary?.description}</p>
          </div>

          <div className="product-container">
            {items.map((data, index) => {
              return (
                <div className="product-card" key={index}>
                  <div className="product-img">
                    {data.product_image.url && (
                      <img draggable="false" 
                        src={data.product_image.url}
                        width={'100%'}
                        height={'100%'}
                        onClick={() => redirectTo(data.button_link || '#')}
                        alt="product_image"
                      />
                    )}
                  </div>
                  <div className="product-details">
                    <h4 className="product-header">{data.header}</h4>
                    <p className="product-name">{data.product_name}</p>
                  </div>
                  <div className="btn-container">
                    <button
                      className="btn"
                      onClick={() => redirectTo(data.button_link || '#')}
                    >
                      {data.button_text != undefined
                        ? data.button_text
                        : 'Buy Now'}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div className="copyright-container">
          <div className="copyright-sub-container">
            <div className="logo-container">
              <div className="aftershock-logo">
                <div className="logo-img">
                  <img draggable="false" 
                    src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/header_logo.svg?v=1714058810"
                    width={'100%'}
                    height={'100%'}
                    alt='header_logo'
                  />
                </div>
              </div>
              <div className="geforce-logo">
                <div className="logo-img">
                  {primary?.geforce_logo.url && (
                    <img draggable="false" 
                      src={primary?.geforce_logo.url}
                      width={'100%'}
                      height={'100%'}
                      alt='gefore_logo'
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <p className="copyright-text">{primary?.copyright_description}</p>
            </div>
          </div>
        </div> */}
      </div>

      <style jsx>{styles}</style>
      <style jsx>
        {`
          .product-container {
            display: grid;
            gap: 20px;
          }
          .product-img {
            height: ${is4Columns ? 'auto' : '280px'};
            cursor: pointer;
          }
          .product-card {
            width: 100%;
          }
          @media (min-width: 600px) {
            .product-container {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }
          }
          @media (min-width: 900px) {
            .product-container {
              grid-template-columns: repeat(
                ${is4Columns ? '4' : '3'},
                minmax(0, 1fr)
              );
            }
          }
        `}
      </style>
    </>
  );
};

HolidayOffers.propTypes = {
  block: Proptypes.shape({
    primary: Proptypes.shape({
      title1: Proptypes.string,
      header: Proptypes.string,
      description: Proptypes.string,
      geforce_logo: Proptypes.object,
      copyright_description: Proptypes.string,
      is_4_columns: Proptypes.bool,
    }),
    items: Proptypes.array,
  }),
};

HolidayOffers.defaultProps = {
  block: null,
};

export default HolidayOffers;
