import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PRODUCT_BY_ID, productByIdVars } from 'helpers/graphql/product';
import { useApolloClient } from '@apollo/client';

const VariantsMobile = ({
  calledBannerText,
  variantMetaFields,
  productHandle,
}) => {
  const [activeLabel, setActiveLabel] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const client = useApolloClient();

  // const toggleLabel = (label) => {
  //   setActiveLabel((prevLabel) => (prevLabel === label ? null : label));
  // };

  useEffect(() => {
    const fetchData = async () => {
      const allProductIds = variantMetaFields.flatMap((itm) =>
        JSON.parse(itm?.node?.value),
      );

      const promises = allProductIds.map(async (product_id) => {
        const result = await client.query({
          query: PRODUCT_BY_ID,
          variables: productByIdVars(product_id),
        });
        return {
          productId: product_id,
          data: result.data,
        };
      });

      const results = await Promise.all(promises);
      setProductsData(results);
    };

    fetchData();
  }, [variantMetaFields, client]);

  return (
    <>
      <div className='container-mobile'>
        <div className='dropdown'>
          <div className='dropdown-title'>
            <div className='dropdown-text'>
              <p className='label'>
                Multiple versions of this build are available
              </p>
            </div>
            {/* <div className='dropdown-image'>
              <Image
                src={
                  show
                    ? 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-up-gray.png?v=1714032503'
                    : 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-down-gray.png?v=1714032823'
                }
                width={40}
                height={35}
              />
            </div> */}
          </div>
          <div className='dropdown-content'>
            {productsData.map(({ productId, data }) => {
              if (data) {
                const thumbnail = data?.product?.images?.edges.filter(
                  (image) => image.node.altText === 'rts_variant',
                );
                const bannerTag = data?.product?.tags.find((tag) => {
                  return tag.split(':')[0] === 'banner_variant';
                });
                
                const shortDescriptions = data.product.metafields
                .filter((field) => field?.key === 'short_description')
                .map((field) => field.value)[0];
 
  
                const banner = (bannerTag && calledBannerText !== bannerTag.split(':')[1]) ? bannerTag.split(':')[1] : null;

                if (thumbnail.length !== 0) {
                  return (
                    <a
                      key={data?.product?.handle}
                      className={`variant-wrapper ${
                        productHandle === data?.product?.handle ? 'active' : ''
                      }`}
                      onClick={
                        productHandle != data?.product?.handle
                          ? () =>
                              (window.location.href = `/products/${data?.product?.handle}`)
                          : () => {}
                      }
                    >
                      <div
                        className={`variant-label ${
                          activeLabel === data?.product?.handle
                            ? 'variant-label-active'
                            : ''
                        } ${banner ? '' : 'empty-banner'}`}
                      >
                        <span>{banner}</span>
                      </div>
                      <div className='variant-details'>
                        <div className='image-container'>
                          <img
                            draggable='false'
                            src={thumbnail[0]?.node?.originalSrc}
                            className={`variant_image`}
                            alt='variant-image'
                          />
                        </div>
                        <div className='overlay-text-wrapper'>
                          <div className='overlay-text'>{shortDescriptions}</div>
                          {/* {description.length > 100 && (
                            <div className='read-more'>
                              <a href='#product-details-section'>Read More</a>
                            </div>
                          )} */}
                          <div className='variant-price'>
                            $
                            {data?.product?.variants?.edges[0]?.node?.price
                              ?.amount
                              ? Number(
                                  data.product.variants.edges[0].node.price
                                    .amount,
                                ).toFixed(2)
                              : '$0.00'}
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                }
              }
            })}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .container-mobile {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
          }
          .dropdown {
            width: 100%;
            position: relative;
            background-color: #141414;
            padding: 20px 0;
          }
          .dropdown-title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          ..dropdown-text {
            width: 100%;
          }
          .label {
            width: 100%;
            display: block;
            color: #f1f2f1;
            font-family: 'Roboto-Medium';
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0;
            cursor: pointer;
            margin: 0 0 15px;
            text-align: center;
            text-transform: uppercase;
          }
          .sub-label {
            display: block;
            color: #989898;
            font-family: 'Roboto-Bold';
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            padding: 0;
            margin: 0;
            margin-bottom: 20px;
          }
          .dropdown-content {
            width: 100%;
            padding-top: 10px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .variant-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid transparent;
            border-radius: 5px;
            background-color: #242424;
            position: relative;
            transition:
              background 0.3s ease-in-out,
              box-shadow 0.3s ease-in-out;
            margin-bottom: 10px;
          }
          .variant-label {
            position: absolute;
            font-family: 'Roboto-Bold';
            font-size: 10px;
            background-color: #950810;
            padding: 3px 30px;
            border-radius: 5px;
            cursor: pointer;
            top: -10px;
          }
          .empty-banner {
            height: 19px;
            background: transparent;
          }
          .variant-details {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 10px;
            gap: 15px;
          }
          .variant-price {
            text-align: center;
            padding: 5px;
            color: #f1f2f1;
            font-family: 'big_noodle_titling';
            font-size: 18px;
          }
          .image-container {
            position: relative;
            width: 100%;
            max-width: 80px;
          }
          .variant_image {
            width: 100%;
            background-color: #2d2d2d;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 5px 5px;
          }
          .overlay-text-wrapper {
            max-width: 72%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Roboto-Medium';
            font-size: 12px;
            overflow: hidden;
          }
          .overlay-text {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            width: 80%;
            text-align: left;
            color: #fff;
            padding: 10px 10px 0;
          }
          .read-more {
            position: absolute;
            bottom: 5px;
            right: 5px;
            padding: 2px 4px;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            text-decoration: underline;
            color: #950810;
          }
          .active {
            border: 2px solid #950810;
          }
          @media (min-width: 769px) {
            .container-mobile {
              display: none;
            }
          }
          @media (max-width: 768px) {
            .variant-details {
              justify-content: space-between;
            }
            .image-container {
              margin-left: 5%;
            }
          }
          @media (max-width: 430px) {
            .overlay-text-wrapper {
              width: 90%;
            }
            .variant-details {
              justify-content: space-between;
            }
            .image-container {
              margin-left: 0;
            }
            .overlay-text {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default VariantsMobile;
