export const sortedFeatures = (featuresTags) => {
  const features = [];
  
  featuresTags.map((feat) => {
    const parts = feat.split(':');
    
    if (parts.length >= 4) {
      const temp = {
        id: parts[1],
        type: parts[2],
        description: parts.slice(3).join(':'),
      };
      features.push(temp);
    }
  });

  return features.sort((a, b) => {
    const numA = Number(a.id.match(/\d+/)?.[0]);
    const numB = Number(b.id.match(/\d+/)?.[0]);

    const isANumber = !isNaN(numA);
    const isBNumber = !isNaN(numB);

    if (isANumber && isBNumber) {
      return numA - numB;
    } else if (isANumber) {
      return 1;
    } else if (isBNumber) {
      return -1;
    } else {
      return a.id.localeCompare(b.id);
    }
  });
};


export const renderPngIcon = (featureType) => {
  if (!featureType) return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-default.svg?v=1714049852';
  if (featureType) {
    let type =
      featureType &&
      featureType.replace(/ /g, '').replace(/-/g, '').toLowerCase();
    if (type == 'graphicscard' || type == 'graphics-card') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/GPU_0ad4a1e5-48a3-4f02-b9b1-818b658d17db.png?v=1714051313';
    if (type == 'cpu' || type == 'processor') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/CPU.png?v=1714051325';
    if (type == 'storage') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-storage_2.png?v=1714051462';
    if (type == 'case') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/CHASSIS_2a732ab9-a26c-4ab5-8ee9-f99283920fde.png?v=1714068086';
    if (type == 'ram') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/RAM_70c7c1c8-7b7f-4e10-95df-9ddf75b83c60.png?v=1714051601';
    if (type == 'motherboard') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/MOTHERBOARD.png?v=1714051625';
    if (type == 'cooler' || type == 'coolingsystem') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/COOLER_58cf72f8-f188-4c47-8331-0f71593463b4.png?v=1714051672';
    if (type == 'fans') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-cooling_2.png?v=1714051735';
    if (type == 'power') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/POWER_SUPPLY.png?v=1714051780';
    if (type == 'operatingsystem') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/Operating-System.png?v=1714052047';
    if (type == 'wireless') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/WIFI.png?v=1714051832';
    if (type == 'cablesleeves') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/CABLE_SLEEVE.png?v=1714051854';
    if (type == 'warranty') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/WARRENTY.png?v=1714051886';
    if (type == 'display') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/SCREEN_708b2762-5f94-43c2-b5dc-852e7ee82409.png?v=1714051808';
    if (type == 'weight') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/Weight_5ed0c40b-0ed2-4c79-b2c3-292a136a5ce5.png?v=1714052071';
    if (type == 'dimensions') return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/Dimensions.png?v=1714052091';
    return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-default.svg?v=1714049852';
  }
};
