import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  PRODUCT_BY_HANDLE,
  productByHandleVars,
} from 'helpers/graphql/product';
import breakPoints from 'helpers/Styles';
import Link from 'next/link';
import Image from 'next/image';
import EssentialProductsMobile from '../EssentialProductsMobile';
import AddToBag from '../AddToBag';

const EssentialProducts = ({ productTags }) => {
  const essentialProductsTags = productTags.filter((item) =>
    item.startsWith('essentials:'),
  );

  const productsHandles = essentialProductsTags.map(
    (item) => item.split(':')[1],
  );

  const essentialProducts = productsHandles.map((handle) => {
    const productHandle = handle.replace(/\ /g, '-');

    const { loading, data, error } = useQuery(PRODUCT_BY_HANDLE, {
      variables: productByHandleVars(productHandle),
    });
    if (loading || (!data && !error)) {
      return <>loading</>;
    }
    if (error || !data) {
      return <>{JSON.stringify(error)}</>;
    }
    if (!error) {
      return data;
    }
  });

  return (
    <>
      {essentialProducts.length > 0 ? (
        <>
          <div className="essential_products_container">
            <h1 className="title">FREQUENTLY PURCHASED WITH</h1>
            <div className="products">
              {essentialProducts.map((item, index) => {
                const { product } = item;
                const productId = item.id;
                const productImage =
                  product?.images?.edges[0]?.node?.originalSrc ||
                  'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274';

                const productTitle = product ? product.title : null;
                const productPrice = product
                  ? product.variants.edges[0].node.price
                  : null;
                const compareAtPrice = product
                  ? product.variants.edges[0].node.compareAtPrice
                  : null;
                const productVariant = product
                  ? product.variants.edges[0].node
                  : null;
                const productHandle = product ? product.handle : null;
                const essentialProductTags = product ? product.tags : null;
                const productSKU = product
                  ? product.variants.edges[0].node.sku
                  : null;
                return (
                  <div className="essential_product_single" key={index}>
                    <Link href={`/products/${productHandle}`}>
                      <a href={`/products/${productHandle}`}>
                        <div className="image_container">
                          <Image draggable={false}
                            src={productImage}
                            alt="essential product"
                            width={197}
                            height={197}
                          />
                        </div>
                      </a>
                    </Link>

                    <h2 className="product_title">{productTitle}</h2>
                    <h1 className="product_price">
                      ${productPrice} &nbsp;
                      {compareAtPrice && (
                        <del>
                          <span className="product_sale_price">
                            ${compareAtPrice}
                          </span>
                        </del>
                      )}
                    </h1>
                    <div>
                      <AddToBag
                        productTitle={productTitle}
                        productPrice={productPrice}
                        selectedVariant={productVariant}
                        productImage={productImage}
                        productHandle={productHandle}
                        productTags={essentialProductTags}
                        productSKU={productSKU}
                        productId={productId}
                        customizedClass="essential_products_cart"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <EssentialProductsMobile essentialProducts={essentialProducts} />
          </div>
        </>
      ) : null}

      <style jsx>
        {`
          .essential_products_container {
            width: 100%;
            margin: 0 auto;
          }
          .products {
            display: flex;
            justify-content: center;
            margin: 0 auto;
          }
          .title {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 31px;
            letter-spacing: 0;
            line-height: 40px;
            text-align: center;
          }
          .product_title {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            height: 40px;
          }
          .product_price {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 30px;
          }
          .product_sale_price,
          del {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
          }
          .essential_product_single {
            width: 25%;
            margin-right: 30px;
          }
          .image_container {
            background: #141414;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .product_image {
            max-width: 100%;
          }
          @media (max-width: ${breakPoints.medium}) {
            .products {
              display: none;
            }
            .title {
              font-size: 23px;
            }
          }
        `}
      </style>
    </>
  );
};
EssentialProducts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productTags: PropTypes.array.isRequired,
};

export default EssentialProducts;
