import React, { useEffect, useState } from 'react';
import breakPoints from 'helpers/Styles';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import debounce from 'utils/debounce';
import AddToBagRTS from '../AddToBag/rts';

const ProductDescriptions = ({
  productTitle,
  productDescription,
  productMetafields,
  productId,
  productVariant,
  products,
  productAvailable,
  variantMetaFields,
  productHandle
}) => {
  const [show, setShow] = useState(true);
  const title = productTitle.split('/')[0];
  const subtitle = productTitle.split('/')[1];
  const handleClick = () => {
    setShow(!show);
  };
  const techspecs = productMetafields.filter(
    (el) => el.node.key === 'technical_specifications',
  );
  const resizeWindow = () => {
    if (window.innerWidth < 767) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    const debouncedResizedWindow = debounce(resizeWindow, 100);
    window.addEventListener('resize', debouncedResizedWindow);
    return () => {
      window.removeEventListener('resize', debouncedResizedWindow);
    };
  });

  return (
    <>
      <div className='product_description'>
        <h1 className='product_title'>{title}</h1>
        <h1 className='product_subtitle'>{subtitle}</h1>
        <div
          className='product_text'
          dangerouslySetInnerHTML={{ __html: productDescription }}
        ></div>
        <div
          className='add_to_cart_desc_button'
          style={{ marginBottom: 20, marginTop: 10 }}
        >
          <AddToBagRTS
            products={products}
            soldOut={!productAvailable}
            disabled={!productAvailable}
            productTitle={productTitle}
            selectedVariant={productVariant}
            productId={productId}
            customizedClass='add_to_cart'
            isOnDescription
          />
        </div>
        {variantMetaFields[0] != undefined &&
          <Variants
            variantMetaFields={variantMetaFields}
            productHandle={productHandle}
          />
        }
        {techspecs[0]?.node.value && (
          <div className='tech_specs_container'>
            <div className='tech_specs'>
              <div>
                <h4>TECHNICAL SPECIFICATIONS</h4>
              </div>
              <div id='icon' onClick={handleClick}>
                {show ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div
              style={{ display: show ? 'block' : 'none' }}
              dangerouslySetInnerHTML={{ __html: techspecs[0]?.node.value }}
            ></div>
          </div>
        )}
      </div>

      <style jsx>
        {`
          .add_to_cart_desc_button {
            max-width: 200px;
          }
          .product_description {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            padding: 0 0 0 4rem;
          }
          .product_title {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            margin: 0;
          }
          .product_subtitle {
            color: #949494;
            font-family: 'Eurostile-Bold';
            font-size: 20px;
            letter-spacing: 0;
            line-height: 40px;
            margin: 0;
          }
          .product_text {
            text-align: justify;
            text-justify: inter-word;
          }
          .tech_specs_container {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #949494;
            transition: all 0.3s ease-in;
          }
          .tech_specs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #949494;
          }
          #icon {
            cursor: pointer;
          }
          @media (max-width: ${breakPoints.medium}) {
            .product_description {
              width: 100%;
              padding: 0;
            }
            .product_title,
            .product_price {
              font-size: 32px;
            }
            .product_subtitle {
              font-size: 23px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductDescriptions;
