module.exports = {
	productionBrowserSourceMaps: true,
  eslint: {
    // Warning: Dangerously allow production builds to successfully complete even if
    // your project has ESLint errors.
    ignoreDuringBuilds: true,
  },
  images: {
		unoptimized: true,
    domains: [
      "images.prismic.io",
      "cdn.shopify.com",
      "aftershockpc.cdn.prismic.io",
      "picsum.photos",
      "www.aftershockpc.com",
    ],
  },
  async redirects() {
    return [
      {
        source: "/welcome/Register",
        destination: "/me/sign-up",
        permanent: true,
      },
      { source: "/welcome/SignIn", destination: "/me", permanent: true },
      {
        source: "/welcome/Products",
        destination: "/gaming/desktops",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/forge-15s",
        destination: "/series/forge-15s",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/apex-15s",
        destination: "/series/apex-15s",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/vapor-17x",
        destination: "/series/vapor-17x",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/apex-15x-evo",
        destination: "/series/apex-15x-evo",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/lunar-14-max",
        destination: "/series/lunar-14-max",
        permanent: true,
      },
      {
        source: "/welcome/products/laptops",
        destination: "/gaming/laptops",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/rapid",
        destination: "/series/rapid",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/cloud",
        destination: "/",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/bubblegum",
        destination: "/series/bubblegum",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/zeal",
        destination: "/series/zeal",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/hypergate",
        destination: "/series/hypergate",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/flow",
        destination: "/series/flow",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/shadow",
        destination: "/series/shadow",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/ultracore-mini",
        destination: "/series/ultracore-mini",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/tomahawk",
        destination: "/series/tomahawk",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/hypergate-pro",
        destination: "/series/hypergate-pro",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/ultracore",
        destination: "/series/ultracore",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/trixel",
        destination: "/series/trixel",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/fishtank",
        destination: "/series/fishtank",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/mainframe",
        destination: "/series/mainframe",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/monolith",
        destination: "/series/monolith",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/vault-mesh",
        destination: "/series/vault-mesh",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/vault",
        destination: "/series/vault",
        permanent: true,
      },
      {
        source: "/welcome/products/desktops",
        destination: "/gaming/desktops",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/vision-mini",
        destination: "/series/vision-mini",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/focus",
        destination: "/series/focus",
        permanent: true,
      },
      {
        source: "/welcome/Productinner/sledge",
        destination: "/series/sledge",
        permanent: true,
      },
      {
        source: "/welcome/products/workstation-new-1",
        destination: "/workstations/workstations",
        permanent: true,
      },
      {
        source: "/welcome/shop/gaming-gear",
        destination: "/accessories/",
        permanent: true,
      },
      {
        source: "/welcome/shop/mousemat",
        destination: "/accessories/mouse_pads",
        permanent: true,
      },
      {
        source: "/welcome/shop/mice",
        destination: "/accessories/mice",
        permanent: true,
      },
      {
        source: "/welcome/shop/keyboards",
        destination: "/accessories/keyboards",
        permanent: true,
      },
      {
        source: "/welcome/shop/streaming-accessories",
        destination: "/accessories/",
        permanent: true,
      },
      {
        source: "/welcome/rts_inner/desktops",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_inner/laptops",
        destination: "/desktops?category=laptops",
        permanent: true,
      },
      {
        source: "/welcome/corporate",
        destination: "/pages/corporate",
        permanent: true,
      },
      {
        source: "/welcome/Support",
        destination: "/pages/support",
        permanent: true,
      },
      {
        source: "/welcome/Support/1",
        destination: "/pages/support",
        permanent: true,
      },
      {
        source: "/welcome/Support/2",
        destination: "/pages/support",
        permanent: true,
      },
      {
        source: "/welcome/Support/3",
        destination: "/pages/support",
        permanent: true,
      },
      {
        source: "/welcome/Support/4",
        destination: "/pages/support",
        permanent: true,
      },
      {
        source: "/welcome/About",
        destination: "/pages/aboutus",
        permanent: true,
      },
      {
        source: "/welcome/wallpapers",
        destination: "/pages/wallpapers",
        permanent: true,
      },
      {
        source: "/welcome/pc_jobs",
        destination: "/pages/careers",
        permanent: true,
      },
      {
        source: "/welcome/ContactUs",
        destination: "/pages/contactus",
        permanent: true,
      },
      {
        source: "/welcome/products/notebooks",
        destination: "/gaming/laptops",
        permanent: true,
      },
      { source: "/freegames", destination: "/", permanent: true },
      { source: "/nvidia", destination: "/", permanent: true },
      { source: "/microsoft", destination: "/", permanent: true },
      {
        source: "/welcome/privacypolicy",
        destination: "/pages/privacy",
        permanent: true,
      },
      {
        source: "/welcome/termsofuse",
        destination: "/pages/terms",
        permanent: true,
      },
      {
        source: "/welcome/Forgotpassword",
        destination: "/me",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/forge-15s",
        destination: "/series/forge-15s",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/forge-15s",
        destination: "/series/forge-15s",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/apex-15s",
        destination: "/series/apex-15s",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/apex-15s",
        destination: "/series/apex-15s",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/vapor-17x",
        destination: "/series/vapor-17x",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/vapor-17x",
        destination: "/series/vapor-17x",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/apex-15x-evo",
        destination: "/series/apex-15x-evo",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/apex-15x-evo",
        destination: "/series/apex-15x-evo",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/lunar-14-max",
        destination: "/series/lunar-14-max",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/lunar-14-max",
        destination: "/series/lunar-14-max",
        permanent: true,
      },
      { source: "/welcome/products", destination: "/", permanent: true },
      {
        source: "/welcome/ProductconfigRec/rapid",
        destination: "/series/rapid",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/rapid",
        destination: "/series/rapid",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/cloud",
        destination: "/",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/cloud",
        destination: "/",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/bubblegum",
        destination: "/series/bubblegum",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/bubblegum",
        destination: "/series/bubblegum",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/zeal",
        destination: "/series/zeal",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/zeal",
        destination: "/series/zeal",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/hypergate",
        destination: "/series/hypergate",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/hypergate",
        destination: "/series/hypergate",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/flow",
        destination: "/series/flow",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/flow",
        destination: "/series/flow",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/shadow",
        destination: "/series/shadow",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/shadow",
        destination: "/series/shadow",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/ultracore-mini",
        destination: "/series/ultracore-mini",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/ultracore-mini",
        destination: "/series/ultracore-mini",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/tomahawk",
        destination: "/series/tomahawk",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/tomahawk",
        destination: "/series/tomahawk",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/hypergate-pro",
        destination: "/series/hypergate-pro",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/hypergate-pro",
        destination: "/series/hypergate-pro",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/ultracore",
        destination: "/series/ultracore",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/ultracore",
        destination: "/series/ultracore",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/trixel",
        destination: "/series/trixel",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/trixel",
        destination: "/series/trixel",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/fishtank",
        destination: "/series/fishtank",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/fishtank",
        destination: "/series/fishtank",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/mainframe",
        destination: "/series/mainframe",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/mainframe",
        destination: "/series/mainframe",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/monolith",
        destination: "/series/monolith",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/monolith",
        destination: "/series/monolith",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/vault-mesh",
        destination: "/series/vault-mesh",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/vault-mesh",
        destination: "/series/vault-mesh",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/vault",
        destination: "/series/vault",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/vault",
        destination: "/series/vault",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/vision-mini",
        destination: "/series/vision-mini",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/vision-mini",
        destination: "/series/vision-mini",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/focus",
        destination: "/series/focus",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/focus",
        destination: "/series/focus",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigRec/sledge",
        destination: "/series/sledge",
        permanent: true,
      },
      {
        source: "/welcome/ProductconfigBaseic/sledge",
        destination: "/series/sledge",
        permanent: true,
      },
      {
        source: "/welcome/Product/Mzk5Mg==",
        destination: "/products/aftershock-blackout",
        permanent: true,
      },
      {
        source: "/welcome/Product/blackout-backpack",
        destination: "/products/aftershock-blackout",
        permanent: true,
      },
      {
        source: "/welcome/Product/MzE1Ng==",
        destination: "/products/oden-black-mechanical-keyboard-brown-switch",
        permanent: true,
      },
      {
        source: "/welcome/Product/oden-black-mechanical-keyboard",
        destination: "/products/oden-black-mechanical-keyboard-brown-switch",
        permanent: true,
      },
      {
        source: "/welcome/Product/NDYxMA==",
        destination: "/products/aftershock-glide-mousemat-white",
        permanent: true,
      },
      {
        source: "/welcome/Product/aftershock-glide-mousemat-white",
        destination: "/products/aftershock-glide-mousemat-white",
        permanent: true,
      },
      {
        source: "/welcome/Product/NDYxMQ==",
        destination: "/products/aftershock-glide-mousemat-black",
        permanent: true,
      },
      {
        source: "/welcome/Product/aftershock-glide-mousemat-black",
        destination: "/products/aftershock-glide-mousemat-black",
        permanent: true,
      },
      {
        source: "/welcome/Product/MzI0Ng==",
        destination: "/products/aftershock-hexar-gaming-mouse",
        permanent: true,
      },
      {
        source: "/welcome/Product/hexar-gaming-mouse",
        destination: "/products/aftershock-hexar-gaming-mouse",
        permanent: true,
      },
      {
        source: "/welcome/Product/NDYxMg==",
        destination: "/products/aftershock-hexar-pro-gaming-mouse",
        permanent: true,
      },
      {
        source: "/welcome/Product/hexar-pro-gaming-mouse",
        destination: "/products/aftershock-hexar-pro-gaming-mouse",
        permanent: true,
      },
      { source: "/welcome/shop", destination: "/", permanent: true },
      {
        source: "/welcome/rts_inner/www.aftershockpc.com",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4849",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4843",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4782",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4784",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4871",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4787",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4786",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4895",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4879",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4793",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4792",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4847",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4893",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4797",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4794",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4799",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4795",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4796",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4800",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4845",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4802",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4801",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4896",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4876",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4872",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4848",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4805",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4804",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4806",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4880",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4829",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4807",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4809",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4810",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4812",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4808",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4811",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4881",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4816",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4815",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4818",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4878",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4842",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4897",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4821",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4892",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4828",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4823",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4890",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4889",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4824",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4873",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4720",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4721",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4722",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4723",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4727",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4725",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4730",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4731",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4728",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_details/4729",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
      {
        source: "/welcome/Support/www.aftershockpc.com",
        destination: "/pages/support",
        permanent: true,
      },
      {
        source: "/welcome/wallpapers/8",
        destination: "/pages/wallpapers",
        permanent: true,
      },
      {
        source: "/welcome/Product/www.aftershockpc.com",
        destination: "/gaming/desktops",
        permanent: true,
      },
      {
        source: "/welcome/rts_inner/rts-laptops",
        destination: "/ready-to-ship/desktops?category=laptops",
        permanent: true,
      },
      {
        source: "/welcome/wallpapers/www.aftershockpc.co",
        destination: "/pages/wallpapers",
        permanent: true,
      },
      {
        source: "/welcome/wallpapers",
        destination: "/pages/wallpapers",
        permanent: true,
      },
      {
        source: "/welcome/rts",
        destination: "/ready-to-ship/desktops",
        permanent: true,
      },
    ];
  },
};
