/* eslint-disable react/prop-types */
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styles from './styles';

const ComponentsMobile = ({ item }) => {

  const { primary } = item[0];

  return (
    <>
      <div className='main-container'>
        <div className='tech-yard-section'>
          <Image draggable={false} layout="fixed" src={primary.tech_yard_image.url} width={392} height={182} alt="rts_image_components" loading='lazy'/>
          <div className='tech-yard-text-section'>
            <p className='tech-yard-title'>{primary.tech_yard_title}</p>
            <p className='tech-yard-description'>{primary.tech_yard_description}</p>
            <a href={primary.tech_yard_link} className='tech-yard-visit'>{'Learn More >'}</a>
          </div>
        </div>

        <div className='component-section'>
          <div className='component-header-section'>
            <p className='component-title'>{primary.title}</p>
            <a href={primary.component_see_more_link} className='view-all'>{'View All >'}</a>
          </div>

          <div className='component-box-section'>
            {item[0].items.map((item,i) => {
              return (
                <div className='component-box' onClick={() => window.location.href=item.link}>
                  <div>
                    <Image draggable={false} layout="fixed" src={item.component_image.url} width={51} height={51} alt="rts_image_components" loading='lazy'/>
                  </div>
                  <div>
                    <p className='component-box-title'>{item.component_title}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

    <style jsx>{styles}</style>
    </>
  );
};
export default ComponentsMobile;
