import { infoLog, eventLog, EVENT_DEBUG } from './general';

export const productView = (product, type) => {
  if (EVENT_DEBUG && !window?.fbq) {
    infoLog('Product View: No FBQ found');
  }

  if (window?.fbq) {
    const detail = {
      content_type: 'product',
      content_category: 'product',
      content_name: product?.title,
      content_ids:
        type === 'pc-model'
          ? [product?.id]
          : [
              product?.id?.replace('gid://shopify/Product/', ''),
            ],
      currency: 'SGD',
      value:
        type === 'pc-model'
          ? parseFloat(product?.price)
          : parseFloat(product?.variants?.edges?.[0]?.node?.price?.amount),
    };
    window?.fbq('track', 'ViewContent', detail);
    if (EVENT_DEBUG) eventLog('View Content', { detail });
  }
};
