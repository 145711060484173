import React, { useState } from 'react';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';

const ProductSpecifications = ({ productTags }) => {
  const specificationsTags = productTags.filter((item) =>
    item.startsWith('specifications:'),
  );

  const [showModal, setShowModal] = useState(null);
  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {specificationsTags.length > 0 && (
        <div className="product_specifications">
          <div>
            <div className="title_container">
              <div>
                <h5 className="title">SPECIFICATIONS</h5>
              </div>
              <div onClick={handleModal} className="modal_toggle">
                {showModal ? (
                  <div className='minus-icon'>
                    <Image draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_minus.png?v=1714048250"
                      alt="warranty"
                      width={15}
                      height={3}
                    />
                  </div>
                ) : (
                  <div>
                    <Image draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_plus.png?v=1714048195"
                      alt="warranty"
                      width={15}
                      height={15}
                    />
                  </div>
                )}
              </div>
            </div>

            {showModal ? (
              <div className="specifications_section">
                {specificationsTags.map((item, index) => {
                  let title =
                    typeof item.split(':')[2] !== 'undefined'
                      ? item.split(':')[2].replace(/-/g, ' ')
                      : '';
                  let description =
                    typeof item.split(':')[3] !== 'undefined'
                      ? item.split(':')[3].replace(/-/g, ' ')
                      : '';

                  return (
                    <div
                      key={index}
                      className={`specification_item ${
                        description.includes('/') ? 'long_value' : ''
                      }`}
                    >
                      <div className="attribute">{title}:</div>

                      {description.includes('/') ? (
                        <div>
                          {description.split('/').map((item, index) => {
                            const subTitle = item.split('_')[0];
                            const subDescription = item.split('_')[1];
                            return (
                              <div className="attribute_value" key={index}>
                                {subTitle}:{subDescription}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="attribute_value">{description}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      )}

      <style jsx>
        {`
          .minus-icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
          }
          .product_specifications {
            margin: 0 auto;
            width: 70%;
            border-bottom: 1px solid #949494;
            margin-top: 20px;
            padding-bottom: 30px;
          }
          .title_container {
            display: flex;
            justify-content: space-between;
          }
          .modal_toggle {
            cursor: pointer;
            margin-top: 30px;
          }
          .title {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }
          .performance_section {
            display: flex;
            justify-content: space-between;
          }
          .specification_item {
            width: 100%;
            display: flex;
            margin-bottom: 16px;
          }
          .attribute {
            min-width: 40%;
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
          }
          .attribute_value {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: left;
          }
          .long_value {
            min-height: 50px;
          }
          @media (max-width: ${breakPoints.medium}) {
            .product_specifications {
              width: 100%;
            }
            .attribute_value {
              font-size: 16px;
              line-height: 24px;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .specification_item {
              min-height: 48px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductSpecifications;
