import css from 'styled-jsx/css';

export default css`
.shortcode_skill {
	position:relative;
	overflow:hidden;
}

.shortcode_skill:before {
	position:absolute;
	top:0;
	left:27%;
	margin:15px 0 0;
	width:1px;
	height:95%;
	background:rgba(0, 0, 0, .1);
	content:"";
}

.skill_item {
	overflow:hidden;
	width:100%;
}

.skill_item > span {
	float:left;
	padding:24px 4.7% 0 0;
	width:25%;
	text-align:right;
}

.skill_item_colored_main_wrap {
	float:left;
	padding:15px 0 5px;
	width:70%;
}

.skill_item_colored_wrap {
	position:relative;
	height:33px;
}

.skill_item_colored {
	position:absolute;
	width:100%;
	height:100%;
	-webkit-animation:move 2s linear .1s normal none 1 ;
	-moz-animation:move 2s linear .1s normal none 1 ;
	-ms-animation:move 2s linear .1s normal none 1 ;
	-o-animation:move 2s linear .1s normal none 1 ;
	animation:move 2s linear .1s normal none 1 ;
}

@-webkit-keyframes move {
	from {width:0;}
	to {width:100%;}
}

@-ms-keyframes move {
	from {width:0;}
	to {width:100%;}
}

@-o-keyframes move {
	from {width:0;}
	to {width:100%;}
}

@keyframes move {
	from {width:0;}
	to {width:100%;}
}

.skill_item_colored_wrap > span {
	position:relative;
	display:block;
}

.skill_item_colored > span {
	display:block;
	padding:8px 10px;
	text-align:right;
	-webkit-animation:opacity 2.5s linear .1s normal none 1 ;
	-moz-animation:opacity 2.5s linear .1s normal none 1 ;
	-ms-animation:opacity 2.5s linear .1s normal none 1 ;
	-o-animation:opacity 2.5s linear .1s normal none 1 ;
	animation:opacity 2.5s linear .1s normal none 1 ;
}

@-webkit-keyframes opacity {
	0% {opacity:0;}
	90% {opacity:0;}
	100% {opacity:1;}
}

@-moz-keyframes opacity {
	0% {opacity:0;}
	90% {opacity:0;}
	100% {opacity:1;}
}

@-ms-keyframes opacity {
	0% {opacity:0;}
	90% {opacity:0;}
	100% {opacity:1;}
}

@-o-keyframes opacity {
	0% {opacity:0;}
	90% {opacity:0;}
	100% {opacity:1;}
}

@keyframes opacity {
	0% {opacity:0;}
	90% {opacity:0;}
	100% {opacity:1;}
}
`;
