import React, { useState, useEffect, useContext } from 'react';
import { ViewProductKlaviyo } from 'helpers/klaviyo';
import styles from './styles-accessories';
import ImageGallery from './ImageGallery/index-new';
import ImagesGalleryMobile from './ImagesGalleryMobile/index-new';
import ProductDescriptions from './ProductDescriptions/index-accessories-new';
import ProductPerformance from './ProductPerformance';
import ProductWarranty from './ProductWarranty';
import ProductSpecification from './ProductSpecification';
import ProductFeatures from './ProductFeatures';
import ImageViewer from './ImageViewer';
import BreadcrumbMenu from './BreadcrumbMenu';
import EssentialProducts from './EssentialProducts';
import ProductAddToCart from './ProductDescriptions/ProductAddToCart';
import ProductAddCartMobile from './ProductDescriptions/ProductAddCartMobile';
import VarianceGift from 'components/VarianceGiftCard';
import { GiftCardContext } from 'pages/products/[...handle]';

const ProductAccessories = ({ data, accessory_list }) => {
  console.log('data :::::', data);
  const [showModal, setShowModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { product } = data;
  const [addToCart, setAddToCart] = useState([data?.product]);
  const { giftCard, gcidx } = useContext(GiftCardContext)

  const productTitle = product.title;
  const productId = product.id;
  const productHandle = product.handle;
  const productPrice = product.variants.edges[0].node.price;
  const productSKU = product.variants.edges[0].node.sku;
  const productImage = product.images?.edges[0]?.node?.originalSrc;
  const imagesGallery = product.images.edges;
  const productVariant = product.variants.edges[0].node;
  const productTags = product.tags;
  const productAvailable =
    product?.variants?.edges[0]?.node?.quantityAvailable > 0;
  const productCompareAtPrice = product.variants.edges[0].node.compareAtPrice;
  const productDescriptionHtml = product.descriptionHtml;

  const sameDayShipping = productTags.filter((item) =>
    item.startsWith('shipping:'),
  );
	const [finalProductPrice, setFinalProductPrice] = useState(
    data?.product?.variants?.edges[0]?.node?.price,
  );
  const [finalComparedProductPrice, setFinalComparedProductPrice] = useState(
    data?.product?.variants.edges[0].node.compareAtPrice,
  );

  const productMetafields = product.metafields.edges;
  const images = productMetafields.filter(
    (el) => el.node.key === 'long_image_portion',
  );
  let imagesArray;
  if (images.length > 0) {
    imagesArray = JSON.parse(images[0].node.value);
  }
  const otherMetaFieldsFilter =
  product?.metafields?.edges.length > 0 &&
  product?.metafields?.edges.filter(
    (el) =>
      el.node.namespace !== 'upsell' &&
      el.node.namespace !== 'UPSELL' &&
      el.node.namespace.toLowerCase() !== 'video' &&
      el.node.namespace.toLowerCase() !== 'custom'
  );

	 useEffect(() => {
    if (data.product.handle === "aftershock-gift-card") {
      setFinalComparedProductPrice(giftCard);
      setFinalProductPrice(giftCard);
    } else {
      setFinalComparedProductPrice(
        data?.product?.variants.edges[0].node.compareAtPrice,
      );
      // console.log({ dataProduct: data });
      setFinalProductPrice(data?.product?.variants?.edges[0]?.node?.price);
    }
  }, [data, giftCard]);

  const variantMetaFields = otherMetaFieldsFilter
    ? [otherMetaFieldsFilter.find((item) => item.node.namespace === 'variants')]
    : [];

  return (
    <>
      {global?.window?._learnq
        ? ViewProductKlaviyo(
            productTitle,
            productId,
            productImage,
            productPrice,
            productCompareAtPrice,
            productHandle,
            productSKU,
          )
        : ''}
      <div className='product_section wrapper'>
        <BreadcrumbMenu
          productHandle={productHandle}
          productTitle={productTitle}
          productTags={productTags}
        />
        {showModal ? (
          <ImageViewer
            imagesGallery={imagesGallery}
            handleModalClose={setShowModal}
            imageIndex={imageIndex}
            handleImageIndex={setImageIndex}
          />
        ) : null}

        <div className='product_information'>
          <div className='product_images_section'>
            <ImageGallery
              productImage={productImage}
              imagesGallery={imagesGallery}
              productTitle={productTitle}
              showModal={showModal}
              handleModalOpen={setShowModal}
              handleImageIndex={setImageIndex}
						  isGiftCard={product.handle === 'aftershock-gift-card'}
            />
          </div>

          <div className='images_gallery_mobile'>
            <ImagesGalleryMobile
              productImage={productImage}
              imagesGallery={imagesGallery}
              showModal={showModal}
              handleModalOpen={setShowModal}
              handleImageIndex={setImageIndex}
            />
          </div>

          <div className='product_contents'>
            <ProductDescriptions
              productTitle={productTitle}
              productPrice={productPrice}
              productVariant={productVariant}
              productDescription={productDescriptionHtml}
              productCompareAtPrice={productCompareAtPrice}
              productTags={productTags}
              productImage={productImage}
              productHandle={productHandle}
              productSKU={productSKU}
              productAvailable={productAvailable}
              productId={productId}
              productMetafields={productMetafields}
              products={addToCart}
              variantMetaFields={variantMetaFields}
            />
						{product.handle === 'aftershock-gift-card' && (
              <VarianceGift
                data={product.options[0].values}
                productImage={productImage}
              />
            )}
            <ProductPerformance productTags={productTags} />
            <ProductFeatures productTags={productTags} />
            <ProductWarranty productTags={productTags} />
            <ProductSpecification productTags={productTags} />
          </div>
        </div>
        <div className='long_image_portion'>
          {imagesArray?.length > 0 &&
            imagesArray.map((el) => {
              return <img draggable="false"  src={el} />;
            })}
        </div>

        <div className='essential_products'>
          <EssentialProducts productTags={productTags} />
        </div>
        <div className='product_footer'>
          <div className='wrapper'>
            <div className='product_add2cart cart__desktop'>
              <ProductAddToCart
                sameDayShipping={sameDayShipping}
                products={addToCart}
                productTitle={productTitle}
								productPrice={
                  product.handle !== 'aftershock-gift-card'
                    ? finalProductPrice
                    : giftCard
                }
                productVariant={productVariant}
                productCompareAtPrice={finalComparedProductPrice}
                productAvailable={productAvailable}
                productId={productId}
								gcidx={gcidx}
              />
            </div>
            <div className='product_add2cart cart__mobile'>
              <ProductAddCartMobile
                sameDayShipping={sameDayShipping}
                products={addToCart}
                productTitle={productTitle}
								productPrice={
                  product.handle !== 'aftershock-gift-card'
                    ? finalProductPrice
                    : giftCard
                }
                productVariant={productVariant}
                productCompareAtPrice={finalComparedProductPrice}
                productAvailable={productAvailable}
                productId={productId}
								gcidx={gcidx}
              />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

export default ProductAccessories;
