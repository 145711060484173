import React from 'react';
import breakPoints from 'helpers/Styles';
// import ReactStars from 'react-rating-stars-component';
const ProductDescriptions = ({
  productTitle,
  productDescription,
  productShortDescription,
  productPrice,
  productCompareAtPrice,
  sameDayShipping,
  handleVideoPreview,
  videoUrl,
  productTags,
  products,
  productId,
  productAvailable,
  productVariant,
  variantMetaFields,
  productHandle
}) => {
  const discount = productCompareAtPrice - productPrice;
  const title = productTitle.split('/')[0];
  const subtitle = productTitle.split('/')[1];
  const sameDayShipAvab =
    sameDayShipping.length > 0 && sameDayShipping[0].split(':')[1];

  const calledBanner = productTags.filter((item) =>
    item.trim().startsWith('banner:')
  );

  const calledBannerText =
    calledBanner.length > 0 && calledBanner[0].split(':')[1];

  return (
    <>
      <div className="product_description">
        <div className="banner-container">
          {/* {sameDayShipAvab &&
          sameDayShipAvab !== false &&
          typeof sameDayShipAvab !== "false" && (
            <div
              className={`same_day_shipping ${
                sameDayShipAvab === "delayed" && sameDayShipping.length === 1
                  ? "hide"
                  : ""
              }`}
              style={{marginRight: 10}}
            >
              <img draggable="false" 
                src="/images/truck.png"
                alt="first_level menu"
                layout="fixed"
                width={15}
                height={15}
              />
              {sameDayShipAvab === "same-day-shipping" && (
                <button>SAME DAY SHIPPING</button>
              )}
              {sameDayShipAvab === "next-day-shipping" && (
                <button>NEXT DAY SHIPPING</button>
              )}
              {sameDayShipAvab === "pre-order" && <button>PRE ORDER</button>}
            </div>
          )} */}
          {calledBannerText != '' && (
            <div className={`same_day_shipping`}>
              <button>{calledBannerText}</button>
            </div>
          )}
        </div>
        <h1 className="product_title">{title}</h1>
        <h1 className="product_subtitle">{subtitle}</h1>
        {/* <div className="rating_stars">
          <ReactStars
            count={5}
            size={24}
            starCount={5}
            value={3.5}
            activeColor="#ffff"
            color="grey"
            isHalf="true"
            // filledIcon=""
            edit={false}
          />
          <span className="reviews">3.5 (12 Reviews)</span>
        </div> */}

        {videoUrl && (
          <div
            className="video_link"
            onClick={() => handleVideoPreview(videoUrl)}
          >
            <span>Watch it in action</span>
            <img draggable="false" 
              src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-play-red.png?v=1714039604"
              height="20px"
              width="20px"
              alt="aftershock, aftershock pc"
            />
          </div>
        )}
      </div>

      <style jsx>
        {`
          .add_to_cart_desc_button {
            max-width: 200px;
          }
          .video_link span {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
          }
          .product_description .video_link {
            color: #fd0300;
            display: flex;
          }
          .product_description .video_link img {
            padding-left: 4px;
            cursor: pointer;
            filter: contrast(4.5);
          }
          .same_day_shipping img {
            height: 12px;
            margin-top: 3px;
            width: 12px;
          }
          .same_day_shipping {
            text-align: center;
            color: #e4e4e4;
            background: #950810;
            width: 100%;
            max-width: 150px;
            display: flex;
            justify-content: center;
            padding: 1px 0px;
            border-radius: 3px;
          }
          .same_day_shipping button {
            font-family: 'Roboto-Medium';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            border: none;
            color: #e4e4e4;
            background: #950810;
          }
          .rating_stars {
            padding: 10px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            display: flex;
            align-items: center;
          }
          .short_description {
            padding: 10px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Roboto-Normal';
          }
          .product_description {
            margin: 0 auto;
            width: 100%;
            box-sizing: border-box;
            margin-top: 0px;
          }
          .product_title {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            margin: 0;
            width: 80%;
          }
          .price_section {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .currency {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            margin-top: 10px;
            margin-right: 10px;
          }
          .product_subtitle {
            color: #949494;
            font-family: 'Eurostile-Bold';
            font-size: 20px;
            letter-spacing: 0;
            line-height: 40px;
            margin: 0;
          }
          .product_price {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
          }
          .descriptions {
            width: 100%;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            margin-top: 40px;
          }
          .compareAtPrice {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            display: flex;
            margin-bottom: 10px;
          }
          .original_price {
            margin-right: 10px;
          }
          .reviews {
            padding-left: 10px;
          }
          .hide {
            display: none;
          }
          @media (max-width: ${breakPoints.medium}) {
            .product_description {
              width: 100%;
            }
            .product_title,
            .product_price {
              font-size: 32px;
            }
            .product_subtitle {
              font-size: 23px;
            }
            .add_to_cart_desc_button {
              display: none;
            }
          }
          .banner-container {
            display: flex;
          }
          @media (max-width: ${breakPoints.small}) {
            .product_title {
              width: 76%;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductDescriptions;
