/* eslint-disable react/prop-types */
import Head from "next/head";
import Prismic, { getDocumentByUid, renderSlices } from 'helpers/Prismic';
import globalProps, { globalPropsRequests } from 'utils/globalProps';
import { useEffect, useContext, useState } from 'react';
import { StorefrontContext } from 'providers/storefront';
import Modal from 'react-modal';
import useScreenWidth from 'utils/useScreenWidth';
import Script from "next/script";
import facebookPixel from "containers/Layout/Head/facebook-pixel";
import gtagManager from "containers/Layout/Head/gtagManager";
import CtaSlice from "containers/Slices/CtaSlice";

export default function Home({ homepageContent }) {
  const {
    body,
    seo_title: seoTitle,
    seo_description: seoDescription,
  } = homepageContent || {};
  const { checkLocation, setCheckLocation } = useContext(StorefrontContext);
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(false);
  const [country, setCountry] = useState(false);

  useEffect(() => {
    fetch('/api/location')
      .then((res) => res.json())
      .then((data) => {
        const country = data.country;
        setCheckLocation(country);
        setCountry(country);
        if (country == 'AU') {
          openModal();
        }
      });
    setLocation(localStorage.getItem('userLocation'));
  }, [location]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    localStorage.setItem('userLocation', country);
  };

  const widthSize = useScreenWidth();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '15px 10px 10px 15px',
      width: widthSize <= 500 ? '90%' : '450px',
      height: widthSize <= 500 ? '27%' : '235px',
      background: '#242424',
      border: '1px solid #242424',
      overflow: 'hidden',
    },
  };

  const redirectTo = () => {
    window.location.href = 'https://www.aftershockpc.com.au/';
  };

  return (
    <div>
      <Head>
        <meta name="google-site-verification" content="KiqDXLIqdK0XyXw4QN4PuCsOa3d5TAe6CdAgNgAiep4" />
        <link
          rel="preconnect dns-prefetch"
          href="https://triplewhale-pixel.web.app/"
          crossOrigin="true"
        />
        <link
          rel="preconnect dns-prefetch"
          href="https://api.config-security.com/"
          crossOrigin="true"
        />
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
				<meta property="og:image" content={homepageContent.og_image.url ? homepageContent.og_image.url : 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/ogImage.png?v=1714058511'} />        
				<meta property="og:image:type" content="image/png"/>
				<meta property="og:image:width" content="1024"/>
				<meta property="og:image:height" content="1024"/>
      </Head>
      <Script src="/tripleWhale.js" dangerouslySetInnerHTML={{ __html: 'TripleHeadless = "aftershockpcsg.myshopify.com"' }} strategy="lazyOnload" />
      {facebookPixel}
      {/* {gtagManager} */}
      <main>
        {body &&
          body.map((block, index) =>
            renderSlices(block, block.slice_type, index)
          )}
      </main>
      {location == null && (
        <Modal
          style={customStyles}
          isOpen={open}
          contentLabel="Minimal Modal Example"
          onRequestClose={closeModal}
        >
          <button className="modal-btn-close" onClick={closeModal}>
            {'Close'}
          </button>
          <p className="modal-header">{'Welcome to Aftershock Singapore'}</p>
          <div className="modal-location-container">
            <div style={{ marginTop: '8%' }}>
              <p className="location-prompt">
                You are in Aftershock Singapore, do you want to redirect to
                Aftershock Australia?
              </p>
            </div>
            <div>
              <button className="modal-btn-location" onClick={redirectTo}>
                Redirect to Australia
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export async function getStaticProps({ preview = false, previewData }) {
  const {
    props: { global },
  } = await globalProps();

  const { ref } = previewData || {};

  const homePage = await getDocumentByUid('landing-page', 'homepage-new', ref);
  globalPropsRequests.push(
    Prismic.getByUID(
      'main_navigation_new_version2',
      'main_navigation_new_version2',
      ref ? { ref } : null
    )
      .then((res) => ['header', res])
      .catch(() => null)
  );
  globalPropsRequests.push(
    Prismic.getByUID('footer', 'footer', ref ? { ref } : null)
      .then((res) => ['footer', res])
      .catch(() => null)
  );

  const homepageContent = homePage?.data;

  return {
    props: {
      global,
      homepageContent: homepageContent || null,
      preview,
    },
    revalidate: 300,
  };
}
