import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

const ImagesGalleryMobile = ({
  productImage,
  imagesGallery,
  productTitle,
  handleModalOpen,
  handleImageIndex,
}) => {
  const [imageViewer, setImageViewer] = useState(null);
  const [idx, setIdx] = useState(0);

  const handleModal = () => {
    handleModalOpen(true);
  };
  useEffect(() => {
    setImageViewer(productImage || 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274');
  }, [productImage]);

  const imageHandleChange = (id) => {
    if (id >= 0 && id < imagesGallery.length) {
      setImageViewer(imagesGallery[id].node.originalSrc);
    }
  };

  const handleLeft = () => {
    setIdx(
      (prevIdx) => (prevIdx - 1 + imagesGallery.length) % imagesGallery.length,
    );
    imageHandleChange(idx);
    handleImageIndex(idx);
  };

  const handleRight = () => {
    setIdx((prevIdx) => (prevIdx + 1) % imagesGallery.length);
    imageHandleChange(idx);
    handleImageIndex(idx);
  };

  useEffect(() => {
    imageHandleChange(idx);
    handleImageIndex(idx);
  }, [idx]);

  const style = {
    borderRadius: '50%',
    backgroundColor: '#242424',
    padding: '6px',
    fontSize: '1.5rem',
  };

  return (
    <>
      <div className='product_image_container'>
        <div className='image_view'>
          <div className='product_image'>
            <div className='arrowLeft arrows' onClick={handleLeft}>
              <FaChevronLeft style={style} />
            </div>
            {imageViewer && (
              <Image draggable={false}
                src={imageViewer}
                alt={productTitle}
                width={500}
                height={500}
                onClick={handleModal}
              />
            )}
            <div className='arrowRight arrows' onClick={handleRight}>
              <FaChevronRight style={style} />
            </div>
          </div>
        </div>
        <div className='image_gallery'>
          {imagesGallery.map((item, id) => {
            const handleClick = () => {
              imageHandleChange(id);
              handleImageIndex(id);
            };
            return (
              <div className='image_tab' key={id}>
                <Image draggable={false}
                  src={item.node.originalSrc}
                  alt='product_view_image'
                  width={200}
                  height={200}
                  onClick={handleClick}
                />
              </div>
            );
          })}
        </div>
      </div>

      <style jsx>
        {`
          .image_view {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
          }
          .product_image {
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #242424;
            position: relative;
            padding: 5%;
          }
          .arrows {
            transition: 0.2s;
          }
          .arrowLeft {
            position: absolute;
            top: 50%;
            left: -2rem;
          }
          .arrowRight {
            position: absolute;
            top: 50%;
            right: -2rem;
          }
          .arrows:hover {
            transform: scale(1.2);
            filter: drop-shadow(0 0 0.05rem white);
          }
          .image_zoom_in {
            position: absolute;
            right: 10px;
            bottom: 10px;
          }

          .image_gallery {
            margin-top: 2%;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 2%;
            padding-left: 5%;
          }
          .image_tab {
            width: 20%;
            background: #141414;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: 30px;
            background-color: #242424;
            padding: 2%;
          }
          .image_tab img {
            width: 70%;
          }
        `}
      </style>
    </>
  );
};

export default ImagesGalleryMobile;
