/* global window */
import { StorefrontContext } from 'providers/storefront';
import React, { useContext, useRef, useEffect, useState } from 'react';
import Router from 'next/router';
import styles from './styles';
import CartContent from './CartContent';
import CartEmpty from './CartEmpty';

const Cart = () => {
  const { isCartOpen, setIsCartOpen, checkout } = useContext(StorefrontContext);
  const [isHoveredIcon, setIsHoveredIcon] = useState(false);

  const handleMouseEnter = () => {
    setIsHoveredIcon(true);
  };

  const handleMouseLeave = () => {
    setIsHoveredIcon(false);
  };

  let feeItemsNum = useRef(0);

  useEffect(() => {
    let lineItems = [...document.getElementsByClassName('Line-item')];
    let feeProduct = lineItems.find((item) =>
      item.innerText.includes('Payment Method Fee'),
    );
    // let feeProductCount = document.getElementsByClassName(`${feeProduct.className}`)
    // console.log(lineItems);
    if (feeProduct) {
      // must uncomment below in live
      // feeProduct.style.display ='none'
      // maybe add this later to correctly show total item count in cart
      // let feeQuantity = feeProduct.getElementsByClassName('Line-item__quantity')[0].innerText
      // feeItemsNum.current = feeQuantity
    }
  });

  Router.onRouteChangeStart = () => {
    setIsCartOpen(false);
  };

  const handleCartClose = () => {
    setIsCartOpen(false);
  };

  const lineItems = checkout?.lineItems?.edges || [];

  const itemsTotal =
    lineItems.map((item) => item.node.quantity).reduce((x, y) => x + y, 0) -
    feeItemsNum.current;

  const useOutsideClick = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCartClose();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useEffect(() => {
    var element = document.getElementById('cart-container');
    var checkout = document.getElementById('sticky-footer');
    if (isCartOpen && checkout != null) {
      if (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      ) {
        checkout.setAttribute(
          'style',
          'position: fixed; width: calc(710px / 2); bottom: 0',
        );
      } else {
        checkout.setAttribute('style', 'position: inherit; width: 100%');
      }
    }
  });

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  return (
    <>
      <div
        className={`Cart ${isCartOpen ? 'Cart--open' : ''}`}
        ref={wrapperRef}
        id='cart-container'
      >
        <header
          className='Cart__header'
          style={{ height: '10%', zIndex: 1, backgroundColor: '#131313' }}
        >
          <div className='cart-header-container'>
            <h2 className='cart_title'>
              MY CART
              {lineItems.length > 0 && (
                <span className='line_items_quantity'>
                  ({itemsTotal} &nbsp;items)
                </span>
              )}
            </h2>
          </div>
          <img
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            src={
              isHoveredIcon
                ? 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close-hover_1.png?v=1714063075'
                : 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936'
            }
            onClick={handleCartClose}
            className='Cart__close'
          />
        </header>

        {lineItems.length > 0 && <CartContent />}

        {lineItems.length === 0 && <CartEmpty />}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Cart;
