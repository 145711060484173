import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import BarCounter from './BarCounter';

const FPSBarCounter = ({ id, progress, game, maxValue }) => {
  return (
    <>
      <BarCounter
        id={id}
        percentage={progress}
        value={progress}
        game={game}
      />
      <style jsx>{styles}</style>
    </>
  );
};

FPSBarCounter.propTypes = {
  id: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  game: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
};

export default FPSBarCounter;
