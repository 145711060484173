/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { memo, useState, useEffect } from 'react';
import PrimaryMobile from './PrimaryMobile';
import MobileBottom from '../../MobileBottom';

const NavigationMobile = ({ isOpen, header, closeMenu }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [kspItems, setKspsItems] = useState([]);
  const [quizItems, setQuizItems] = useState();
  const [showRTSBannerMobile, setShowRTSBannerMobile] = useState(false);
  const getMegaMenuColumns = (megaMenuItems) => {
    if (!megaMenuItems) return [];
    const columns = [];
    let currentSecondLevelIndex = 0;
    megaMenuItems.forEach((childMenuItem, index) => {
      const sliceType = childMenuItem.slice_type;
      // eslint-disable-next-line no-plusplus
      if (sliceType === '1st_level' && index !== 0) currentSecondLevelIndex++;
      if (!columns[currentSecondLevelIndex]) {
        columns[currentSecondLevelIndex] = [];
      }

      columns[currentSecondLevelIndex].push(childMenuItem);
    });

    return columns;
  };
  useEffect(() => {
    if (header && header.body) {
      setMenuItems(getMegaMenuColumns(header.body));
    }
  }, [header]);
  useEffect(() => {
    if (header && header.body) {
      const ksps = header.body.filter((n) => n.slice_type === 'ksps');
      const quiz = header.body.filter((n) => n.slice_type === 'quiz');
      setKspsItems(ksps);
      setQuizItems(quiz);
    }
  }, [header]);

  useEffect(() => {
    if (header?.show_mobile_cta_banner_rts !== undefined) {
      setShowRTSBannerMobile(header?.show_mobile_cta_banner_rts);
    }
  }, [header?.show_mobile_cta_banner_rts]);

  const componentItems = header?.body?.filter(
    (n) => n.slice_type === 'components',
  );

  return (
    <Modal
      isOpen={isOpen}
      modalClass='mobile_menu_modal'
      overlayClassName='mobile_menu_modal__overlay'
    >
      <div className='mobile_menu_modal__header'>
        <PrimaryMobile
          ksps={kspItems}
          data={menuItems}
          closeMenu={closeMenu}
          componentItems={componentItems}
          showRTSBannerMobile={showRTSBannerMobile}
        />
        <MobileBottom
          quiz={quizItems}
          closeMenu={closeMenu}
        />
      </div>
    </Modal>
  );
};

NavigationMobile.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.any,
  closeMenu: PropTypes.func.isRequired,
};

NavigationMobile.defaultProps = {
  isOpen: false,
  header: null,
};

export default memo(NavigationMobile);
