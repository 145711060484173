import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/Logo';
import Image from 'next/image';
import { StorefrontContext } from 'providers/storefront';
import { getCheckoutId } from 'helpers/cookies';
import Link from 'next/link';
import CartCounter from '../Header/Navigation/CartCounter';
import styles from './styles';
import Countdown from 'react-countdown';
import ProfileButton from 'components/NavigationButtonIcons/ProfileButton';
import BannerCountdownTimer from '../Header/BannerCountdownTimer';
import { ThreeDots } from 'react-loader-spinner';
import Prismic from 'helpers/Prismic';

const MobileHeader = ({
  header,
  isChecked,
  openMenu,
  closeMenu,
  customProducts,
  customLaptops,
  limitedEditionProducts,
  workstationProducts,
  rtsProducts,
}) => {
  const [checkoutId, setCheckoutId] = useState(getCheckoutId());
  const {
    setIsCartOpen,
    checkout,
    setIsAnnouncementBarOpen,
    isAnnouncementBarOpen,
    isMenuOpen,
    isLoggedIn,
    setIsLoggedIn,
  } = useContext(StorefrontContext);
  useEffect(() => {
    if (checkout?.id) setCheckoutId(checkout.id);
  }, [checkout]);
  const [openSearch, setOpenSearch] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const { allPcRtsProducts, setAllPcRtsProducts } =
    useContext(StorefrontContext);

  async function getPcModelsByNames(names) {
    try {
      const response = await fetch(
        `/api/search/getAllPcModelsByNames?names=${encodeURIComponent(
          names.join(','),
        )}&t=${new Date().getTime()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const results = await response.json();
      return results;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }

  const arraysOfObjectsAreEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };

  useEffect(() => {
    const sixHoursInMillis = 6 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    setLoadingSearch(true);

    if (lastUpdated && currentTime - lastUpdated < sixHoursInMillis) {
      setAllPcRtsProducts(allProducts);
      setLoadingSearch(false);
      return;
    }

    let finalCustomProducts = [];
    let slpPcModels = [];

    // Формування customProducts
    customProducts?.body?.forEach((data) => {
      data.items.forEach((item) => {
        finalCustomProducts = [...finalCustomProducts, { product: item }];
      });
    });

    customLaptops?.body?.forEach((data) => {
      data.items.forEach((item) => {
        item.tags = 'laptops';
        finalCustomProducts = [...finalCustomProducts, { product: item }];
      });
    });

    if (limitedEditionProducts?.body) {
      finalCustomProducts = [
        ...finalCustomProducts,
        ...limitedEditionProducts?.body,
      ];
    }

    finalCustomProducts.forEach((data) => {
      let slp = '';
      if (data.product) {
        slp = data.product.link.split('/');
        slpPcModels = [...slpPcModels, slp[2]];
      } else if (data.primary) {
        slp = data.primary.redirect_link.split('/');
        slpPcModels = [...slpPcModels, slp[2]];
      }
    });

    const newSlpModels = slpPcModels.map((model) =>
      model.includes('-') ? model.split('-')[0] : model,
    );

    const filterDuplicates = (arr) =>
      arr.filter((item, index) => arr.indexOf(item) === index);

    const updatedSlpModels = filterDuplicates(newSlpModels);
    updatedSlpModels.pop();

    const promises = updatedSlpModels.reduce((acc, model, i) => {
      if (i % 4 === 0)
        acc.push(getPcModelsByNames(updatedSlpModels.slice(i, i + 4)));
      return acc;
    }, []);

    Promise.all(promises)
      .then((responses) => {
        const allPcModels = responses.flat();
        const filteredAllPcModels = allPcModels.filter(
          (pcModel) => pcModel.price < 90000,
        );

        let filteredPcModels = [];
        filteredAllPcModels.forEach((option) => {
          const graphicsCardOptions = option?.modelOptionGroup?.find(
            (options) =>
              options?.optionGroup.option_group_title === 'Graphics Card',
          );
          const chassisOptions = option?.modelOptionGroup?.find(
            (options) => options?.optionGroup.option_group_title === 'Chassis',
          );
          const cpuOptions = option?.modelOptionGroup?.find(
            (options) => options?.optionGroup.option_group_title === 'CPU',
          );

          filteredPcModels.push({
            slug: option.slug,
            model_title: option.model_title,
            seo_description: option.seo_description,
            seo_title: option.seo_title,
            thumb_image: option.thumb_image,
            modelOptionGroup: [graphicsCardOptions],
            chassisGroup: [chassisOptions],
            cpuGroup: [cpuOptions],
            buildType: option.buildType.build_type_name,
          });
        });

        const flatProducts = rtsProducts?.body
          ?.map((bodyItem) => bodyItem.items)
          ?.flat()
          ?.filter((el) => el.product !== undefined);

        const finalProducts = [...flatProducts, ...filteredPcModels];
        setAllProducts(finalProducts); // Записуємо в стан
        setLastUpdated(currentTime); // Оновлюємо час останнього оновлення
        setAllPcRtsProducts(finalProducts);
        setLoadingSearch(false);
      })
      .catch((error) => console.error('Error:', error));
  }, [lastUpdated]);

  const topBanner =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');
  const topBannerContents = topBanner && topBanner[0]?.primary?.banner_contents;
  const hideBannerTimerRunsOut =
    topBanner && topBanner[0]?.primary?.hide_when_timer_runs_out;
  const topBannerTimer =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');
  const topBannerTimerContents =
    topBannerTimer && topBannerTimer[0]?.primary?.countdown_end;

  const renderer = ({
    days = null,
    hours = null,
    minutes = null,
    seconds = null,
    completed = null,
  }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <span>
          {' '}
          | Ends in {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  const handleCloseBanner = () => {
    setIsAnnouncementBarOpen(false);
  };
  const handleCartOpen = () => {
    setIsCartOpen(true);
  };

  const handleChatToggle = () => {
    window.FrontChat('show');
  };

  const checkGraphicsCard = (data, search) => {
    let check = false;
    data.filter((item) => {
      const graphicsCardOptions = item?.modelOptionGroup?.find(
        (options) => options.optionGroup.option_group_title === 'Graphics Card',
      );

      if (graphicsCardOptions) {
        graphicsCardOptions.options.find((option) => {
          option.component.component_title
            .toLowerCase()
            .includes(search.toLowerCase());
          if (
            (check = option.component.component_title
              .toLowerCase()
              .includes(search.toLowerCase()))
          ) {
            return true;
          }
        });
      }
    });
    return check;
  };

  const checkCPU = (data, search) => {
    let check = false;
    data.filter((item) => {
      const cpuOptions = item?.modelOptionGroup?.find(
        (options) => options?.optionGroup.option_group_title === 'CPU',
      );

      if (cpuOptions) {
        cpuOptions.options.find((option) => {
          option.component.component_title
            .toLowerCase()
            .includes(search.toLowerCase());
          if (
            (check = option.component.component_title
              .toLowerCase()
              .includes(search.toLowerCase()))
          ) {
            return true;
          }
        });
      }
    });
    return check;
  };

  const checkChasisAllProducts = (data, search) => {
    let check = false;

    data.filter((item) => {
      if (item.product != undefined) {
        const productTags = item.product?.tags?.split(',').map((item) => {
          return item.trim();
        });
        const featuresTags = productTags.filter((item) =>
          item.startsWith('specifications:'),
        );

        featuresTags.map((item) => {
          if (item.split(':')[3] != undefined) {
            const description = item.split(':')[3].replace(/[-\s|]/g, '');

            if (
              description
                .toLowerCase()
                .includes(search.replace(/[-\s|]/g, '').toLowerCase())
            ) {
              check = true;
            }
          }
        });
      } else {
        if (item.chassisGroup != undefined) {
          item.chassisGroup[0]?.options?.map((option) => {
            if (
              option.component.component_title
                .replace(/[-\s|]/g, '')
                .toLowerCase()
                .includes(search.replace(/[-\s|]/g, '').toLowerCase())
            ) {
              check = true;
            }
          });
        }
      }
    });
    return check;
  };

  const checkChasisRTS = (item, search) => {
    let check = false;

    if (item.product != undefined) {
      const productTags = item.product?.tags?.split(',').map((item) => {
        return item.trim();
      });
      const featuresTags = productTags.filter((item) =>
        item.startsWith('specifications:'),
      );

      featuresTags.map((item) => {
        if (item.split(':')[3] != undefined) {
          const description = item.split(':')[3].replace(/[-\s|]/g, '');
          if (
            description
              .toLowerCase()
              .includes(search.replace(/[-\s|]/g, '').toLowerCase())
          ) {
            check = true;
          }
        }
      });
    }
    return check;
  };

  const checkTags = (data, search) => {
    const filteredData = data.filter((item) => {
      return item.product?.tags
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes(search.replace(/[^a-zA-Z0-9]/g, ''));
    });
    if (filteredData.length != 0) {
      return true;
    }
    return false;
  };

  const handleSearch = (value) => {
    if (value.includes('notebook')) {
      setSearch('laptop');
    } else if (
      value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes('samedaydelivery')
    ) {
      setSearch('samedayshipping');
    } else if (
      value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes('sff')
    ) {
      setSearch('itx');
    } else {
      setSearch(value);
    }
  };

  const checkCpu = (data, search) => {
    let check = false;
    data.filter((item) => {
      const graphicsCardOptions = item?.modelOptionGroup?.find(
        (options) => options.optionGroup.option_group_title === 'CPU',
      );

      if (graphicsCardOptions) {
        graphicsCardOptions.options.find((option) => {
          option.component.component_title
            .toLowerCase()
            .includes(search.toLowerCase());
          if (
            (check = option.component.component_title
              .toLowerCase()
              .includes(search.toLowerCase()))
          ) {
            return true;
          }
        });
      }
    });
    return check;
  };

  return (
    <>
      <div className='hamburger_menu'>
        {isAnnouncementBarOpen && (
          <div className='header_menu_banner wrapper'>
            <div className='shipping-banner'>
              {topBannerTimerContents ? (
                <div className='banner-with-timer'>
                  <span>{topBannerContents}</span>
                  <BannerCountdownTimer
                    date={topBannerTimerContents}
                    handleCloseBanner={handleCloseBanner}
                    hideBannerTimerRunsOut={hideBannerTimerRunsOut}
                  />
                  {/* <Countdown
                    renderer={renderer}
                    date={new Date(topBannerTimerContents)}
                  /> */}
                </div>
              ) : (
                topBannerContents
              )}
              <div
                className='close_banner'
                aria-hidden='false'
                onClick={handleCloseBanner}
              >
                <Image
                  draggable={false}
                  src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936'
                  alt='close icon'
                  width='16'
                  height='16'
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`menu-header wrapper ${
            isMenuOpen ? `menu_open` : `menu_close`
          }`}
        >
          {openSearch && (
            <div className='mobile-wide-search-bar-container'>
              <div className='search-aftershock-logo'>
                <Image
                  draggable={false}
                  src={
                    'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/header_new_logo.svg?v=1714033525'
                  }
                  alt='Aftershock logo'
                  width={130}
                  height={30}
                  layout='fixed'
                />
                <button
                  className='close-search'
                  onClick={() => setOpenSearch(false)}
                >
                  X
                </button>
              </div>
              <input
                className='wide-search-bar'
                placeholder='Search...'
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div className='search-result-con'>
                {loadingSearch ? (
                  <div className='loading-search'>
                    <p className='loading-text'>Loading</p>
                    <ThreeDots
                      height='25'
                      width='25'
                      radius='9'
                      color='#950610'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClassName=''
                      visible={loadingSearch}
                    />
                  </div>
                ) : (
                  search != '' &&
                  (allProducts.filter((item) =>
                    item.product?.title
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      .toLowerCase()
                      .includes(
                        search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                      ),
                  ).length == 0 &&
                  allProducts.filter((item) =>
                    item.model_title
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      .toLowerCase()
                      .includes(
                        search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                      ),
                  ).length == 0 &&
                  allProducts.filter((item) =>
                    item.title1
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      .toLowerCase()
                      .includes(
                        search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                      ),
                  ).length == 0 &&
                  allProducts.filter((item) =>
                    item.seo_title
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      .toLowerCase()
                      .includes(
                        search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                      ),
                  ).length == 0 &&
                  allProducts.filter((item) =>
                    item.subtitle
                      ?.replace(/[^a-zA-Z0-9]/g, '')
                      .toLowerCase()
                      .includes(
                        search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                      ),
                  ).length == 0 &&
                  !checkChasisAllProducts(allProducts, search) &&
                  !checkGraphicsCard(allProducts, search) &&
                  !checkCPU(allProducts, search) &&
                  !checkTags(allProducts, search) ? (
                    <div>
                      <p>No result found</p>
                    </div>
                  ) : (
                    allProducts.map((item) => {
                      const checkPcModel = item?.modelOptionGroup?.filter(
                        (options) =>
                          options?.optionGroup?.option_group_title ==
                          'Graphics Card',
                      );
                      const checkPcModelChassis = item?.chassisGroup?.filter(
                        (options) =>
                          options?.optionGroup?.option_group_title == 'Chassis',
                      );
                      const checkPcModelCpu = item?.cpuGroup?.filter(
                        (options) =>
                          options?.optionGroup?.option_group_title == 'CPU',
                      );

                      if (
                        item.product?.title
                          ?.replace(/[^a-zA-Z0-9]/g, '')
                          .toLowerCase()
                          .includes(
                            search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                          ) ||
                        item.model_title
                          ?.replace(/[^a-zA-Z0-9]/g, '')
                          .toLowerCase()
                          .includes(
                            search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                          ) ||
                        checkChasisRTS(item, search) ||
                        item.title1
                          ?.replace(/[^a-zA-Z0-9]/g, '')
                          .toLowerCase()
                          .includes(
                            search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                          ) ||
                        item.seo_title
                          ?.replace(/[^a-zA-Z0-9]/g, '')
                          .toLowerCase()
                          .includes(
                            search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                          ) ||
                        item.subtitle
                          ?.replace(/[^a-zA-Z0-9]/g, '')
                          .toLowerCase()
                          .includes(
                            search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                          ) ||
                        item.product?.tags
                          ?.toLowerCase()
                          .replace(/[^a-zA-Z0-9]/g, '')
                          .includes(search.replace(/[^a-zA-Z0-9]/g, '')) ||
                        item.buildType
                          ?.toLowerCase()
                          .includes(search.replace(/[^a-zA-Z0-9]/g, ''))
                      ) {
                        const productImage = item.product?.image?.src
                          ? item.product?.image?.src
                          : item.thumb_image
                          ? item.thumb_image
                          : item.thumbnail?.url;
                        const productTitle = item.product?.title
                          ? item.product?.title?.split('/')
                          : item.model_title
                          ? item.model_title
                          : item.title1;
                        const productDescription = item.product?.body_html
                          ? item.product?.body_html?.replace(/<[^>]*>/g, '')
                          : item.seo_description;
                        return (
                          <a
                            href={
                              item.product?.handle
                                ? `/products/${item.product?.handle}`
                                : item.slug
                                ? `/pc-models/${item.slug}`
                                : item.link
                            }
                            className='search-section'
                            onClick={() =>
                              (window.location.href = item.product?.handle
                                ? `/products/${item.product?.handle}`
                                : `/pc-models/${item.slug}`)
                            }
                          >
                            <div className='search-image'>
                              <img
                                draggable='false'
                                width={'100%'}
                                src={productImage}
                              />
                            </div>
                            <div className='search-description'>
                              <h1 className='product-title'>
                                {item.product?.title
                                  ? productTitle[0]
                                  : productTitle}
                              </h1>
                              <h1 className='product-subtitle'>
                                {item.product?.title
                                  ? productTitle[1]
                                  : item.seo_title
                                  ? item.seo_title
                                  : item.subtitle.replace(/\bquotation\b/g, '')}
                              </h1>
                              <div className='product-description'>
                                {productDescription}
                              </div>
                            </div>
                          </a>
                        );
                      } else if (
                        checkPcModelChassis != undefined ||
                        checkPcModel != undefined ||
                        checkPcModelCpu != undefined
                      ) {
                        const check = checkPcModel[0]?.options?.map(
                          (option) => {
                            if (
                              option.component.component_title
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return true;
                            }
                          },
                        );
                        const hasTrueValue = check?.includes(true);

                        const checkChassis =
                          checkPcModelChassis[0]?.options?.map((option) => {
                            if (
                              option.component.component_title
                                .replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                                .includes(
                                  search
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .toLowerCase(),
                                )
                            ) {
                              return true;
                            }
                          });
                        const hasTrueValueChassis =
                          checkChassis?.includes(true);

                        const checkCpu = checkPcModelCpu[0]?.options?.map(
                          (option) => {
                            if (
                              option.component.component_title
                                .replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                                .includes(
                                  search
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .toLowerCase(),
                                )
                            ) {
                              return true;
                            }
                          },
                        );
                        const hasTrueValueCpu = checkCpu?.includes(true);

                        if (
                          hasTrueValue ||
                          hasTrueValueChassis ||
                          hasTrueValueCpu
                        ) {
                          const productImage = item.thumb_image;
                          const productTitle = item.model_title;
                          const productDescription = item.seo_description;
                          return (
                            <a
                              href={`/pc-models/${item.slug}`}
                              className='search-section'
                              onClick={() =>
                                (window.location.href = `/pc-models/${item.slug}`)
                              }
                            >
                              <div className='search-image'>
                                <img
                                  draggable='false'
                                  width={'100%'}
                                  src={productImage}
                                />
                              </div>
                              <div className='search-description'>
                                <h1 className='product-title'>
                                  {productTitle}
                                </h1>
                                <h1 className='product-subtitle'>
                                  {item.seo_title}
                                </h1>
                                <div className='product-description'>
                                  {productDescription}
                                </div>
                              </div>
                            </a>
                          );
                        }
                      }
                    })
                  ))
                )}
              </div>
            </div>
          )}

          <div className='menu_icon'>
            <div className='menu_icon'>
              <Logo width={148} height={32} />
            </div>
          </div>

          <div className='menu_icon'>
            <div className='right_icons'>
              <button
                className='search-icon'
                onClick={() => {
                  handleSearch('');
                  setOpenSearch(!openSearch);
                }}
              >
                <img
                  draggable='false'
                  src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-search-gray_736e3137-c729-4aad-b020-7bb9ed902c1e.png?v=1714032128'
                  alt='icon-search'
                  width={15}
                  height={15}
                />
              </button>

              {/* <div
              type="button"
              className="icons icons_container chat_icon"
              aria-hidden="false"
              onClick={handleChatToggle}
             >
              <Image draggable={false}
                src={'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-chat.svg?v=1714033559'}
                alt={'icon-chat'}
                width={18}
                height={18}
              />
              <span className="chat_badge" />
              <span className="chat_badge ping" />
              </div> */}
              {/* <ProfileButton isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> */}

              <div
                type='button'
                className='icons icons_container'
                onClick={handleCartOpen}
                aria-hidden='false'
              >
                <Image
                  draggable={false}
                  src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-cart.svg?v=1714039956'
                  alt='cart icon'
                  layout='fixed'
                  width={24}
                  height={24}
                />
                <span>
                  {checkoutId && <CartCounter checkoutId={checkoutId} />}
                </span>
              </div>

              <div id='iconMenu'>
                <input
                  type='checkbox'
                  checked={isChecked}
                  id='toggle'
                  onClick={() => (isChecked ? closeMenu() : openMenu())}
                />
                <label for='toggle'></label>
              </div>

              {/* <div style={{"display":"flex","justifyContent":"center","alignItems":"center"}}>
                {isMenuOpen ? (
                  <Image draggable={false}
                    src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936"
                    alt="close icon"
                    width={24}
                    height={24}
                    onClick={toggleMenu}
                    layout="fixed"
                  />
                ) : (
                  <Image draggable={false}
                    src="/icons/icon-hamburger.svg"
                    alt="close icon"
                    width={24}
                    height={24}
                    onClick={toggleMenu}
                    layout="fixed"
                  />
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

MobileHeader.propTypes = {
  closeMenu: PropTypes.func,
  openMenu: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  header: PropTypes.any,
};

MobileHeader.defaultProps = {
  closeMenu: null,
  openMenu: null,
  header: null,
};

export default MobileHeader;
