import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { ViewProductKlaviyo } from 'helpers/klaviyo';
import styles from './styles';
import ImageGallery from './ImageGallery';
import ImagesGalleryMobile from './ImagesGalleryMobile';
import ProductDescriptions from './ProductDescriptions';
import ProductPerformance from './ProductPerformance';
import ProductWarranty from './ProductWarranty';
import ProductSpecification from './ProductSpecification';
import ProductFeatures from './ProductFeatures';
import ImageViewer from './ImageViewer';
import BreadcrumbMenu from './BreadcrumbMenu';
import EssentialProducts from './EssentialProducts';
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';
import TiktokPixel from 'tiktok-pixel';

import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
  AnalyticsEventName,
} from '@shopify/hydrogen-react';

const Product = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { product } = data;

  const productTitle = product.title;
  const productId = product.id;
  const productHandle = product.handle;
  const productPrice = product.variants.edges[0].node.price;
  const productSKU = product.variants.edges[0].node.sku;
  const productImage = product.images?.edges[0]?.node?.originalSrc;
  const imagesGallery = product.images.edges;
  const productVariant = product.variants.edges[0].node;
  const productTags = product.tags;
  const productAvailable =
    product?.variants?.edges[0]?.node?.quantityAvailable > 0;
  const productCompareAtPrice = product.variants.edges[0].node.compareAtPrice;
  const productDescription = (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: data.product.descriptionHtml }}
    />
  );

  const  sendPageView = (analyticsPageData) => {
    const payload = {
      ...getClientBrowserParameters(),
      ...analyticsPageData,
    };
    sendShopifyAnalytics({
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
    });
  }

  const analyticsShopData = {
    shopId: 'gid://shopify/Shop/63704072436',
    currency: 'SGD',
    acceptedLanguage: 'en',
  };

  const hasUserConsent = true;

  const analytics = {
    hasUserConsent,
    ...analyticsShopData,
  };

  useEffect(() => {
    fbEvent({
      eventName: 'ViewContent',
      products: [{
        sku: productSKU,
        quantity: 1,
      }],
      value: productPrice,
      currency: 'SGD',
      enableStandardPixel: false
    });

    const options = {
      debug: false,
    };

    TiktokPixel.init(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID, options);
    TiktokPixel.track('ViewContent', {
      content_category: 'product',
      content_id: productId.replace('gid://shopify/Product/', ''),
      content_name: productTitle,
      content_type: 'product',
      currency: 'SGD',
      value: productPrice,
    });

    sendPageView(analytics);

  }, []);

  return (
    <>
      {global?.window?._learnq
        ? ViewProductKlaviyo(
            productTitle,
            productId,
            productImage,
            productPrice,
            productCompareAtPrice,
            productHandle,
            productSKU,
          )
        : ''}
      <div className="product_section wrapper">
        <BreadcrumbMenu
          productHandle={productHandle}
          productTitle={productTitle}
          productTags={productTags}
        />
        {showModal ? (
          <ImageViewer
            imagesGallery={imagesGallery}
            handleModalClose={setShowModal}
            imageIndex={imageIndex}
            handleImageIndex={setImageIndex}
          />
        ) : null}

        <div className="product_information">
          <div className="product_images_section">
            <ImageGallery
              productImage={productImage}
              imagesGallery={imagesGallery}
              productTitle={productTitle}
              showModal={showModal}
              handleModalOpen={setShowModal}
              handleImageIndex={setImageIndex}
            />
          </div>

          <div className="images_gallery_mobile">
            <ImagesGalleryMobile
              imagesGallery={imagesGallery}
              showModal={showModal}
              handleModalOpen={setShowModal}
              handleImageIndex={setImageIndex}
            />
          </div>

          <div className="product_contents">
            <ProductDescriptions
              productTitle={productTitle}
              productPrice={productPrice}
              productVariant={productVariant}
              productDescription={productDescription}
              productCompareAtPrice={productCompareAtPrice}
              productTags={productTags}
              productImage={productImage}
              productHandle={productHandle}
              productSKU={productSKU}
              productAvailable={productAvailable}
              productId={productId}
            />
            <ProductPerformance productTags={productTags} />
            <ProductFeatures productTags={productTags} />
            <ProductWarranty productTags={productTags} />
            <ProductSpecification productTags={productTags} />
          </div>
        </div>
        <div className="essential_products">
          <EssentialProducts productTags={productTags} />
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

Product.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default Product;
