import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

const ImageGallery = ({
  productImage,
  imagesGallery,
  productTitle,
  handleModalOpen,
  handleImageIndex,
}) => {
  const [imageViewer, setImageViewer] = useState(null);
  const imageHandleChange = (id) => {
    const imageList = imagesGallery;
    imageList.map((imageNode) => {
      if (imageList.indexOf(imageNode) === id) {
        setImageViewer(imageNode.node.originalSrc);
      }
    });
  };
  const handleModal = () => {
    handleModalOpen(true);
  };
  useEffect(() => {
    setImageViewer(productImage || 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274');
  }, [productImage]);

  return (
    <>
      <div className="product_image_container">
        <div className="image_view" onClick={handleModal}>
          <div className="product_image">
            {imageViewer && (
              <Image draggable={false}
                src={imageViewer}
                alt={productTitle}
                width={500}
                height={500}
              />
            )}
          </div>
          <div className="image_zoom_in">
            <Image draggable={false}
              src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-magnifyingglass-light_1.png?v=1714048366"
              alt="product_features"
              width={25}
              height={25}
            />
          </div>
        </div>
        <div className="image_gallery">
          {imagesGallery.map((item, id) => {
            const handleClick = () => {
              imageHandleChange(id);
              handleImageIndex(id);
            };
            return (
              <div className="image_tab" key={id}>
                <Image draggable={false}
                  src={item.node.originalSrc}
                  alt="product_view_image"
                  width={200}
                  height={200}
                  onClick={handleClick}
                />
              </div>
            );
          })}
        </div>
      </div>

      <style jsx>
        {`
          .image_view {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 600px;
            cursor: pointer;
            position: relative;
          }
          .image_zoom_in {
            position: absolute;
            right: 10px;
            bottom: 10px;
          }

          .image_gallery {
            height: 200px;
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 5%;
          }
          .image_tab {
            width: 30%;
            background: #141414;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: 30px;
          }
          .image_tab img {
            width: 70%;
          }
        `}
      </style>
    </>
  );
};

ImageGallery.propTypes = {
  productImage: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  imagesGallery: PropTypes.array.isRequired,
  productTitle: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleImageIndex: PropTypes.func.isRequired,
};

export default ImageGallery;
