import React, { useContext, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';
import Cart from 'containers/Cart';
import { StorefrontContext } from 'providers/storefront';
import PreviewMode from 'components/PreviewMode';
import Navigation from './Header/Navigation';
import NavigationMobile from './Header/NavigationMobile';
import Footer from './Footer';
import Head from './Head';
import MobileHeader from './MobileHeader';
import { useRouter } from 'next/router';
import { globalPropsRequests } from 'utils/globalProps';

const Layout = ({ children, global, preview }) => {
  const {
    header,
    footer,
    rtsProducts,
    customProducts,
    workstationProducts,
    limitedEditionProducts,
    customLaptops,
  } = global || {};
  const headerRef = useRef('');
  const { isMenuOpen, setIsMenuOpen } = useContext(StorefrontContext);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // console.log({ layoutGlobal: global });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('header'))) {
      header = JSON.parse(localStorage.getItem('header'));
    }

    if (header != null && header != undefined) {
      if (header.length != 0) {
        localStorage.setItem('header', JSON.stringify(header));
      }
    }
  }, []);

  const router = useRouter();
  const isKnowledgeHub = router.pathname.includes('blog');

  return (
    <>
      <Head />
      {!isKnowledgeHub && global && (
        <MobileHeader
          header={header}
          isChecked={isMenuOpen}
          openMenu={openMenu}
          closeMenu={closeMenu}
          rtsProducts={rtsProducts}
          customProducts={customProducts}
          workstationProducts={workstationProducts}
          limitedEditionProducts={limitedEditionProducts}
          customLaptops={customLaptops}
        />
      )}
      <NavigationMobile
        header={header}
        isOpen={isMenuOpen}
        closeMenu={closeMenu}
      />
      {!isKnowledgeHub && global && (
        <Navigation
          header={header}
          opacityRef={headerRef}
          rtsProducts={rtsProducts}
          customProducts={customProducts}
          workstationProducts={workstationProducts}
          limitedEditionProducts={limitedEditionProducts}
          customLaptops={customLaptops}
        />
      )}
      <div ref={headerRef}>
        {children}
        {!isKnowledgeHub && (
          <>
            <Footer footer={footer} />
            <Cart />
            <PreviewMode preview={preview} />
          </>
        )}
      </div>
      <style jsx>
        {`
          @media (max-width: ${breakPoints.medium}) {
            .header-nav {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

Layout.propTypes = {
  preview: PropTypes.bool,
};

Layout.defaultProps = {
  preview: false,
};

export default Layout;
