/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import LineItemProduct from './LineItemProduct';
import { fixLineItemsPriceObjectModel } from 'helpers/fixPriceObjectModel';

const LineItemsGroup = ({
  lineItems,
  title,
  removeLineItemInCart,
  updateLineItemInCart,
  delayedItems,
}) => {
const fixedLineItems = fixLineItemsPriceObjectModel(lineItems);
  if (!fixedLineItems || fixedLineItems?.length === 0) return '';
  return (
    <div>
      <h4>{title}</h4>
      {fixedLineItems.map((item) => {
        const delayed = delayedItems.filter(
          (i) => i === item?.node?.id,
        )?.length;
        return (
          title == "READY TO SHIP PCs" ? 
            <LineItemProduct
              key={item?.node?.id}
              item={item}
              removeLineItemInCart={() => removeLineItemInCart(item?.node?.id, 'rts')}
              updateLineItemInCart={(lineItemId, updatedQuantity) => updateLineItemInCart(item?.node?.id, updatedQuantity, 'rts')}
              delayed={delayed > 0}
            />
            :
            <LineItemProduct
            key={item?.node?.id}
            item={item}
            removeLineItemInCart={removeLineItemInCart}
            updateLineItemInCart={updateLineItemInCart}
            delayed={delayed > 0}
          />
        );
      })}
      <style jsx>
        {`
          h4 {
            border-bottom: 1px solid #343434;
            padding-bottom: 15px;
          }
        `}
      </style>
    </div>
  );
};
LineItemsGroup.propTypes = {
  lineItems: PropTypes.array.isRequired,
  removeLineItemInCart: PropTypes.func.isRequired,
  updateLineItemInCart: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  delayedItems: PropTypes.arrayOf(PropTypes.string),
};

LineItemsGroup.defaultProps = {
  delayedItems: [],
};

export default LineItemsGroup;
