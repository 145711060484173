import Image from "next/image";
import { useState } from "react";
import ReactModal from "react-modal";
import breakPoints from "helpers/Styles";

const NotifyMe = ({ product, variant, title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubscription = async () => {
    setLoading(true);
    setMessage("");
    if (
      email &&
      email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      const productId = Buffer.from(product)
        .toString('base64')
        .replace('gid://shopify/Product/', '');

      const variantId = Buffer.from(variant)
        .toString('base64')
        .replace('gid://shopify/ProductVariant/', '');

      try {
        const response = await fetch("/api/subscribe/out-of-stock", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            product: productId,
            variant: variantId,
          }),
        });
        const result = await response.json();
        if (result.success === true) {
          setLoading(false);
          setMessage("You have been successfully subscribed");
        } else {
          setMessage(
            "There was an issue subscribing you. Plase try again later"
          );
          setLoading(false);
        }
      } catch (err) {
        setMessage("There was an issue subscribing you. Plase try again later");
        setLoading(false);
      }
    } else {
      setMessage("Please enter a valid email");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="notify-me">
        <button type="button" onClick={handleOpen}>
          Notify Me When Available
        </button>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        className="modal-content"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        preventScroll
        style={{
          overlay: {
            background: 'rgba(255, 255, 255, 0.25)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
          },
          content: {
            margin: '10px',
            borderRadius: '2px',
            minWidth: '50vw',
            overflow: 'hidden',
            maxWidth: '656px',
          },
        }}
      >
        <div className="notify-modal-container">
          <h2 className="title">{title}</h2>
          <div className="subtitle">
            Get notified via email when this item is back in stock
          </div>
          <input
            className="email-input"
            placeholder="Enter your email address"
            onChange={handleOnChange}
            value={email}
            type="email"
          />
          <div className="notify-me">
            <button
              type="button"
              disabled={loading}
              onClick={handleSubscription}
            >
              Notify Me When Available
            </button>
          </div>
          {message && !loading && <div className="message">{message}</div>}
          <div className="info">
            While entering your email will not hold an item for you, it will
            allow us to notify you once it becomes available again.
          </div>
          <div className="close-icon">
            <button type="button" onClick={handleClose}>
              <Image draggable={false}
                src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close-white_1.png?v=1714038100"
                alt="close"
                width={24}
                height={24}
              />
            </button>
          </div>
        </div>
      </ReactModal>
      <style jsx>
        {`
          .notify-me {
            display: flex;
          }
          .notify-me button {
            background: #950810;
            width: 100%;
            height: 48px;
            outline: none;
            border: 0;
            border-radius: 3px;
            font-family: 'Roboto-Medium';
            letter-spacing: 0;
            font-style: normal;
            font-weight: 600 !important;
            font-size: 13px;
            line-height: 14px !important;
            text-align: center;
            color: #e4e4e4 !important;
            padding: 12px 20px;
          }

          @media (min-width: 991px) {
            .notify-me button {
              margin-left: 15px;
            }
            .car_button.cart_btn button {
              margin-left: 0px !important;
            }
          }
          @media (min-width: 1200px) {
            .notify-me button {
              margin-left: 5px;
            }
          }
          .notify-me button:disabled {
            opacity: 0.4;
          }
          .notify-modal-container {
            background-color: #141414;
            padding: 24px 40px 32px 40px;
            position: relative;
          }
          .title {
            font-family: 'big_noodle_titling';
            text-align: center;
            font-size: 31px;
            line-height: 40px;
            margin-bottom: 24px;
          }
          .subtitle {
            font-family: 'Roboto-Medium';
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
          }
          .email-input {
            font-family: 'Roboto-Medium';
            font-size: 16px;
            line-height: 24px;
            padding: 12px 16px;
            color: #949494;
            background-color: #000000;
            border: 1px solid #242424;
            outline: none;
            width: 100%;
            margin-bottom: 24px;
          }
          .info {
            margin-top: 24px;
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            line-height: 24px;
            text-align: center;
          }
          .close-icon {
            position: absolute;
            top: 16px;
            right: 16px;
          }
          .close-icon button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
          }
          .message {
            margin: 12px 0;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            line-height: 16px;
            text-align: center;
          }
          @media (max-width: 768px) {
            .notify-modal-container {
              padding: 24px 16px;
            }
          }
          @media (max-width: ${breakPoints.medium}) {
            .notify-me button {
              margin-left: 0px;
              font-size: 12px !important;
            }
          }
          @media (max-width: ${breakPoints.large}) {
            .notify-me button {
              font-size: 12px;
              height: 50px !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default NotifyMe;
