import css from 'styled-jsx/css';

export default css`
  .circle {
    width: 130px;
    position: relative;
  }
  .indicator-bottom {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 87px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    user-select: none;
    font-size: 12px;
  }
  .indicator-top {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 27px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    user-select: none;
    font-size: 12px;
  }
  .indicator-center {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 8px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    user-select: none;
    font-size: 35px;
    font-family: 'Roboto-Medium';
    font-weight: bold;
  }
  .plus-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -18px;
    margin-left: 1px;
    font-size: 30px;
  }
  @media (max-width: 479px) and (min-width: 1px) {
    .circle {
      width: 60px;
    }
    .indicator-top {
      font-size: 6px;
      top: 10px;
    }
    .indicator-center {
      font-size: 17px;
      top: 2px;
    }
    .indicator-bottom {
      font-size: 6px;
      top: 42px;
    }
    .plus-icon {
      margin-top: -5px;
      font-size: 12px;
    }
  }
`;