import { gql } from '@apollo/client';

export const PRODUCT_BY_HANDLE = gql`
  query ($productHandle: String!) {
    product: productByHandle(handle: $productHandle) {
      id
      title
      handle
      tags
      description
      descriptionHtml
      metafields(
        identifiers: [
          { namespace: "configurator", key: "components_configurator" }
          { namespace: "custom", key: "ranking" }
          { namespace: "panel_print", key: "product_id" }
          { namespace: "variants", key: "product_id" }
          { namespace: "custom", key: "limited_time_deal_start" }
          { namespace: "custom", key: "limited_time_deal_end" }
          { namespace: "custom", key: "restock_time" }
          { namespace: "custom", key: "short_description" }
          { namespace: "warranty", key: "product_id" }
          { namespace: "pre_installed", key: "product_id" }
          { namespace: "power_supply_upgrade", key: "product_id" }
          { namespace: "cpu_cooling_upgrade", key: "product_id" }
          { namespace: "ram_upgrades", key: "product_id" }
          { namespace: "ssd", key: "product_id" }
          { namespace: "hdd", key: "product_id" }
          { namespace: "upsell", key: "productHandle" }
          { namespace: "video", key: "video_url" }
          { namespace: "custom", key: "technical_specifications" }
          { namespace: "custom", key: "long_image_portion" }
        ]
      ) {
        namespace
        key
        value
      }
      priceRange {
        minVariantPrice {
          amount
        }
      }
      tags
      productType
      vendor
      images(first: 5) {
        edges {
          node {
            originalSrc
            altText
            transformedSrc
          }
        }
      }
      options {
        id
        name
        values
      }
      seo {
        title
        description
      }
      totalInventory
      variants(first: 5) {
        edges {
          node {
            id
            title
            price {
              amount
              currencyCode
            }
            sku
            quantityAvailable
            availableForSale
            currentlyNotInStock
            priceV2 {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export const productByHandleVars = (productHandle) => ({
  productHandle,
});

export const PRODUCT_STOCK = gql`
  query ($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on ProductVariant {
        id
        inventoryQuantity
        inventoryItem {
          tracked
        }
        inventoryPolicy
      }
    }
  }
`;

export const productStockVars = (ids) => ({
  ids,
});

export const PRODUCT_BY_ID_SOME_FILEDS = gql`
  query ($product_id: ID!) {
    product: node(id: $product_id) {
      id
      ... on Product {
        id
        title
        variants(first: 5) {
          edges {
            node {
              id
              title
              price
              compareAtPrice
            }
          }
        }
      }
    }
  }
`;
export const PRODUCT_BY_ID = gql`
  query ($product_id: ID!) {
    product: node(id: $product_id) {
      id
      ... on Product {
        id
        title
        handle
        tags
        description
        descriptionHtml
        metafields(
          identifiers: [
            { namespace: "configurator", key: "components_configurator" }
            { namespace: "panel_print", key: "product_id" }
            { namespace: "custom", key: "limited_time_deal_start" }
            { namespace: "custom", key: "limited_time_deal_end" }
            { namespace: "custom", key: "restock_time" }
            { namespace: "custom", key: "short_description" }
            { namespace: "warranty", key: "product_id" }
            { namespace: "pre_installed", key: "product_id" }
            { namespace: "power_supply_upgrade", key: "product_id" }
            { namespace: "cpu_cooling_upgrade", key: "product_id" }
            { namespace: "ram_upgrades", key: "product_id" }
            { namespace: "ssd", key: "product_id" }
            { namespace: "hdd", key: "product_id" }
            { namespace: "upsell", key: "productHandle" }
            { namespace: "video", key: "video_url" }
          ]
        ) {
          namespace
          key
          value
        }
        priceRange {
          minVariantPrice {
            amount
          }
        }
        tags
        productType
        vendor
        images(first: 10) {
          edges {
            node {
              originalSrc
              altText
              transformedSrc
            }
          }
        }
        options {
          id
          name
          values
        }
        seo {
          title
          description
        }
        variants(first: 5) {
          edges {
            node {
              id
              title
              price {
                amount
                currencyCode
              }
              sku
              quantityAvailable
              availableForSale
              priceV2 {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
  }
`;
export const productByIdVars = (product_id) => ({
  product_id,
});

export const GET_META_OBJECT = gql`
  query MetaObject($gid: ID!) {
    metaobject(id: $gid) {
      id
      fields {
        key
        value
      }
    }
  }
`;

export const GET_MEDIA_IMAGE = gql`
  query GetMediaImage($id: ID!) {
    node(id: $id) {
      ... on MediaImage {
        id
        alt
        image {
          originalSrc
          transformedSrc
          width
          height
        }
      }
    }
  }
`;

export const GET_PRODUCT_VARIANT = gql`
  query GetProductVariant($id: ID!) {
    node(id: $id) {
      ... on ProductVariant {
        id
        title
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
        product {
          title
          id
        }
      }
    }
  }
`;

export const productRanking = (ranking_id) => ({
  ranking_id,
});
