import { StorefrontContext } from 'providers/storefront';
import React, { useContext, useRef, useState } from 'react';

function SidePanel({
  children,
  title,
  isOpen,
  onClose,
  isCloseBottom,
  bannerImage,
  isHeaderCloseOnMobile,
}) {
  const modalRef = useRef();
  const { setIsMenuOpen } = useContext(StorefrontContext);
  return (
    <>
      <div
        className="modal_background"
        ref={modalRef}
        onClick={(e) => modalRef.current === e.target && onClose()}
      />
      <div className="side">
        <div className="side_container">
          <div
            className={`side_header ${
              isHeaderCloseOnMobile ? 'hide_on_mobile' : ''
            }`}
          >
            <img draggable="false" 
              src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-arrow-left_1.png?v=1714051208"
              className="back_icon"
              onClick={() => onClose()}
              alt="arrow_left"
            />
            <span className="side_title">{title}</span>
            {!isCloseBottom && (
              <>
                <img draggable="false" 
                  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936"
                  className="close_icon"
                  onClick={() => onClose()}
                  alt='close_icon'
                />
                <span></span>
              </>
            )}
          </div>
          {bannerImage && (
            <div className="banner-image-container">
              <img draggable="false" 
                src={bannerImage}
                alt="sidepanel-banner"
                className="banner-image"
              />
            </div>
          )}
          <div className="side_content">{children}</div>
        </div>
        {isCloseBottom && (
          <div className="bottom_close_container">
            <button onClick={() => onClose()} className="bottom_close">
              Close
            </button>
          </div>
        )}
      </div>

      <style jsx>{`
        .banner-image-container {
          display: flex;
          justify-content: center;
        }
        .banner-image {
          margin: 0 auto;
        }
        .side_container {
          max-width: ;
        }
        .bottom_close {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          width: 100%;
          background: #242424;
          border-radius: 4px;
          height: 48px;
          padding: 12px;
          color: #e4e4e4;
          border: none;
        }
        .bottom_close:hover {
          opacity: 0.75;
        }
        .bottom_close_container {
          padding: 24px;
        }
        .back_icon,
        .close_menu_icon {
          display: none;
          cursor: pointer;
        }
        .modal_background {
          display: ${isOpen ? 'block' : 'none'};
          background-color: rgba(4, 4, 4, 0.5);
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .close_icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
        .side_content {
          display: grid;
          gap: 8px;
          padding: 25px 24px;
        }
        .side_title {
          text-transform: uppercase;
          font-family: 'Roboto-Medium';
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #e4e4e4;
        }
        .side_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
          border-bottom: #242424 1px solid;
        }
        .side {
          position: fixed;
          top: 0;
          z-index: 5;
          right: ${isOpen ? '0' : '-368px'};
          width: 368px;
          height: 100vh;
          background-color: #141414;
          transition: all 0.5s ease-in-out;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
        .icon-carat {
          margin-left: 6.67px;
        }
        @media (max-width: 991px) {
          .hide_on_mobile {
            display: none;
          }
          .side {
            right: ${isOpen ? '0' : '-100vw'};
          }
          .close_icon {
            display: none;
          }
          .back_icon,
          .close_menu_icon {
            display: block;
            width: 24px;
            height: 24px;
          }
          .side_title {
            font-size: 13px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
        @media (max-width: 642px) {
          .side {
            width: 100vw;
          }
        }
      `}</style>
    </>
  );
}

export default SidePanel;