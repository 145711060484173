import css from 'styled-jsx/css';

export default css`
  .cta-img {
    width: 100%;
  }
  .item-list-container {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
  .item-list-title-group {
    display: flex;
  }
  .item-list-title {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 24px;
    color: #949494;
  }
  .item-list-subtitle {
    margin-left: 8px;
    font-size: 13px;
    line-height: 24px;
    color: #949494;
  }
  .mega-menu-center {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rts-image-container {
    cursor: pointer;
  }
  .item-list-body {
    display: flex;
  }
  .items-list {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .items-list-title-group {
    display: flex;
    flex-direction: column;
  }
  .items-list-title {
    color: #e4e4e4;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    font-weight: bold;
  }
  .items-list-title:hover {
    color: #fd0300;
  }
  .items-list-subtitle {
    color: #949494;
    font-size: 13px;
    text-align: center;
  }
  .link-button {
    color: #fd0300;
    font-size: 13px;
    line-height: 20px;
    padding-right: 7px;
    cursor: pointer;
  }
  .link-group {
    padding-top: 4px;
    display: flex;
    align-items: center;
  }
  .navbar-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 20px;
    color: #9a9a9a;
  }
`;
