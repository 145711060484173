import { useEffect, useRef } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import styles from './styles';

const PcPerformanceTips = ({ page, name, isTooltipOpen, handleCloseModal }) => {
  const title = name;
  const description =
    title === 'GRAPHICS PERFORMANCE' ? (
      <div className="contents">
        <p>
          Your graphics card (GPU) drives all the graphics performance needs in
          your PC. If you are after high frame rates, great visuals or are
          editing video content, this is your most important metric.
        </p>
      </div>
    ) : (
      title === 'PROCESSING SPEED' && (
        <div className="contents">
          <p>
            The processor (CPU) is the brain of the operation. Focus on your
            processing speed if you handle large work loads or want to squeeze
            extra performance out of your GPU.
          </p>
        </div>
      )
    );

  if (!title || !description) return null;

  const useOutsideClick = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCloseModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  return (
    <>
      {description && isTooltipOpen === true ? (
        <>
          <div
            className={`performance-description ${
              page === 'collection' && 'performance-description-collection'
            } `}
            ref={wrapperRef}
          >
            <h5
              className={`heading ${
                page === 'collection' && 'heading-collection'
              } `}
            >
              {title}
            </h5>

            <div
              className={`contents ${
                page === 'collection' && 'contents-collection'
              } `}
            >
              {description}
            </div>

            <div className="close" onClick={handleCloseModal}>
              <Image draggable={false}
                src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936"
                alt="performance tip"
                width={15}
                height={15}
              />
            </div>
          </div>
          <div className="arrow-top" />
        </>
      ) : null}
      <style global jsx>
        {`
          .contents a {
            color: #fd0300;
          }
        `}
      </style>

      <style jsx>{styles}</style>
    </>
  );
};

PcPerformanceTips.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pcConfigDoc: PropTypes.object.isRequired,
  isTooltipOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default PcPerformanceTips;
