import React, { useState, useEffect, useContext } from 'react';
import styles from '../styles-rts';
import { useQuery } from '@apollo/client';
import { PRODUCT_BY_ID, productByIdVars } from 'helpers/graphql/product';
import { Widget } from '@uploadcare/react-widget';
import { StorefrontContext } from 'providers/storefront';
import $ from 'jquery';
import { fixProductDataMetafields } from 'helpers/fixPriceObjectModel';
import { fixDecimalPlaces } from 'helpers/smallFixes';
import Image from 'next/image';

const ProductCustomize = ({
  selected,
  namespace,
  handlePreInstallChange,
  productMetaFields,
  handlePreInstallResetChange,
  handlePreSelected,
  handleCustomDesignUrl,
}) => {
  const { setCustomDesignUrl } = useContext(StorefrontContext);
  let productAvaiablityCheck = { [namespace]: [] };

  const [update, setUpdate] = useState(0);
  const [idx, setIdx] = useState(0);
  const [updateRaw, setUpdateRaw] = useState(0);
  const [idxRaw, setIdxRaw] = useState(0);

  // Added for power supply upgrade product
  const [idxPowerSupply, setIdxPowerSupply] = useState(0);
  const [updatePowerSupply, setUpdatePowerSupply] = useState(0);

  const [updateWarranty, setUpdateWarranty] = useState(0);
  const [updateCpuCool, setUpdateCpuCool] = useState(0);
  const [idxWarranty, setIdxWarranty] = useState(0);
  const [active, setActive] = useState(false);

  var metaProducts = productMetaFields.map((product_id) => {
    const { loading, data, error } = useQuery(PRODUCT_BY_ID, {
      variables: productByIdVars(product_id),
    });
    if (loading || (!data && !error)) {
      return <>loading</>;
    }
    if (error || !data) {
      return <>{JSON.stringify(error)}</>;
    }
    if (!error && namespace !== 'configurator') {
      return fixProductDataMetafields(data);
    }
  });

  useEffect(() => {
    setCustomDesignUrl('');
    if (
      update === 0 &&
      namespace === 'pre_installed' &&
      selected.pre_installed?.id === '' &&
      typeof metaProducts !== 'undefined' &&
      metaProducts &&
      typeof metaProducts[idx]?.product !== 'undefined'
    ) {
      const { product } = metaProducts[idx];
      const productAvailable =
        product && product?.variants?.edges[0]?.node?.quantityAvailable > 0;
      if (productAvailable) {
        setUpdate(1);
        handlePreInstallChange(namespace, metaProducts[idx]?.product);
      } else {
        setIdx(idx + 1);
      }
    }
    if (
      updateCpuCool === 0 &&
      namespace === 'cpu_cooling_upgrade' &&
      selected.cpu_cooling_upgrade?.id === '' &&
      typeof metaProducts !== 'undefined' &&
      metaProducts &&
      typeof metaProducts[idx]?.product !== 'undefined'
    ) {
      const { product } = metaProducts[idx];
      const productAvailable =
        (product && product?.variants?.edges[0]?.node?.quantityAvailable > 0) ||
        product.title === 'Default Cooler Selection';
      if (productAvailable) {
        setUpdateCpuCool(1);
        handlePreInstallChange(namespace, metaProducts[idx]?.product);
      } else {
        setIdx(idx + 1);
      }
    }
    if (
      updateRaw === 0 &&
      namespace === 'ram_upgrades' &&
      selected.ram_upgrades?.id === '' &&
      typeof metaProducts !== 'undefined' &&
      metaProducts &&
      typeof metaProducts[idxRaw]?.product !== 'undefined'
    ) {
      const { product } = metaProducts[idxRaw];
      const productAvailable =
        product && product?.variants?.edges[0]?.node?.quantityAvailable > 0;
      if (productAvailable) {
        setUpdateRaw(1);
        handlePreInstallChange(namespace, metaProducts[idxRaw]?.product);
      } else {
        setIdxRaw(idxRaw + 1);
      }
    }
    if (
      updateWarranty === 0 &&
      namespace === 'warranty' &&
      selected.warranty?.id === '' &&
      typeof metaProducts !== 'undefined' &&
      metaProducts &&
      typeof metaProducts[idxWarranty]?.product !== 'undefined'
    ) {
      const { product } = metaProducts[idxWarranty];
      const productAvailable =
        product && product?.variants?.edges[0]?.node?.quantityAvailable > 0;
      if (productAvailable) {
        setUpdateWarranty(1);
        handlePreInstallChange(namespace, metaProducts[idxWarranty]?.product);
      } else {
        setIdxWarranty(idxWarranty + 1);
      }
    }
    // Added for power supply upgrade product
    if (
      updatePowerSupply === 0 &&
      namespace === 'power_supply_upgrade' &&
      selected.power_supply_upgrade?.id === '' &&
      typeof metaProducts !== 'undefined' &&
      metaProducts &&
      typeof metaProducts[idxPowerSupply]?.product !== 'undefined'
    ) {
      const { product } = metaProducts[idxPowerSupply];
      const productAvailable =
        (product && product?.variants?.edges[0]?.node?.quantityAvailable > 0) ||
        product.title === 'Default Power Supply Selection';
      if (productAvailable) {
        setUpdatePowerSupply(1);
        handlePreInstallChange(
          namespace,
          metaProducts[idxPowerSupply]?.product,
        );
      } else {
        setIdxPowerSupply(idxPowerSupply + 1);
      }
    }
  }, [idx, idxRaw, idxPowerSupply, metaProducts]);

  useEffect(() => {
    $(document).on(
      'click',
      '.uploadcare--preview__back',
      handleBackButtonClick,
    );

    return () => {
      $(document).off(
        'click',
        '.uploadcare--preview__back',
        handleBackButtonClick,
      );
    };
  }, []);

  const handleBackButtonClick = () => {
    $('.info-text').remove();
    $('.crop-text').remove();
    $('.uploadcare--tab__content').append(
      "<p class='info-text'>Need help to resize or crop an image? <a href='https://redketchup.io/' target='_blank'><b>Check this out</b></a></p>",
    );
  };

  const handleClick = (id) => {
    if (!document.getElementById(`${id}`).classList.contains('uninstalled')) {
      document.getElementById(`${id}`).click();
    }
  };

  // function maxDimensions(width, height) {
  //   return function (fileInfo) {
  //     var imageInfo = fileInfo.originalImageInfo;

  //     if (imageInfo !== null) {
  //       function gcd(a, b) {
  //         return (a / 21).toFixed();
  //       }

  //       const imageWidth = fileInfo.originalImageInfo.width;
  //       const imageHeight = fileInfo.originalImageInfo.height;
  //       const factor = gcd(imageWidth, imageHeight);
  //       const aspectWidth = (imageWidth / factor).toFixed();
  //       const aspectHeight = (imageHeight / factor).toFixed();

  //       if (aspectWidth != 21 || aspectHeight != 9) {
  //         throw new Error('aspectRatio');
  //       } else if (imageInfo.dpi == null) {
  //         throw new Error('dimensions');
  //       } else if (imageInfo.dpi[0] < 300 && imageInfo.dpi[1] < 300) {
  //         throw new Error('dimensions');
  //       }
  //     }
  //   };
  // }

  const getIcon = (namespace) => {
    if (namespace === 'warranty') {
      return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/warranty-icon.png?v=1714058045';
    } else if (namespace === 'pre_installed') {
      return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/installed-os-icon.png?v=1714058067';
    } else if (namespace === 'power_supply_upgrade') {
      return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/POWER_SUPPLY_af88da62-669b-4851-97bf-dba504239a95.png?v=1716393529';
    } else if (namespace === 'cpu_cooling_upgrade') {
      return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/CPU_Cooling_Upgrades.png?v=1715087207';
    } else if (namespace === 'ram_upgrades') {
      return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/ram-upgrades-icon.png?v=1714058088';
    } else if (namespace === 'ssd' || namespace === 'hdd') {
      return 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/ssd-icon.png?v=1714058108';
    }
  };

  const fileTypeLimit = (allowedFileTypes) => {
    const types = allowedFileTypes.split(' ');

    return function (fileInfo) {
      if (fileInfo.name === null) {
        return;
      }
      const extension = fileInfo.name.split('.').pop();

      if (extension && !types.includes(extension)) {
        throw new Error('fileType');
      }
    };
  };

  const validators = [fileTypeLimit('png jpg jpeg')];

  metaProducts.length > 0 &&
    metaProducts &&
    metaProducts.map((itm, idx) => {
      if (namespace !== 'configurator') {
        const { product } = itm;
        if (product?.variants?.edges === undefined) {
          return;
        }
        const productAvailable =
          (product && product?.variants?.edges[0]?.node?.quantityAvailable > 0) ||
          product.title === 'Default Cooler Selection';
        if (!productAvailable) productAvaiablityCheck[namespace].push(true);
      }
    });

  if (metaProducts.length === productAvaiablityCheck[namespace].length)
    return <></>;
  else
    return (
      <>
        <div className='installed_template'>
          {/* <h2 className="h2">
            {namespace === "pre_installed" && "PRE-INSTALLED OS"}
            {namespace === "ram_upgrades" && "RAM UPGRADES"}
            {namespace === "hdd" && "HDD - ADDITIONAL"}
            {namespace === "ssd" && "SSD - ADDITIONAL"}
            {namespace === "warranty" && "WARRANTY - ADDITIONAL"}
            {namespace === 'panel_print' && 'PANEL PRINT'}
          </h2> */}

          <div className='collapse-container'>
            <div className='collapse-box'>
              <div
                className='collapse-section'
                onClick={() => setActive(!active)}
              >
                <div className='collapse-icon'>
                  <Image
                    draggable={false}
                    src={getIcon(namespace)}
                    alt='product_features'
                    width={namespace !== 'ssd' && namespace !== 'hdd' ? 55 : 45}
                    height={
                      namespace !== 'ssd' && namespace !== 'hdd' ? 55 : 45
                    }
                  />
                </div>
                <div className='collapse-button'>
                  {namespace === 'pre_installed' && 'PRE-INSTALLED OS'}
                  {namespace === 'ram_upgrades' && 'RAM UPGRADES'}
                  {namespace === 'hdd' && 'HDD - ADDITIONAL'}
                  {namespace === 'ssd' && 'STORAGE - ADD ON'}
                  {namespace === 'warranty' && 'WARRANTY'}
                  {namespace === 'power_supply_upgrade' &&
                    'POWER SUPPLY UPGRADE'}
                  {namespace === 'cpu_cooling_upgrade' && 'CPU COOLING UPGRADE'}
                  {namespace === 'panel_print' && 'PANEL PRINT'}
                </div>
                <div>
                  <img
                    src={
                      active
                        ? 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-up-gray.png?v=1714032503'
                        : 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-down-gray.png?v=1714032823'
                    }
                    className='collapse-chevron'
                  />
                </div>
              </div>
            </div>

            {active &&
              typeof metaProducts !== 'undefined' &&
              metaProducts &&
              metaProducts.map((itm, idx) => {
                const { product } = itm;
                if (product?.variants?.edges === undefined) {
                  return;
                }
                const productId = product && product.id;
                const productImage =
                  (product && product?.images?.edges[0]?.node?.originalSrc) ||
                  'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274';
                const productTitle = product ? product.title : null;
                const productPrice = product
                  ? product.variants.edges[0].node.price
                  : null;
                const productComparePrice = product
                  ? product.variants.edges[0].node.compareAtPrice
                  : null;
                const productAvailable =
                  product?.variants?.edges[0]?.node?.quantityAvailable > 0 ||
                  product.title === 'Default Cooler Selection' ||
                  product.title === 'Default Power Supply Selection';

                let customDesignTagData = [];
                if (product?.tags != undefined) {
                  customDesignTagData = product?.tags.filter((item) =>
                    item.startsWith('CustomDesign'),
                  );
                }

                const customDesignTag = customDesignTagData.length > 0;

                if (
                  productAvailable ||
                  product?.handle == 'custom-design' ||
                  product.title === 'Default Cooler Selection'
                ) {
                  return (
                    <>
                      {idx === 0 &&
                        (namespace === 'hdd' || namespace === 'ssd') && (
                          <div
                            className={`installed_software ${
                              selected[namespace]?.id === 'none' &&
                              'uninstalled'
                            }`}
                            onClick={() =>
                              handlePreInstallResetChange(namespace, product)
                            }
                          >
                            <h3>None</h3>
                          </div>
                        )}

                      {customDesignTagData[0] == 'CustomDesign' && (
                        <div
                          key={idx}
                          className={`installed_software ${
                            selected[namespace]?.id === productId &&
                            'uninstalled'
                          }`}
                          onClick={() =>
                            handlePreInstallChange(namespace, product)
                          }
                          id={product.id}
                        >
                          <h3 style={{ width: '45%' }}>{product.title}</h3>
                          <Widget
                            id='image'
                            publicKey='2e7072e9d25b7ca6ebcc'
                            onChange={(info) => {
                              handleCustomDesignUrl(info.cdnUrl);
                            }}
                            onDialogClose={() => handleClick(product.id)}
                            clearable={false}
                            previewStep={true}
                            validators={validators}
                            onDialogOpen={() => {
                              if (
                                $('.uploadcare--media').length == 1 &&
                                $('.uploadcare--preview__back').length == 0
                              ) {
                                $('.uploadcare--tab__content').append(
                                  "<p class='info-text'>Need help to resize or crop an image? <a href='https://redketchup.io/' target='_blank'><b>Check this out</b></a></p>",
                                );
                              }

                              const dropArea = document.querySelector(
                                '.uploadcare--draganddrop__supported',
                              );
                              if (dropArea) {
                                dropArea.innerHTML =
                                  '<p class="drag-drop-text">drag & drop<br>png or jpeg</p><p class="or-text">or</p>';
                              }
                            }}
                            localeTranslations={{
                              buttons: {
                                choose: {
                                  files: {
                                    one: 'UPLOAD HERE',
                                  },
                                },
                              },
                              errors: {
                                dimensions: 'Failed to upload. ',
                                aspectRatio: 'Failed to upload. ',
                                fileType: 'Failed to upload. ',
                              },
                              dialog: {
                                tabs: {
                                  preview: {
                                    error: {
                                      fileType: {
                                        title:
                                          'Please upload an image with PNG, JPG or JPEG file type.',
                                        text: 'The file is not valid. Please try again.',
                                        back: 'Ok',
                                      },
                                    },
                                  },
                                },
                              },
                            }}
                          />
                          <span style={{ marginLeft: '1%' }}>+$0.00</span>
                        </div>
                      )}

                      {customDesignTagData[0] != 'CustomDesign' && (
                        <div
                          key={idx}
                          className={`installed_software ${
                            selected[namespace]?.id === productId &&
                            'uninstalled'
                          }`}
                          onClick={() =>
                            handlePreInstallChange(namespace, product)
                          }
                        >
                          <h3>{productTitle}</h3>
                          <div className='price_container'>
                            {productComparePrice > 0 && (
                              <span className='compare_price'>
                                {'+$' + productComparePrice}
                              </span>
                            )}
                            <span>{'+$' + fixDecimalPlaces(productPrice)}</span>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              })}
          </div>
        </div>
        <style jsx>{styles}</style>
      </>
    );
};

export default ProductCustomize;
