import React from 'react';

function SocialItem({ href, iconSrc, alt, onClose, closeMenu }) {
  return (
    <div onClick={closeMenu}>
      <a href={href} target='_blank' rel='noreferrer' aria-label={alt}>
        <img draggable={false} src={iconSrc} alt={alt} width={24} height={24} />
      </a>
    </div>
  );
}

export default SocialItem;
