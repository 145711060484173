import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getCollectionName } from 'helpers/getCollectionName';

const BreadcrumbMenu = ({ productHandle, productTitle, productTags }) => {
  const title = productTitle.split('/')[0];
  const router = useRouter();
  const linkPath = router.asPath;

  const handleHref = productTags => {
    if (productTags.includes('ready-to-ship')) {
      return '/ready-to-ship/desktops';
    }
    if (productTags.includes('workstations')) {
      return '/workstations';
    }
    if (productTags.filter(item => item.includes('accessories'))) {
      return '/collections/accessories';
    }
    return '';
  };

  return (
    <>
      <div className="breadcrumb_menu">
        <ul>
          <li>
            <Link href="/">
              <a href="/">Home</a>
            </Link>
            <div>
              <div className="cart_icon">
                <Image draggable={false}
                  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_right_grey.png?v=1714036755"
                  alt="cart icon"
                  layout="fixed"
                  width="8px"
                  height="10px"
                />
              </div>
            </div>
          </li>
          <li>
            <Link href={handleHref(productTags)}>
              <a
                // onClick={() => {
                //   history.back(),
                //     sessionStorage.setItem('productHandle', productHandle);
                // }}
              >
                {getCollectionName(productTags)}
              </a>
            </Link>
            <div>
              <div className="cart_icon">
                <Image draggable={false}
                  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_right_grey.png?v=1714036755"
                  alt="cart icon"
                  layout="fixed"
                  width="8px"
                  height="10px"
                />
              </div>
            </div>
          </li>
          <li>
            <Link href={`/products/${productHandle}`}>
              <a
                href={`/products/${productHandle}`}
                className={`${
                  linkPath.includes(productHandle) ? 'active' : ''
                }`}
              >
                {title}
              </a>
            </Link>
          </li>
        </ul>
      </div>

      <style jsx>
        {`
          .breadcrumb_menu {
            padding: 20px 20px 0;
          }
          .breadcrumb_menu ul {
            display: flex;
            margin: 0;
            padding: 0;
          }
          .breadcrumb_menu ul li {
            display: flex;
            list-style: none;
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-right: 10px;
          }
          .breadcrumb_menu ul li a {
            display: block;
            margin-right: 10px;
          }
          .breadcrumb_menu ul li a:hover {
            color: #fd0300;
          }
          .active {
            color: #e4e4e4;
          }
        `}
      </style>
    </>
  );
};

export default BreadcrumbMenu;
