import React from 'react';

const Progress_bar = ({ bgcolor, progress }) => {
  const Parentdiv = {
    width: '100%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
  };

  const Childdiv = {
    height: '10px',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right',
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}> </div>
    </div>
  );
};

export default Progress_bar;
