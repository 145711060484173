import ChatPingingIconButton from 'components/NavigationButtonIcons/ChatPingingIconButton';
import React from 'react';

function SidePanelSubButton({
  iconSrc,
  text,
  onClick,
  chevron,
  isChatPingingIcon,
}) {
  return (
    <>
      <button className="side_panel_sub_button" onClick={onClick}>
        <div className="icon_text">
          {isChatPingingIcon ? (
            <div className="icon">
              <img draggable="false" 
                src={'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-chat.svg?v=1714033559'}
                alt={'icon-chat'}
                width={18}
                height={18}
              />
              <span className="chat_badge" />
              <span className="chat_badge ping" />
            </div>
          ) : (
            <div className="icon">
              {iconSrc ? (
                <img draggable="false"  src={iconSrc} alt="side_icon" />
              ) : (
                <div className="no_icon" />
              )}
            </div>
          )}

          <span className="button_text">{text}</span>
        </div>
        {chevron == undefined && (
          <img draggable="false" 
            src={'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-right-gray.png?v=1714056957'}
            alt="chevron_right"
          />
        )}
      </button>

      <style jsx>{`
        .icon {
          width: 36px;
          height: 16px;
          text-align: center;
          margin-left: -6px;
          position: relative;
        }
        .icon_text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 16px;
        }
        .no_icon {
          height: 16px;
          width: 16px;
        }
        .side_panel_sub_button {
          height: 40px;
          background: transparent;
          border: none;
          width: 100%;
          text-align: left;
          padding: 16px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .button_text {
          font-family: 'Roboto-Medium';
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #949494;
          text-transform: none;
          margin-left: 12px;
        }
        .ping {
          animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
        }
        @keyframes ping {
          75%,
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }
        .chat_badge {
          background: #7ed321;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          position: absolute;
          top: -2px;
          right: 5px;
        }
      `}</style>
    </>
  );
}

export default SidePanelSubButton;
