import React from "react";
import breakPoints from "helpers/Styles";
import NotifyMe from "components/NotifyMe";
import AddToBag from "../AddToBag";

const ProductDescriptions = ({
  productTitle,
  productPrice,
  productDescription,
  productImage,
  productVariant,
  productCompareAtPrice,
  productTags,
  productHandle,
  productSKU,
  productId,
  productAvailable,
}) => {
  const discount = productCompareAtPrice - productPrice;
  const title = productTitle.split("/")[0];
  const subtitle = productTitle.split("/")[1];
  return (
    <>
      <div className="product_description">
        <h1 className="product_title">{title}</h1>
        <h1 className="product_subtitle">{subtitle}</h1>
        <div className="price_section">
          <div className="currency">SGD</div>
          {productPrice && (
            <div className="product_price">
              $
              {productPrice
                .toLocaleString()
                .replace(/(\d{1,2})(?=(\d{3})+\.)/g, '$1,')}
            </div>
          )}
        </div>
        {productCompareAtPrice && discount > 0 ? (
          <div className="compareAtPrice">
            <div className="original_price">
              <del>
                {productCompareAtPrice
                  .toLocaleString()
                  .replace(/(\d{1,2})(?=(\d{3})+\.)/g, '$1,')}
              </del>
            </div>

            <div>You save ${discount}</div>
          </div>
        ) : null}
        <div>
          <AddToBag
            soldOut={!productAvailable}
            disabled={!productAvailable}
            productTitle={productTitle}
            productPrice={productPrice}
            selectedVariant={productVariant}
            productImage={productImage}
            productHandle={productHandle}
            productTags={productTags}
            productSKU={productSKU}
            productId={productId}
            customizedClass="add_to_cart"
          />
        </div>
        {!productAvailable && (
          <NotifyMe
            title={title}
            product={productId}
            variant={productVariant?.id}
          />
        )}

        <div className="descriptions">{productDescription}</div>
      </div>

      <style jsx>
        {`
          .product_description {
            margin: 0 auto;
            width: 70%;
            // border: 1px solid red;
            box-sizing: border-box;
            margin-top: 30px;
          }
          .product_title {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            margin: 0;
          }
          .price_section {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .currency {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            margin-top: 10px;
            margin-right: 10px;
          }

          .product_subtitle {
            color: #949494;
            font-family: 'Eurostile-Bold';
            font-size: 20px;
            letter-spacing: 0;
            line-height: 40px;
            margin: 0;
          }
          .product_price {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
          }
          .descriptions {
            width: 100%;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            margin-top: 40px;
          }
          .compareAtPrice {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            display: flex;
            margin-bottom: 40px;
          }
          .original_price {
            margin-right: 10px;
          }
          @media (max-width: ${breakPoints.medium}) {
            .product_description {
              width: 100%;
            }
            .product_title,
            .product_price {
              font-size: 32px;
            }
            .product_subtitle {
              font-size: 23px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductDescriptions;
