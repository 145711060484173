import Head from 'next/head';
import PropTypes from 'prop-types';

const ContainedHead = ({ path, title, description, ogImage }) => {
  if (!title || !path) return '';
  const url = `${process.env.NEXT_PUBLIC_STOREFRONT_URL || 'https://aftershockpc.com'}${path || ''}`;
  return (
    <Head>
      <title>{title}</title>
      <meta key="og:title" property="og:title" content={title} />
      {description && <meta name="description" content={description} />}
      {description && <meta key="og:description" property="og:description" content={description} />}
      <meta property="og:image" content={ogImage ? ogImage : "https://cdn.shopify.com/s/files/1/0637/0407/2436/files/ogImage.png?v=1714058511"} />
      <meta key="og:url" property="og:url" content={url} />
      <meta name="twitter:site" content={url} />
    </Head>
  );
};
ContainedHead.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

ContainedHead.defaultProps = {
  description: null,
};

export default ContainedHead;
