import React, { useContext } from 'react';
import { StorefrontContext } from 'providers/storefront';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Image from 'next/image';
import Router from 'next/router';
import Link from 'next/link';
import CountrySelector from 'components/CountrySelector';
import Cookies from 'js-cookie';
import CUSTOMER_QUERY from '../../Account/AccountWithGQL/graph';
import SocialItem from 'components/SocialItem';
import { kSocials } from 'utils/constants';
import RegionSwitchButton from 'components/NavigationButtonIcons/RegionSwitchButton';

const MobileBottom = ({ closeMenu }) => {
  const { isLoggedIn } = useContext(StorefrontContext);
  let customerName = null;
  if (isLoggedIn) {
    const userToken = Cookies.get('customerToken');
    const token = userToken ? JSON.parse(userToken) : null;
    const { data } = useQuery(CUSTOMER_QUERY, {
      variables: { customerAccessToken: token?.accessToken },
    });
    customerName = data?.customer?.firstName;
  }
  Router.onRouteChangeStart = () => {
    closeMenu();
  };
  return (
    <div>
      {/* <div className="wrapper quiz">
        <Image draggable={false} draggable={false} src="/images/rig_finder_banner_mobile.png" alt="facebook icon" width={343} height={48} />
        <div className="quiz-body">
          <div className="quiz-title">
            <div>Not sure where to start?</div>
            <div>Answer our short quiz and find your ideal set up</div>
          </div>
          <Link type="button" href="/survey" passHref>
            <div className="quiz-button">TAKE THE QUIZ</div>
          </Link>
        </div>
      </div> */}
      <div className='wrapper bottom_bar'>
        {/* <div>
          <CountrySelector backgroundColor="#141414" isShowFullName mobileView />
        </div> */}
        <RegionSwitchButton />
        <div>
          <div className='social_links'>
            {kSocials.map((socialItem, idx) => (
              <SocialItem
                key={idx}
                href={socialItem.href || '#'}
                iconSrc={socialItem.iconSrc}
                alt={socialItem.alt}
                closeMenu={closeMenu}
              />
            ))}
          </div>
          {/* <div className="profile_button">
            <div className="profile_icon">
              <Link href="/me">
                <a href="/me">
                  <div className="profilex_icon_group">
                    <Image draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-profile.svg?v=1714033625"
                      alt="cart icon"
                      className="cart_icon"
                      layout="fixed"
                      width={24}
                      height={24}
                    />
                    {isLoggedIn && customerName && <div className="profile_name">{`HI, ${customerName}`}</div>}
                  </div>
                </a>
              </Link>
            </div>
          </div> */}
        </div>
      </div>

      <style jsx>
        {`
          .profile_button {
            display: flex;
            align-items: center;
          }
          .profile_name {
            font-size: 13px;
            line-height: 20px;
            color: #e4e4e4;
            margin-left: 12px;
          }
          .profilex_icon_group {
            display: flex;
            opacity: 75%;
            color: #e4e4e4;
          }
          .profilex_icon_group:hover {
            opacity: 100%;
          }
          .quiz {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 24px;
            max-width: 343px;
            margin-bottom: 64px;
          }
          .quiz-body {
            padding: 12px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #242424;
            border-radius: 0px 0px 2px 2px;
          }
          .quiz-title div {
            margin: 0;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            line-height: 20px;
            color: #e4e4e4;
            text-align: center;
          }
          .quiz-button {
            margin-top: 8px;
            cursor: pointer;
            font-size: 13px;
            line-height: 24px;
            padding: 4px 16px;
            background: #950810;
            border-radius: 4px;
          }
          .social_links {
            display: flex;
            justify-content: center;
            gap: 12px;
          }
          .bottom_menu {
            height: 110px;
            background: #242424;
            margin-top: 30px;
            border-radius: 3px;
            padding: 20px;
            margin-left: 10px;
            margin-right: 10px;
          }
          .bottom_menu div {
            margin-bottom: 5px;
          }
          .bottom_menu a {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
          }
          .bottom_bar {
            display: flex;
            height: 64px;
            align-items: center;
            justify-content: space-between;
            background: #141414;
          }
        `}
      </style>
    </div>
  );
};

MobileBottom.propTypes = {
  closeMenu: PropTypes.func,
};

MobileBottom.defaultProps = {
  closeMenu: null,
};

export default MobileBottom;
