import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { useQuery } from "@apollo/client";
import { GET_META_OBJECT } from "helpers/graphql/product";
const PerformanceChart = ({ rankingMetaField }) => {

  const [activeTab, setActiveTab] = useState('GAMING');
  const rankingId = rankingMetaField[0]?.node?.value;
  const { data } = useQuery(GET_META_OBJECT, {
    variables: { gid: rankingId }
  });
  const metaFields = data?.metaobject?.fields;
  const esport1080p = metaFields?.find(item => item.key === "esports_1080p_ranking").value;
  const esport1440p = metaFields?.find(item => item.key === "esports_1440p_ranking").value;
  const esport4k = metaFields?.find(item => item.key === "esports_4k_ranking").value;
  const aaa1080p = metaFields?.find(item => item.key === "aaa_1080p_ranking").value;
  const aaa1400p = metaFields?.find(item => item.key === "aaa_1440p_ranking").value;
  const aaa4k = metaFields?.find(item => item.key === "aaa_4k_ranking").value;

  const graphicDesign = metaFields?.find(item => item.key === "graphic_design_ranking").value;
  const photography = metaFields?.find(item => item.key === "photography_ranking").value;
  const gameDevelopment = metaFields?.find(item => item.key === "game_development_ranking").value;
  const visualEffects = metaFields?.find(item => item.key === "3d_and_visual_effects_ranking").value;
  const videoEditing = metaFields?.find(item => item.key === "video_editing_and_and_graphics_motion_ranking").value;

  const getBadge = (value) => {
    if (value === "Overkill") {
      return "https://cdn.shopify.com/s/files/1/0637/0407/2436/files/overkill.png?v=1714032323";
    } else if (value === "Highly Recommended") {
      return "https://cdn.shopify.com/s/files/1/0637/0407/2436/files/highly_recommended.png?v=1714032356";
    } else if (value === "Not Recommended") {
      return "https://cdn.shopify.com/s/files/1/0637/0407/2436/files/not_recommended.png?v=1714032391";
    }
  }

  return (
    <>
      <div className='performance-chart-container'>
        <div className='performance-chart-section'>
          <h1 className='performance-chart-header'>OUR RECOMMENDATIONS FOR GAMERS & CREATIVES</h1>
          <div className='performance-tab-section'>
            <div className={`performance-chart-tab ${activeTab == 'GAMING' && 'performance-chart-tab-active'}`} onClick={() => setActiveTab('GAMING')}>GAMING</div>
            <div className={`performance-chart-tab ${activeTab == 'PRO CREATIVE' && 'performance-chart-tab-active'}`} onClick={() => setActiveTab('PRO CREATIVE')}>PRO CREATIVE</div>
          </div>

          {activeTab === 'GAMING' ?
            <>
              <div className='titles-image'>
                <img
                  draggable="false"
                  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/esports_title_rectangle.png?v=1714032430"
                  width={'100%'}
                />
              </div>
              <div className='badge-section'>
                <div className='badge-box'>
                  <h1 className='badge-title'>1080P</h1>
                  <img
                    draggable="false"
                    src={getBadge(esport1080p)}
                    width={50}
                    height={50}
                  />  
                </div>

                <div className='badge-box'>
                  <h1 className='badge-title'>1440P</h1>
                  <img
                    draggable="false"
                    src={getBadge(esport1440p)}
                    width={50}
                    height={50}
                  />  
                </div>

                <div className='badge-box'>
                  <h1 className='badge-title'>4K</h1>
                  <img
                    draggable="false"
                    src={getBadge(esport4k)}
                    width={50}
                    height={50}
                  />  
                </div>
              </div>

              <div className='titles-image'>
                <img
                  draggable="false"
                  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/aaa_titles.png?v=1714032468"
                  width={'100%'}
                />
              </div>

              <div className='badge-section'>
                <div className='badge-box'>
                  <h1 className='badge-title'>1080P</h1>
                  <img
                    draggable="false"
                    src={getBadge(aaa1080p)}
                    width={50}
                    height={50}
                  />  
                </div>

                <div className='badge-box'>
                  <h1 className='badge-title'>1440P</h1>
                  <img
                    draggable="false"
                    src={getBadge(aaa1400p)}
                    width={50}
                    height={50}
                  />  
                </div>

                <div className='badge-box'>
                  <h1 className='badge-title'>4K</h1>
                  <img
                    draggable="false"
                    src={getBadge(aaa4k)}
                    width={50}
                    height={50}
                  />  
                </div>
              </div>
            </>
          :
            <div>
              <div className='pro-creative-list'>
                <div className='pro-creative-badge-section'>
                  <img
                    draggable="false"
                    src={getBadge(graphicDesign)}
                    width={50}
                    height={50}
                  />
                </div>

                <div className='pro-creative-description-section'>
                  <h1 className='pro-creative-title'>GRAPHIC DESIGN</h1>
                  <p className='pro-creative-description'>Adobe Photoshop, Adobe Illustrator, Adobe Express, Canva, Affinity Designer, Figma</p>
                </div>
              </div>

              <div className='pro-creative-list'>
                <div className='pro-creative-badge-section'>
                  <img
                    draggable="false"
                    src={getBadge(photography)}
                    width={50}
                    height={50}
                  />
                </div>

                <div className='pro-creative-description-section'>
                  <h1 className='pro-creative-title'>PHOTOGRAPHY</h1>
                  <p className='pro-creative-description'>Adobe Photoshop, Adobe Lightroom, Affinity Photo, Pixlr</p>
                </div>
              </div>

              <div className='pro-creative-list'>
                <div className='pro-creative-badge-section'>
                  <img
                    draggable="false"
                    src={getBadge(gameDevelopment)}
                    width={50}
                    height={50}
                  />
                </div>

                <div className='pro-creative-description-section'>
                  <h1 className='pro-creative-title'>GAME DEVELOPMENT</h1>
                  <p className='pro-creative-description'>GameMaker, Unity, Unreal Engine, Godot, Blender</p>
                </div>
              </div>

              <div className='pro-creative-list'>
                <div className='pro-creative-badge-section'>
                  <img
                    draggable="false"
                    src={getBadge(visualEffects)}
                    width={50}
                    height={50}
                  />
                </div>

                <div className='pro-creative-description-section'>
                  <h1 className='pro-creative-title'>3D & VISUAL EFFECTS</h1>
                  <p className='pro-creative-description'>Blender, Autodesk Maya, Adobe After Effects, Maxon Cinema 4D, Unreal Engine, Autodesk 3DS Max</p>
                </div>
              </div>

              <div className='pro-creative-list'>
                <div className='pro-creative-badge-section'>
                  <img
                    draggable="false"
                    src={getBadge(videoEditing)}
                    width={50}
                    height={50}
                  />
                </div>

                <div className='pro-creative-description-section'>
                  <h1 className='pro-creative-title'>VIDEO EDITING & GRAPHICS MOTION GRAPHICS</h1>
                  <p className='pro-creative-description'>Adobe Photoshop, Adobe After Effects, Adobe Premiere Pro,</p>
                </div>
              </div>
            </div>
          }

          <div className='badge-footer-section'>
            <div className='badge-footer-box'>
              <img
                draggable="false"
                src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/overkill.png?v=1714032323"
                width={30}
                height={30}
              />
              <h1 className='badge-footer-title'>OVERKILL</h1>
            </div>

            <div className='badge-footer-box'>
              <img
                draggable="false"
                src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/highly_recommended.png?v=1714032356"
                width={30}
                height={30}
              />
              <h1 className='badge-footer-title'>HIGHLY RECOMMENDED</h1>
            </div>

            <div className='badge-footer-box'>
              <img
                draggable="false"
                src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/not_recommended.png?v=1714032391"
                width={30}
                height={30}
              />
              <h1 className='badge-footer-title'>NOT RECOMMENDED</h1>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

PerformanceChart.propTypes = {
  rankingMetaField: PropTypes.array
};

export default PerformanceChart;
