import React, { useState, useEffect } from "react";
import breakPoints from "helpers/Styles";
import NotifyMe from "components/NotifyMe";
import AddToBagRTS from "../AddToBag/rts";
import { fixProductsPriceObjectModel, fixVariantPriceObjectModel } from "helpers/fixPriceObjectModel";
import { fixDecimalPlaces } from "helpers/smallFixes";

const ProductAddCartMobile = ({
  sameDayShipping,
  productTitle,
  productPrice,
  productDescription,
  productImage,
  productVariant,
  productCompareAtPrice,
  productTags,
  productHandle,
  productSKU,
  productId,
  productAvailable,
  products,
  deliveryDays,
	collectionDays,
	gcidx
}) => {
  const discount = productCompareAtPrice - productPrice;
  const title = productTitle.split("/")[0];
  const [clickIndex, setClickIndex] = useState(null);
  const [modal, setModal] = useState(null);
  const [showText, setShowText] = useState("SHOW");
  const sameDayShipAvab =
    sameDayShipping.length > 0 && sameDayShipping[0].split(":")[1];

	const [showShipping, setShowShipping] = useState(true);

  useEffect(() => {
    if (productTitle === "Aftershock Gift Card") {
      setShowShipping(false)
    }
  }, [productTitle])

  return (
    <>
      <div
        className={
          'main_mobile_footer ' + (productAvailable ? 'valid_product01' : '')
        }
      >
        <div className="product_description add2CartDesc">
		{showShipping &&
          <div
            className={
              'footer_right ' + (modal === true ? 'footerrighton_click' : '')
            }
          >
            <div key={1} className="column">
              <div
                className="footer-title"
                onClick={() => {
                  setClickIndex(4),
                    setShowText(!modal ? 'HIDE' : 'SHOW'),
                    setModal(!modal);
                }}
              >
                <div>
                  <img draggable="false" 
                    src={
                      clickIndex !== null && clickIndex === 4 && modal === true
                        ? 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_up_light_2.png?v=1714049343'
                        : 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_down_light_2.png?v=1714049383'
                    }
                    alt="first_level menu"
                  />
                </div>
                <div>{showText} SHIPPING</div>
              </div>
            </div>
            <div className="price_section">
              <div className="save_price">
                {productCompareAtPrice && discount > 0 ? (
                  <div className="compareAtPrice">
                    <div className="original_price">
                      <del>
                        {fixDecimalPlaces(productCompareAtPrice)}
                      </del>
                    </div>
                  </div>
                ) : null}
                {discount > 0 && (
                  <div className="save-am">You save ${fixDecimalPlaces(discount)}</div>
                )}
              </div>
              {productPrice && (
                <div className="product_price">
                  $
                  {fixDecimalPlaces(productPrice)}
                </div>
              )}
            </div>
          </div>
		}
          <div
            className={
              'show_content_footer ' +
              (modal === true ? 'new ' : ' ') +
              (!productAvailable ? 'sold_pro' : '')
            }
          >
            {clickIndex !== null && clickIndex === 4 && modal === true ? (
              <ul className="contact_container">
                <li className="delivery">
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5455 3.5H13.0909V0H1.63636C0.736364 0 0 0.7875 0 1.75V11.375H1.63636C1.63636 12.8275 2.73273 14 4.09091 14C5.44909 14 6.54545 12.8275 6.54545 11.375H11.4545C11.4545 12.8275 12.5509 14 13.9091 14C15.2673 14 16.3636 12.8275 16.3636 11.375H18V7L15.5455 3.5ZM15.1364 4.8125L16.74 7H13.0909V4.8125H15.1364ZM4.09091 12.25C3.64091 12.25 3.27273 11.8562 3.27273 11.375C3.27273 10.8938 3.64091 10.5 4.09091 10.5C4.54091 10.5 4.90909 10.8938 4.90909 11.375C4.90909 11.8562 4.54091 12.25 4.09091 12.25ZM5.90727 9.625C5.45727 9.09125 4.81909 8.75 4.09091 8.75C3.36273 8.75 2.72455 9.09125 2.27455 9.625H1.63636V1.75H11.4545V9.625H5.90727ZM13.9091 12.25C13.4591 12.25 13.0909 11.8562 13.0909 11.375C13.0909 10.8938 13.4591 10.5 13.9091 10.5C14.3591 10.5 14.7273 10.8938 14.7273 11.375C14.7273 11.8562 14.3591 12.25 13.9091 12.25Z"
                      fill="#E4E4E4"
                    />
                  </svg>

                  <div className="our_content">
                    <span className="shipping">Delivery</span>
                    {sameDayShipAvab === 'same-day-shipping' ? (
                      <span className="next-content">
                        {' '}
                        Regular and same day delivery options on checkout{' '}
                      </span>
                    ) : sameDayShipAvab === 'next-day-shipping' ? (
                      <span className="next-content">
                        Dispatched next business day
                      </span>
                    ) : sameDayShipAvab === 'pre-order' ? (
                      <span className="next-content">
                        Estimated time frame for product can be obtained from our live chat or at checkout
                      </span>
                    ) : sameDayShipAvab === 'delayed' ? (
                      <span className="next-content">
                        Dispatched between 5-15 business days
                      </span>
                    ) : (
                      <span className="next-content">
                        Dispatched between {deliveryDays ? deliveryDays : '3-7'} business days
                      </span>
                    )}
                  </div>
                </li>
                <li className="collection">
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.00039 1.5V0H18.0504V1.5H2.00039ZM2.12539 16V9.55H0.900391V8.05L2.00039 3H18.0254L19.1254 8.05V9.55H17.9004V16H16.4004V9.55H11.6754V16H2.12539ZM3.62539 14.5H10.1754V9.55H3.62539V14.5ZM2.37539 8.05H17.6504H2.37539ZM2.37539 8.05H17.6504L16.8754 4.5H3.15039L2.37539 8.05Z"
                      fill="#E4E4E4"
                    />
                  </svg>

                  <div className="our_content">
                    <span className="shipping">Collection</span>
                    <span className="next-content">
                      {sameDayShipAvab === 'same-day-shipping' ||
                      sameDayShipAvab === 'next-day-shipping'
                        ? 'Same day collection subjected to availability & request'
                        : sameDayShipAvab === 'pre-order'
                        ? `Estimated time frame for product can be obtained from our live chat or at checkout`
                        : sameDayShipAvab === 'delayed'
                        ? 'Pickup available between 5-15 business days'
                        : `Pickup available ${collectionDays ? collectionDays : '1-5'} business days`}
                    </span>
                  </div>
                </li>
              </ul>
            ) : null}

            <div className="car_button cart_btn">
              <AddToBagRTS
                products={fixProductsPriceObjectModel(products)}
                soldOut={!productAvailable}
                disabled={!productAvailable}
                productTitle={productTitle}
                selectedVariant={fixVariantPriceObjectModel(productVariant)}
                productId={productId}
                customizedClass="add_to_cart"
								gcidx={gcidx}
              />
            </div>
            {!productAvailable && (
              <div className="notifyRes">
                <NotifyMe
                  title={title}
                  product={productId}
                  variant={productVariant?.id}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <style>
        {`
          .show_content_footer {
              width: 100%;
              height: 92px;
              left: 0px;
              bottom: 0px;
              background: #141414;
              // display: flex;
              justify-content: space-between;
              position: fixed;
          }
          .footer-title {
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            text-decoration-line: underline;
            width: max-content;
          }
          .main_mobile_footer {
            display: flex;
          }
          .main_mobile_footer .delivery {
            margin-bottom: 12px;
          }
          .main_mobile_footer .footer_right {
            padding: 9px 66.9px;
          }
          .main_mobile_footer .footer_right span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #E4E4E4;
          }
          .main_mobile_footer .footer_right{
              padding: 0 !important;
          }
          .main_mobile_footer .footer_right img {
              width: 12px;
              height: auto;
              margin-right: 10px;
              top: 2px;
          }
          .main_mobile_footer .next-content {
              color: #949494 !important;
              padding-left: 12px;
              font-weight: 400 !important;
          }
          .main_mobile_footer .next-content a {
              text-decoration: underline;
              padding-left: 6px;
          }
          .car_button.cart_btn button {
            font-style: normal;
            font-weight: 600 !important;
            font-size: 13px !important;
            line-height: 24px !important;
            text-align: center;
            color: #E4E4E4 !important;
            padding: 12px 58px;
            margin-left: 16px;
          }
          
          .short_description {
            padding: 10px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
          .add2CartDesc {
            margin: 0 auto;
            width: 100%;
            box-sizing: border-box;
            margin-top: 0px;
            display: flex;
            align-items: center;
          }
          .product_title {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            margin: 0;
          }
          .price_section {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .currency {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            margin-top: 10px;
            margin-right: 10px;
          }

          .product_subtitle {
            color: #949494;
            font-family: 'Eurostile-Bold';
            font-size: 20px;
            letter-spacing: 0;
            line-height: 40px;
            margin: 0;
          }
          .product_price {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
          }
          .descriptions {
            width: 100%;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            margin-top: 40px;
          }
          .compareAtPrice {
              width: 100%;
              display: inherit;
              padding: 0
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              text-decoration-line: line-through;
              color: #949494;
          }
        .price_section {
            flex-wrap: wrap;
            justify-content: right;
         }

          .original_price {
            width: 100%;
            text-align: right;
            margin: 0 !important;
        }
        .save_price {
            display: flex;
            flex-wrap: wrap;
            padding-right: 10px;
        }
        .save-am {
            width: 100%;
            text-align: right;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #FD0300;
        }


        .copyright {
          display: none;
      }
      @media (max-width: ${breakPoints.large}){
        .notify-me button {
            font-size: 12px;
            line-height: 18px;
        }
        .car_button.cart_btn button {
            font-size: 12px !important;
            line-height: 18px !important;
            padding: 10px 35px;
            height: auto;
        }
        .add_to_cart.button--disabled.sold-out-button{
          font-size: 12px !important;
          padding: 16px 35px !important;
        }
      }
      @media screen and (max-width: 991px) {
        .product-page-notify #fc_frame, #fc_frame.fc-widget-normal .product_footer{
          bottom: 187px !important;
        } 
        .product-page-chat #fc_frame, #fc_frame.fc-widget-normal .product_footer{
          bottom: 135px !important;
        } 
      }
        @media (max-width: 1199px){
          {/* .footer_right {
            box-shadow: rgb(4 4 4 / 75%) 0px -8px 4px;
          } */}
          .main_desktop_footer .footer_right img {
              margin-right: 5px;
          }
          .main_desktop_footer .delivery {
              margin-bottom: 5px;
          }
          .footer_right span {
              font-size: 13px;
              line-height: 17px;
          }
          .product_price {
              font-size: 30px;
          }
          .compareAtPrice {
              font-size: 12px;
              line-height: 14px;
          }
          .save-am {
              font-size: 12px;
              line-height: 14px;
          }
          .price_section {
              margin-top: 0;
              margin-bottom: 0;
          }
          .car_button.cart_btn button {
              margin-left: 10px;
          }
          .notifyRes {
              padding-left: 5px;
          }
          .notify-me button {
              font-size: 12px;
              line-height: 18px;
          }
        }
        @media (max-width: 1159px){
          
          .footer_right span {
              font-size: 12px;
              line-height: 15px;
          }
          .product_price {
              font-size: 25px;
          }
          .compareAtPrice {
              font-size: 11px;
              line-height: 14px;
          }
          .save-am {
              font-size: 11px;
              line-height: 14px;
          }
        }
          @media (max-width: ${breakPoints.medium}) {
            .footer_right {
              box-shadow: rgb(4 4 4 / 75%) 0px -8px 4px;
            }
            .show_content_footer .notifyRes {
              padding-left: 0px !important;
            }
            .hide_for_rts {
              display: none !important;
            }
            .add2CartDesc {
              width: 100%;
            }
            .product_title,
            .product_price {
              font-size: 32px;
            }
            .product_subtitle {
              font-size: 23px;
            }
            .our_content {
                width: calc(100% - 32px);
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;
            }
            .show_content_footer .contact_container li img {
                margin-right: 10px;
                width: 18px;
                height: auto;
                display: inline-block;
                vertical-align: baseline;
            }
            .show_content_footer {
                height: auto;
                position: absolute;
                bottom: -65px;
                padding: 0px 15px 15px;
                transition: all 0.3s;
            }
            .show_content_footer.new {
                bottom: -215px;
            }
            .product_footer.wrapper {
                padding-top: 20px;
            }
            .main_mobile_footer {
                padding-bottom: 0;
            }
            .main_mobile_footer .next-content a {
                text-decoration: underline;
                padding-left: 0;
                width: 100%;
                display: inline-block;
            }
            .main_mobile_footer .next-content {
                padding-left: 0;
                width: 100%;
                display: inline-block;
            }
            ul.contact_container {
                padding: 0;
                margin: 0;
                border-top: 1px solid #242424;
            }
            .product_footer{
                position: relative !important;
            }
            .show_content_footer.new.sold_pro {
                bottom: -255px;
            }
            .show_content_footer .car_button.cart_btn {
                margin-left: 0;
                margin-bottom: 15px;
            }
            .show_content_footer.new.sold_pro {
                bottom: 0px;
            }
            .price_section {
                margin-top: 0;
                margin-bottom: 0;
            }
            .main_mobile_footer .footer_right {
                padding: 20px 15px 10px !important;
                position: relative;
                top: -122px;
                background: #141414;
                left: 0;
                width: 100%;
            }
            .footer_right.footerrighton_click {
                position: relative;
                top: -255px;
                left: 0;
                padding:20px 15px !important;
            }
            .add2CartDesc {
                position: fixed;
                top: auto;
                left: 0;
                bottom: 0;
                z-index:99;
            }
            .show_content_footer.sold_pro {
                bottom: 0px;
            }
            .product_specifications_tab.jsx-1038434528 {
                padding-bottom: 175px;
            }
            .notify-me button {
                font-size: 13px;
                line-height: 24px;
            }
            .car_button.cart_btn button {
                font-size: 13px !important;
                line-height: 24px !important;
            }
            .add_to_cart.button--disabled.sold-out-button{
              padding: 10px 35px !important;
            }
            .valid_product01 .show_content_footer.new {
              bottom: 0;
          }
            .main_mobile_footer.valid_product01 .footer_right {
        
              top: -68px;
            
          }
          .valid_product01 .show_content_footer {
              bottom: 0;
          }
          .valid_product01 .footer_right.footerrighton_click {
            top: -182px
          }
          .main_mobile_footer.valid_product01 .car_button.cart_btn {
              margin-bottom: 0;
          }
          }
          @media (max-width: ${breakPoints.small}){
            .footer_right {
              box-shadow: rgb(4 4 4 / 75%) 0px -8px 4px;
            }
            .product_title, .product_price {
                font-size: 28px;
            }
            .save_price {
                padding-right: 8px;
            }
  
            .save-am {
                font-size: 12px;
                line-height: 13px;
            }
            .original_price {
                line-height: 14px;
            }
            .our_content span {
                font-size: 16px;
                line-height: 20px;
            }
            .show_content_footer.new {
                bottom: -203px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductAddCartMobile;
