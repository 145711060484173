import React from 'react';
import Script from 'next/script';

export default (
  <Script
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: `window.$zopim || ( function ( d, s ) {
        console.log("initialize zopim");
	  var z = $zopim = function ( c ) {
			  $zopim.livechat.window.toggle();
			  z._.push( c )
		  },
		  $ = z.s =

		  d.createElement( s ),
		  e = d.getElementsByTagName( s )[ 0 ];
	  z.set = function ( o ) {
		  z.set.

		  _.push( o )
	  };
	  z._ = [];
	  z.set._ = [];
	  $.async = !0;
	  $.setAttribute( 'charset', 'utf-8' );

	  $.src = '//cdn.zopim.com/?JRrHrMxOHg8d3TTnzvL4O80IwcPqxy9c';
	  z.t = +new Date;
	  $.

	  type = 'text/javascript';
	  e.parentNode.insertBefore( $, e )
  } )( document, 'script' );`,
    }}
	strategy='lazyOnload'
  />
);
