import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Video from 'components/video/index-rts';
import VideoViewer from '../ImageViewer/video-rts';

const ImageGalleryMobile = ({
  imagesGallery,
  showModal,
  handleModalOpen,
  handleImageIndex,
  videoPreviewLink,
  handleModalClose,
  handleVideoPreview,
  videoUrl,
}) => {
  return (
    <>
      <div className="product_image_container">
        <Carousel
          showStatus={false}
          showThumbs={false}
          useKeyboardArrows
          infiniteLoop
          autoPlay
          interval={5000}
          showIndicators={false}
        >
          {imagesGallery.map((item, id) => {
            const handleClick = () => {
              handleModalOpen(true), handleImageIndex(id);
            };
            return (
              <div className="image_tab" key={id} onClick={handleClick}>
                <div className="product_image">
                  <Image draggable={false} src={item.node.originalSrc} alt="product_features" width={350} height={350} />
                </div>

                <div className="image_zoom_in" onClick={handleClick}>
                  <Image draggable={false} src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-magnifyingglass-light_1.png?v=1714048366" alt="product_features" width={25} height={25} />
                </div>
              </div>
            );
          })}
        </Carousel>
        {videoPreviewLink !== null && showModal ? (
          <VideoViewer
            videoPreviewLink={videoPreviewLink}
            handleModalClose={handleModalClose}
            imageIndex={1}
            handleImageIndex={handleImageIndex}
          />
        ) : null}
      </div>

      <style jsx>
        {`
          .product_image_container {
            overflow: hidden;
          }
          .image_tab {
            background: #141414;
             {
              /* margin: 0 10px; */
            }
            overflow-y: hidden;
             {
              /* padding: 30px 0; */
            }
            position: relative;
          }
          .image_zoom_in {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 25px !important;
          }
          .product_image {
             {
              /* width: 90% !important;
          min-height: 350px !important; */
            }
            margin: 0 auto;
          }

          @media (max-width: ${breakPoints.medium}) {
            .product_image {
              width: 50% !important;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .product_image {
              width: 100% !important;
            }
          }
          @media (min-width: 768px) and (max-width: 1180px) {
            .carousel .slide img {
              height: 696px !important;
            }
            .product_image {
              height: 750px !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default ImageGalleryMobile;
