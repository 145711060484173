// eslint-disable-next-line import/prefer-default-export
export const getCollectionName = (productTags) => {
  if (productTags.includes('ready-to-ship')) {
    return 'Ready to Ship';
  }
  if (productTags.includes('workstations')) {
    return 'Workstations';
  }
  if (productTags.filter((item) => item.includes('accessories'))) {
    return 'Accessories';
  }
  if (productTags.filter((item) => item.includes('gaming'))) {
    return 'Gaming';
  }
  return '';
};
