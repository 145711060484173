import React, { useState } from 'react';

const CircleRing = ({ percentage }) => (
  <>
    <div className="percent">
      <svg>
        <circle cx="20" cy="20" r="20"></circle>
        <circle cx="20" cy="20" r="20"></circle>
      </svg>
      <div className="number">
        <h2>{percentage}</h2>
      </div>
    </div>

    <style jsx>
      {`
        .percent {
          position: absolute;
          width: 50px;
          height: 50px;
        }
        .percent svg {
          width: 80px;
          height: 80px;
        }
        .percent svg circle {
          width: 50px;
          height: 50px;
          fill: none;
          stroke-width: 7;
          // stroke: yellow;
          transform: translate(15px, 15px);
          transform: rotate(-90deg);
          stroke-dasharray: 125;
          stroke-dashoffset: 125;
          transform-origin: 35px 20px;
        }
        .percent svg circle:nth-child(2) {
          stroke-dashoffset: calc(125 - (125 * ${percentage}) / 100);
          stroke: #950810;
        }
        .percent .number {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
        .number h2 {
          font-size: 13px;
          margin: 0;
          padding: 0;
          margin-left: 20px;
          margin-top: 20px;
        }
      `}
    </style>
  </>
);

export default CircleRing;
