import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageGalleryMobile = ({
  imagesGallery,
  showModal,
  handleModalOpen,
  handleImageIndex,
}) => (
  <>
    <div className="product_image_container">
      <Carousel
        showStatus={false}
        showThumbs={false}
        useKeyboardArrows
        infiniteLoop
        autoPlay
        interval={5000}
        showIndicators={false}
      >
        {imagesGallery.map((item, id) => {
          const handleClick = () => {
            handleModalOpen(true), handleImageIndex(id);
          };
          return (
            <div className="image_tab" key={id} onClick={handleClick}>
              <div className="product_image">
                <Image draggable={false}
                  src={item.node.originalSrc}
                  alt="product_features"
                  width={350}
                  height={350}
                />
              </div>

              <div className="image_zoom_in">
                <Image draggable={false}
                  src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-magnifyingglass-light_1.png?v=1714048366"
                  alt="product_features"
                  width={25}
                  height={25}
                />
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>

    <style jsx>
      {`
        .product_image_container {
          overflow: hidden;
        }
        .image_tab {
          background: #141414;
           {
            /* margin: 0 10px; */
          }
          overflow-y: hidden;
           {
            /* padding: 30px 0; */
          }
          position: relative;
        }
        .image_zoom_in {
          position: absolute;
          right: 10px;
          bottom: 10px;
          width: 25px !important;
        }
        .product_image {
           {
            /* width: 90% !important;
          min-height: 350px !important; */
          }
          margin: 0 auto;
        }
        @media (max-width: ${breakPoints.medium}) {
          .product_image {
            width: 50% !important;
          }
        }
        @media (max-width: ${breakPoints.small}) {
          .product_image {
            width: 100% !important;
          }
        }
      `}
    </style>
  </>
);

export default ImageGalleryMobile;
