import Head from 'next/head';
import gtm from './gtm';
import klaviyo from './klaviyo';
import hotjar from './hotjar';
import chat from './chat';
import viralSwip from './viralSwip';
import { useRouter } from 'next/router';
import { useEffect, useState, useContext } from 'react';
import { StorefrontContext } from 'providers/storefront';
import zopim from './zopim';
import gtagManager from './gtagManager';

const icons = () => {
  const config = ['16', '32', '64', '128'];
  const getIcon = (size) => {
    switch (size) {
      case '16':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon16.png?v=1672187508';
      case '32':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon32.png?v=1672187508';
      case '64':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon64.png?v=1672187508';
      case '128':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon128.png?v=1672187508';
      default:
        return '';
    }
  };
  return config.map((size) => (
    <link rel='icon' key={size} size={`${size}x${size}`} href={getIcon(size)} />
  ));
};

const LayoutHead = () => {
  const { toggleChat, showBothChat } = useContext(StorefrontContext);

  const router = useRouter();
  let interval;
  function setFrontChatPosition() {
    const iframe = document.getElementById('front-chat-iframe');
    if (iframe !== null) {
      iframe.style.transition = 'all 0.5s ease-in-out';
      if (iframe.style.bottom.toString() === '0px') {
        iframe.style.scale = showBothChat ? '0.8' : '1';
        iframe.style.bottom = '0px';
        iframe.style.right = '9px';
        return;
      }
      if (
        iframe.style.bottom.toString() === '135px' &&
        router.pathname !== '/pc-models/[uid]' &&
        router.pathname !== '/products/[...handle]'
      ) {
        iframe.style.bottom = '4px';
        return;
      }
      if (
        router.pathname === '/pc-models/[uid]' ||
        router.pathname === '/products/[...handle]'
      ) {
        const windowWidth = window.innerWidth;
        if (windowWidth > 1521) {
          iframe.style.scale = showBothChat ? '0.8' : '1';
          iframe.style.bottom = '4px';
          iframe.style.right = '9px';
        } else if (windowWidth <= 1521 && windowWidth >= 1025) {
          iframe.style.scale = showBothChat ? '0.8' : '1';
          iframe.style.bottom = '80px';
          iframe.style.right = '9px';
        } else {
          iframe.style.scale = showBothChat ? '0.8' : '1';
          iframe.style.bottom = '125px';
          iframe.style.right = '9px';
        }

        return;
      }
      iframe.style.scale = '1';
      iframe.style.bottom = '4px';
    }
  }
  function setFrontChatDefaultPosition() {
    const iframe = document.getElementById('front-chat-iframe');
    if (iframe !== null) {
      if (iframe.style.bottom.toString() === '4px') {
        clearInterval(interval);
      }
      iframe.style.transition = 'all 0.5s ease-in-out';
      iframe.style.scale = showBothChat ? '0.8' : '1';
      iframe.style.right = '9px';
      iframe.style.bottom = '4px';
      return;
    }
  }
  function hideWhiteMessage() {
    const iframe = document.getElementById('front-chat-iframe');
    if (iframe !== null && iframe.style.width.toString() > '100px') {
      iframe.style.maxHeight = '85px';
    } else {
      iframe.style.maxHeight = 'none';
    }
  }

  function showWhiteMessage() {
    const iframe = document.getElementById('front-chat-iframe');
    if (iframe !== null) {
      iframe.style.maxHeight = 'none';
    }
  }

  useEffect(() => {
    clearInterval(interval);
    const whiteMessageInterval = setInterval(() => {
      if (window.innerWidth < 992) {
        hideWhiteMessage();
      } else {
        showWhiteMessage();
      }
    }, 1000);
    if (
      router.pathname !== '/pc-models/[uid]' &&
      router.pathname !== '/products/[...handle]'
    ) {
      clearInterval(interval);
      interval = setInterval(setFrontChatDefaultPosition, 1000);
    } else {
      clearInterval(interval);
      interval = setInterval(setFrontChatPosition, 1000);
    }
    return () => {
      clearInterval(interval);
      clearInterval(whiteMessageInterval);
    };
  }, [router, showBothChat]);

  const url =
    process.env.NEXT_PUBLIC_STOREFRONT_URL || 'https://aftershockpc.com/';
  const title = 'AFTERSHOCK PC - Custom Gaming PCs and Notebooks';
  const description =
    "Singapore's favorite custom PC brand. With over 250000+ happy customers in 3 different countries, we are relentless in our pursuit of excellence. AFTERSHOCK PC prides itself in delivering the ultimate custom PC experience, with a commitment to deliver service that is a step above the rest. Voted 4 times as the winner of the best gaming pc brand in Singapore, you can rest assured that we will build you the perfect PC to fit your needs.";
  const keywords =
    'aftershock, custom gaming computers, custom pcs, custom notebooks, custom workstations';

  const showChatComponent = () => {
    if (showBothChat != null) {
      if (showBothChat) {
        return chat;
      } else if (toggleChat && toggleChat != null) {
        return chat;
      }
    } else if (
      router.asPath != '/pages/contactus' &&
      router.asPath != '/pages/support' &&
      router.asPath != '/pages/testcontact'
    ) {
      return chat;
    }
  };

  return (
    <>
      <Head>
        <meta charSet='UTF-8' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta name='robots' content='index,follow' />

        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name='apple-mobile-web-app-status-bar-style'
          content='black-translucent'
        />

        <meta key='og:url' property='og:url' content={url} />
        <meta key='og:title' property='og:title' content={title} />
        <meta
          key='og:description'
          property='og:description'
          content={description}
        />
        <meta name='twitter:site' content={url} />
        <meta name='twitter:card' content='summary_large_image' />
        {/* <meta name="twitter:image" content="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/ogImage.png?v=1714058511" /> */}
        <meta property='og:type' content='website' />
        {/* <meta property="og:image" content="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/ogImage.png?v=1714058511" /> */}
        {icons()}
        {/* {gtm} */}
        {hotjar}
        {/* {gtagManager} */}
      </Head>
      {/* {showBothChat != null &&
        showBothChat ? 
          chat : 
          toggleChat && toggleChat != null && chat
      } */}
      {zopim}
      {showChatComponent()}
      {klaviyo}
      {viralSwip}
      {/* 
        This break design for the slider in the survey
         <br
          className="Apple-interchange-newline"
          style={{ height: 0, width: 0 }}
        /> */}
    </>
  );
};

export default LayoutHead;
