import React from 'react';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';

const ProductWarranty = ({ productTags }) => {
  const warrantyTag = productTags?.filter((item) =>
    item.startsWith('warranty:'),
  );

  return (
    <>
      {warrantyTag.length > 0 && (
        <div className="product_warranty">
          <div>
            <h5 className="title">WARRANTY</h5>
            <div className="warranty_section">
              {warrantyTag.map((item, index) => {
                const description = item.split(':')[1].replace(/-/g, ' ');
                return (
                  <div className="warranty_item" key={index}>
                    <div className="warranty_icon">
                      <Image draggable={false}
                        src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_Warranty.png?v=1714048511"
                        alt="warranty"
                        width={25}
                        height={25}
                      />
                    </div>
                    {description}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <style jsx>
        {`
          .product_warranty {
            margin: 0 auto;
            width: 70%;
            border-bottom: 1px solid #949494;
            margin-top: 30px;
            padding-bottom: 30px;
          }
          .title {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }
          .features_section {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }
          .warranty_item {
            width: 60%;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 20px;
            display: flex;
          }
          .warranty_icon {
            margin-right: 15px;
            margin-bottom: -3px;
          }
          @media (max-width: ${breakPoints.medium}) {
            .product_warranty {
              width: 100%;
            }
            .warranty_item {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductWarranty;
