import React, { memo, useState } from 'react';
import Image from 'next/image';
import breakPoints, { fonts } from 'helpers/Styles';
import Modal from 'components/Modal';
import moduleStyles from './Video.module.css';

const video = ({ videoUrl, videoSource, videoTitle, videoDescription }) => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const openModel = () => setShowModal(true);

  return (
    <>
      <div className="video">
        <video
          loop={true}
          muted
          autoPlay={true}
          playsInline={true}
          controls={true}
        >
          <source src={videoSource} type="video/mp4" />
        </video>
        <div className="overlay">
          {/* <button className="button-div" type="button" onClick={openModel}>
            <Image draggable={false}
              src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-play-red.png?v=1714039604"
              height="64px"
              width="64px"
              alt="aftershock, aftershock pc"
            />
          </button> */}
          {videoTitle !== null ? (
            <span className="title">{videoTitle}</span>
          ) : null}
          {videoDescription !== null ? (
            <span className="description">{videoDescription}</span>
          ) : null}
        </div>
        <Modal
          isOpen={showModal}
          modalClass={moduleStyles.modalContent}
          overlayClassName={moduleStyles.modalOverlay}
        >
          <button
            onClick={handleCloseModal}
            className="button-div"
            type="button"
            style={{ float: 'right' }}
          >
            <Image draggable={false} src="https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_5.png?v=1714062936" width="24px" height="24px" alt="close_icon"/>
          </button>
          <div className="desktop-youtube">
            <iframe
              title="aftershock"
              width="560"
              height="315"
              src={videoUrl}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div className="mobile-youtube">
            <iframe
              title="aftershock"
              width="360"
              height="250"
              src={videoUrl}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </Modal>
      </div>
      <style jsx>
        {`
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .video {
            position: relative;
          }
          .overlay {
            pointer-events: none;
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // height: 408px;
            align-items: center;
            top: 0;
            font-family: ${fonts.bigNoodleTitling};
          }
          .title {
            color: #e4e4e4;
            font-size: 49px;
          }
          .description{
            width: 70%;
            color: #E4E4E4;
            font-family: "Roboto-Medium";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin-top: 30px;

          }
          @media (max-width: ${breakPoints.medium}) {
            .overlay {
              height: 100%;
            }
            .title{
              font-size: 26px;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            video{
              height: 100%;
            } 
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          .mobile-youtube {
            display: none;
            text-align: center;
          }
          @media (max-width: ${breakPoints.medium}) {
            .mobile-youtube {
              display: block;
            }
            .desktop-youtube {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default memo(video);
