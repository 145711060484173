import { infoLog, eventLog, EVENT_DEBUG } from './general';

export const initiateCheckoutEvent = (checkout) => {
  if (EVENT_DEBUG && !window?.fbq) {
    infoLog('Initiate Checkout: No FBQ found');
  }

  if (window?.fbq) {
    const detail = {
      content_category: 'product',
      contents: checkout?.lineItems?.edges.map((edge) => ({
        id: Buffer.from(edge?.node?.variant?.id)
          .toString('base64')
          .replace('gid://shopify/ProductVariant/', ''),
        quantity: edge?.node?.quantity,
      })),
      currency: 'SGD',
      value: parseFloat(checkout?.totalPrice),
      num_items: checkout?.lineItems?.edges?.length,
    };
    window?.fbq('track', 'InitiateCheckout', detail);
    if (EVENT_DEBUG) eventLog('InitiateCheckout', { detail });
  }
};
