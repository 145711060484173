import { getCollectionName } from "helpers/getCollectionName";
import Script from "next/script";
// eslint-disable-next-line import/prefer-default-export
export const addToCartKlaviyo = (
  productTitle,
  productId,
  productSKU,
  productImage,
  productHandle,
  productPrice,
  productTags,
  checkout
) => {
  const lineItems =
    checkout && checkout.lineItems
      ? checkout.lineItems.edges.map((lineItem) => ({
          ProductID: lineItem.node.id,
          ProductName: lineItem.node.title,
          Quantity: lineItem.node.quantity,
          ItemPrice: lineItem.node.price,
          ProductURL: `https://aftershockpc.com/products/${lineItem.node.variant.product.handle}`,
          ImageURL: lineItem.node.variant.image != null ? lineItem.node.variant.image.src : ''
        }))
      : {};

  // eslint-disable-next-line react/jsx-indent
  const _learnq = global?.window?._learnq || [];
  _learnq.push([
    "track",
    "Added to Cart",
    {
      AddedItemProductName: productTitle,
      AddedItemProductID: productId,
      AddedItemSKU: productSKU,
      AddedItemCategories: getCollectionName(productTags),
      AddedItemImageURL: productImage,
      AddedItemURL: `https://aftershockpc.com/products/${productHandle}`,
      AddedItemPrice: productPrice,
      AddedItemQuantity: 1,
      CheckoutURL: checkout ? checkout.webUrl : null,
      Items: lineItems,
    },
  ]);
};

export const ViewProductKlaviyo = (
  productTitle,
  productId,
  productImage,
  productPrice,
  productCompareAtPrice,
  productHandle,
  productSKU
) => (
  <Script strategy="lazyOnload">
    {`
      // eslint-disable-next-line no-undef
      if(typeof _learnq !== 'undefined') {
        let _learnq = window._learnq || [];
      }
      _learnq.push([
        'track',
        'Viewed Product',
        {
          Name: '${productTitle}',
          ProductID: '${productTitle}',
          SKU: '${productTitle}',
          ImageURL: '${productTitle}',
          URL: 'https://aftershockpc.com/products/${productHandle}',
          Price: '$${productPrice}',
          CompareAtPrice: '$${productCompareAtPrice}',
        },
      ]);

      _learnq.push([
        'trackViewedItem',
        {
          Name: '${productTitle}',
          ProductID: '${productId}',
          SKU: '${productSKU}',
          ImageURL: '${productImage}',
          URL: 'https://aftershockpc.com/products/${productHandle}',
          Price: '$${productPrice}',
          CompareAtPrice: '$${productCompareAtPrice}',
        },
      ]);
    `}
  </Script>
);
