import React, { useState } from "react";
import breakPoints from "helpers/Styles";
import Image from "next/image";
import { renderPngIcon } from "helpers/specifications";
import { sortedFeatures } from "helpers/specifications";

const ProductFeatures = ({ productTags }) => {
  const [showTitle, setShowTitle] = useState(false);
  const [showTitleCustomization, setShowTitleCustomization] = useState(false);

  const customizationTag = productTags.filter((item) =>
    item.startsWith('customisation:'),
  );

  const customization = customizationTag.length > 0 && customizationTag[0].split(':')[1];

  const featuresTags = productTags.filter((item) =>
    item.startsWith("specifications:")
  ); 

  const filteredTags = sortedFeatures(featuresTags);

  return (
    <>
      {sortedFeatures(featuresTags).length > 0 && (
        <div className="product_feature">
          <div>
            {/* <h5 className="title">FEATURES</h5> */}
            <div className="features_section">
              {sortedFeatures(featuresTags).map((item, index) => {
                const iconStyle = item.type === 'Storage' ? { padding: '0 4px' } : {};
                return (
                  <div className="feature_item" key={index}>
                    {item.description && (
                      <div
                        className={'feature_icon ' + item.type}
                        onMouseLeave={() => setShowTitle(false)}
                        onMouseEnter={() => setShowTitle(index)}
                        style={iconStyle}
                      >
                        {showTitle === index ? (
                          <span className="feature_item_title" style={{}}>
                            {item.type}
                          </span>
                        ) : null}
                        <Image draggable={false}
                          src={renderPngIcon(item.type)}
                          alt="product_features"
                          width={item.type === 'Storage' ? 16 : 25}
                          height={25}
                        />
                      </div>
                    )}
                    <div className="feature_title">{item.description}</div>
                  </div>
                );
              })}
              {customization && (
                <div className="feature_item">
                  <div
                    className={'feature_icon'}
                    onMouseLeave={() => setShowTitleCustomization(false)}
                    onMouseEnter={() => setShowTitleCustomization(true)}
                  >
                    {showTitleCustomization ? (
                      <span className="feature_item_title">
                        {'Customisation'}
                      </span>
                    ) : null}
                    <Image draggable={false}
                      src={`https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-default.svg?v=1714049852`}
                      alt="product_features"
                      width={25}
                      height={25}
                    />
                  </div>
                  <div className="feature_title">{customization}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          .product_feature {
            margin: 0 auto;
            margin-top: 30px;
            // padding-bottom: 10px;
            padding: 0 20px 10px;
          }
          .title {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
            padding: 0;
          }
          .features_section {
            width: 100%;
          }
          .feature_item {
            width: 100%;
            display: flex;
            gap: 10px;
            align-items: start;
            color: #e4e4e4;
            font-family: 'Roboto-Normal';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
            position: relative;
          }
          .feature_item_title {
            border: 1px solid #3b3b3b;
            padding: 4px;
            position: absolute;
            top: -30px;
            left: -15px;
            text-align: center;
            background: #242424;
          }
          .feature_icon {
            margin-right: 9.7px;
            margin-bottom: -3px;
            cursor: pointer;
          }
          .feature_icon img {
            width: 16.67px;
            height: auto;
        }
          @media (max-width: ${breakPoints.medium}) {
            .product_feature {
              width: 100%;
            }
            .feature_icon {
              margin-top: 3px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductFeatures;
