import css from 'styled-jsx/css';

export default css`
  .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .component-section {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 50px;
    margin-left: -50px;
  }
  .component-container {
    width: 350px;
    border-right: 2px solid #656565;
  }
  .component-title {
    font-size: 14px;
    font-weight: bold;
  }
  .component-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    float: left;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .component-box-title {
    margin: 0px;
    color: #969696;
    font-size: 14px;
  }
  .component-box-see-more {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 15px;
    float: left;
    height: 102px;
  }
  .see-more {
    margin: 0px;
    color: #ff0000;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 10px;
    cursor: pointer;
  }
  .tech-yard-section {
    width: 40%;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .tech-yard-title {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .tech-yard-description {
    margin-top: 0px;
    font-size: 14px;
    color: rgb(150, 150, 150);
    width: 280px;
  }
  .tech-yard-visit {
    font-size: 14px;
    color: rgb(255, 0, 0);
    font-weight: 600;
    cursor: pointer;
  }
`;
