import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { PRODUCT_BY_ID, productByIdVars } from 'helpers/graphql/product';
import { useQuery } from '@apollo/client';

const Variants = ({ calledBannerText, variantMetaFields, productHandle }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className='container'>
        {show && (
          <>
            <p className='label'>
              Multiple versions of this build are available
            </p>
          </>
        )}
        <div className='image-section'>
          {variantMetaFields.map((itm) => {
            const productMetaData = JSON.parse(itm?.node?.value);
            return productMetaData.map((product_id) => {
              const { data } = useQuery(PRODUCT_BY_ID, {
                variables: productByIdVars(product_id),
              });
              const bannerTag = data?.product?.tags.find((tag) => {
                return tag.split(':')[0] === 'banner_variant';
              });

              const banner = (bannerTag && calledBannerText !== bannerTag.split(':')[1]) ? bannerTag.split(':')[1] : null;

              if (data != undefined) {
                if (!show) {
                  setShow(true);
                }

                const thumbnail = data?.product?.images?.edges.filter(
                  (image) => image.node.altText === 'rts_variant',
                );
                // const description = data.product.description;
                const shortDescriptions = data.product.metafields
                  .filter((field) => field?.key === 'short_description')
                  .map((field) => field.value)[0];

                if (thumbnail.length != 0) {
                  return (
                    <a
                      className={
                        productHandle != data?.product?.handle && 'link'
                      }
                      onClick={
                        productHandle != data?.product?.handle
                          ? () =>
                              (window.location.href = `/products/${data?.product?.handle}`)
                          : () => {}
                      }
                    >
                      <div
                        className={`variant-wrapper ${
                          productHandle == data?.product?.handle ? 'active' : ''
                        }`}
                      >
                        <div
                          className={`variant-label ${
                            productHandle == data?.product?.handle
                              ? 'variant-label-active'
                              : ''
                          } ${banner ? '' : 'empty-banner'}`}
                        >
                          <span>{banner}</span>
                        </div>
                        <div className='image-container'>
                          <img
                            draggable='false'
                            src={thumbnail[0]?.node?.originalSrc}
                            className={`variant_image`}
                            alt='variant-image'
                          />
                          <div className='overlay'>
                            <div className='text'>{shortDescriptions}</div>
                            {/* {description.length > 100 && (
                              <div className='read-more'>
                                <a href='#product-details-section'>Read More</a>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div className='variant-price'>
                          $
                          {data?.product?.variants?.edges[0]?.node?.price
                            ?.amount
                            ? Number(
                                data.product.variants.edges[0].node.price
                                  .amount,
                              ).toFixed(2)
                            : '0.00'}
                        </div>
                      </div>
                    </a>
                  );
                }
              }
            });
          })}
        </div>
      </div>

      <style jsx>
        {`
          .container {
            width: 100%;
            padding-bottom: 20px;
						// border-bottom: 2px solid #2D2D2D;
          }
          .label {
            display: block;
            color: #f1f2f1;
            font-family: "Roboto-Medium";
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0;
            padding: 0;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
            text-transform: uppercase;
            padding: 15px 0 15px 0;
            border-bottom: 3px solid #2d2d2d;
          }
          .sub-label {
						display: block;
            color: #989898;
            font-family: 'Roboto-Bold';
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            padding: 0;
						margin: 0;
            margin-bottom: 20px;
          }
          .image-section {
            display: flex;
						gap: 20px;
            width: 100%;
          }
          .variant-wrapper {
            width: 100%;
						max-width: 175px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid transparent;
            border-radius: 5px;
            background-color: #242424;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            transition:
							background 0.3s ease-in-out;
              box-shadow 0.3s ease-in-out;
          }
          .variant-wrapper:hover {
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
						background: rgba(0, 0, 0, 0.8);
          }
          .variant-label {
            font-family: 'Roboto-Bold';
            font-size: 10px;
            background-color: #950810;
            padding: 3px 15px;
            border-radius: 5px;
          }
          .variant-label-active {
            border-radius: 0 0 5px 5px;
          }
          .empty-banner {
            height: 19px;
            background: transparent;
          }
          .variant-price {
            width: 100%;
            text-align: center;
            padding: 5px 0;
            background-color: #000;
            color: #f1f2f1;
            font-family: "big_noodle_titling";
            font-size: 20px;
          }
          .image-container {
            position: relative;
            width: 100%;
          }
          .variant_image {
            width: 100%;
            background-color: #2d2d2d;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 5px 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            opacity: 0;
            transition: opacity 0.3s ease;
            font-family: 'Roboto-Medium';
            font-size: 12px;
            padding: 10px;
            overflow: hidden;
          }
          .image-container:hover .overlay {
            opacity: 1;
          }
          .text {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
          }
          .read-more {
            position: absolute;
            bottom: 5px;
            right: 0;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 2px 4px;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            text-decoration: underline;
            color: #950810;
          }
          .link {
            cursor: pointer;
          }
          .active {
            border: 2px solid #950810;
          }
					@media (max-width: 768px) {
						.container {
							display: none;
						}
					}
        `}
      </style>
    </>
  );
};

Variants.propTypes = {
  productTags: PropTypes.array.isRequired,
};

export default Variants;
